import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Model {
  [key: string]: any;
};

interface ArrayModel {
  class: any;
  child: any;
  data: any;
  employee: any;
  school: any;
  subjects: any;
  room: any;
  rooms: any;
  settings: any;
  type: any;
  viewMode: any;
};

const initialState: ArrayModel = {
  class: null,
  child: null,
  data: null,
  employee: null,
  school: null,
  subjects: [],
  room: null,
  rooms: [],
  settings: {},
  type: null,
  viewMode: "schoolSelect",
};

const slice = createSlice({
    name: 'timetable',
    initialState: initialState,
    reducers: {
      setTimetableClass(state, action: PayloadAction<Model>){
        state.class = action.payload;
      },
      setTimetableChild(state, action: PayloadAction<Model>){
        state.child = action.payload;
      },
      setTimetableData(state, action: PayloadAction<Model>){
        state.data = action.payload;
      },
      setTimetableEmployee(state, action: PayloadAction<Model>){
        state.employee = action.payload;
      },
      setTimetableSchool(state, action: PayloadAction<Model>){
        state.school = action.payload;
      },
      setTimetableSubjects(state, action: PayloadAction<Model>){
        state.subjects = action.payload;
      },
      setTimetableRoom(state, action: PayloadAction<Model>){
        state.room = action.payload;
      },
      setTimetableRooms(state, action: PayloadAction<Model>){
        state.rooms = action.payload;
      },
      setTimetableSettings(state, action: PayloadAction<Model>){
        state.settings = action.payload;
      },
      setTimetableType(state, action: PayloadAction<Model>){
        state.type = action.payload;
      },
      setTimetableViewMode(state, action: PayloadAction<Model>){
        state.viewMode = action.payload;
      },
    }
});

export const timetableSlice = slice;