import moment from '../../utils/moment';
import { calendarViewModes } from 'src/utils/useCalendar';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Model {
  [key: string]: any;
};

interface ArrayModel {
  date: any;
  viewMode: any;
  isLoading: any;
  events: any;
  hoverEvent: any;
  filterSchoolID: any;
  filterClassID: any;
  filterChildID: any;
  filterUserID: any;
  filterType: any;
};

const initialState: ArrayModel = {
  date: moment(),
  viewMode: calendarViewModes[2],
  isLoading: true,
  events: [],
  hoverEvent: null,
  filterSchoolID: [],
  filterClassID: [],
  filterChildID: [],
  filterUserID: [],
  filterType: [],
};

const slice = createSlice({
  name: 'calendar',
  initialState: initialState,
  reducers: {
    setCalendarDate(state, action: PayloadAction<Model>){
      state.date = action.payload;
    },
    setCalendarViewMode(state, action: PayloadAction<Model>){
      state.viewMode = action.payload;
    },
    setCalendarIsLoading(state, action: PayloadAction<Model>){
      state.isLoading = action.payload;
    },
    setCalendarEvents(state, action: PayloadAction<Model>){
      state.events = action.payload;
    },
    setCalendarHoverEvent(state, action: PayloadAction<Model>){
      state.hoverEvent = action.payload;
    },
    setCalendarFilterSchoolID(state, action: PayloadAction<Model>){
      state.filterSchoolID = action.payload;
    },
    setCalendarFilterClassID(state, action: PayloadAction<Model>){
      state.filterClassID = action.payload;
    },
    setCalendarFilterChildID(state, action: PayloadAction<Model>){
      state.filterChildID = action.payload;
    },
    setCalendarFilterUserID(state, action: PayloadAction<Model>){
      state.filterUserID = action.payload;
    },
    setCalendarFilterType(state, action: PayloadAction<Model>){
      state.filterType = action.payload;
    },
  }
});

export const calendarSlice = slice;