import HttpService from '../http.service';

const listTokens = (params?: any) => {
  let query = "";
  if(params) {
    if(Object.keys(params).length > 0) {
      query = query + "?";
      for (const [key, value] of Object.entries(params)) {
        query = query + key + "=" + value + "&";
      }
      query = query.substring(0, query.length - 1);
    }
  }
  return HttpService.getDefaultRoot('https://apiv3.twigsee.com/', `security/token${query ? `${query}`: ""}`, { 'Accept': 'application/ld+json', 'Content-Type': 'application/ld+json' }, {});
};

const deleteToken = (tokenID: any) => {
  return HttpService.removeDefaultRoot('https://apiv3.twigsee.com/',`security/token/${tokenID}`, { 'Accept': 'application/ld+json', 'Content-Type': 'application/ld+json' });
};

export {
  listTokens,
  deleteToken,
};