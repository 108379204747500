import { AnyAction } from '@reduxjs/toolkit';
import { controllersSlice as slice } from '../slices/controllers.slice';
import { RootState } from '../index';
import { ThunkAction } from '@reduxjs/toolkit';

export const actions = slice.actions;

export const setInitializeUserController = (data: boolean): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setInitializeUserController(data));
  }
};

export const setDeinitializeUserController = (data: boolean): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setDeinitializeUserController(data));
  }
};

export const setReinitializeUserController = (data: boolean): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setReinitializeUserController(data));
  }
};


export const setResetUserController = (data: boolean): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setResetUserController(data));
  }
};