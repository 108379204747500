import CloseButton from 'src/components/Buttons/CloseButton';
import Modal from '../../../utils/modal';
import NormalButton from '../../Buttons/NormalButton';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { setDiagnosticsModal } from '../../../store/actions/modals.actions';
import { setDiagnosticsChildID, setDiagnosticsType } from '../../../store/actions/diagnostics.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "600px",
    maxWidth: '90vw',
    overflow: "auto",
    padding: "20px",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    marginTop: "20px",
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
    display: 'flex',
    gap: '8px',
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: '16px',
  },
}));

const DiagnosticsModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const modalsData = useAppSelector((state: any) => state.modals);
  const navigate = useNavigate();
  
  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      childID: null,
    };
    dispatch(setDiagnosticsModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleOpenKindergartenDiagnostics = () => {
    dispatch(setDiagnosticsChildID(modalsData.diagnosticsModal.childID));
    dispatch(setDiagnosticsType("kindergarten"));
    navigate("/diagnostics");
  };

  const handleOpenPreschoolerDiagnostics = () => {
    dispatch(setDiagnosticsChildID(modalsData.diagnosticsModal.childID));
    dispatch(setDiagnosticsType("preschooler"));
    navigate("/diagnostics");
  };
  
  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "diagnosticsModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p>{t('diagnostics')}</p>
          </div>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div className={classes.body}>
          <NormalButton buttonType="secondary" onClick={handleOpenKindergartenDiagnostics} dataCy="closeButton">
            {t("diagnostics_kindergarten")}
          </NormalButton>
          <NormalButton buttonType="secondary" onClick={handleOpenPreschoolerDiagnostics} dataCy="closeButton">
            {t("diagnostics_preschooler")}
          </NormalButton>
        </div>
        <div className={classes.footer}>
          <NormalButton buttonType="primary" onClick={handleClose} dataCy="closeButton">
            {t("close")}
          </NormalButton>
        </div>
      </div>
    </Modal>
  );
};  

export default DiagnosticsModal;