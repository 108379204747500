import config from '../../../constants/config';
import htmlParse from 'html-react-parser';
import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import theme from 'src/ui/theme';
import { createUseStyles } from 'react-jss';
import { getQueryParams, isValidHexColor } from 'src/utils/useFunctions';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

interface Props {
  backgroundColor?: any;
}

const useStyles = createUseStyles((theme: any) => ({
  multipleTabScreen: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    bottom: '0px',
    right: '0px',
    zIndex: theme.zIndex.screen,
    backgroundColor: (props: Props) => props.backgroundColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > svg': {
      maxWidth: '60%',
    }, 
  },
  buttons: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
  },
  failed: {
    position: 'absolute',
    top: '75%',
    transform: 'translateY(-75%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    '& > span': {
      color: theme.colors.primaryBlue[500],
      fontWeight: 'bold',
      marginTop: '8px',
    },
    '& > p': {
      maxWidth: '600px',
      padding: '8px 16px',
      '& > b': {
        color: theme.colors.primaryBlue[500],
        fontWeight: '500',
      },
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '0.5rem',
    width: '100%',
    maxWidth: '100%',
    position: 'absolute',
    bottom: '43px',
    left: '50%',
    transform: 'translateX(-50%)',
    '& > span': {
      color: theme.colors.primaryBlue[500],
      whiteSpace: 'nowrap',
      '& > span': {
        fontWeight: 'bold',
      },
    },
  },
}));

const MultipleTabScreen: React.FunctionComponent = () => {
  
  const location = useLocation();
  const { t } = useTranslation();
  
  const queryParams = getQueryParams(location);
  const embed = queryParams.get("embed");
  const isEmbed = embed === "true" ? true : false;
  const background = queryParams.get("background");
  const backgroundColor = isEmbed ? (background === "gradient" ? theme.colors.gradient : (isValidHexColor(`#${background}`) ? `#${background}` : theme.colors.white)) : theme.colors.white;

  const classes = useStyles({
    backgroundColor: backgroundColor,
  });

  return (
    <div className={classes.multipleTabScreen}>
      <SVG src={config.APP_LOGO}/>
      <div className={classes.failed}>
        <span>{t('too_many_tabs')}</span>
        <p>
          {htmlParse(t('too_many_tabs_info'))}
        </p>
      </div>   
      <div className={classes.footer}>
        <span data-clarity-unmask="true">
          &copy; {t('twigsee')} {new Date().getFullYear()}
        </span>
        <span>|</span>
        <span data-clarity-unmask="true">{t('version')} {config.APP_VERSION}</span>
        {
          isEmbed ? (
            <>
              <span>|</span>
              <span data-clarity-unmask="true">{t('version_embed')} {config.APP_EMBED_VERSION}</span>
            </>
          ) : null
        }
      </div>     
    </div>
  );
}

export default MultipleTabScreen;