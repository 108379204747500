import { AnyAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { ThunkAction } from '@reduxjs/toolkit';
import { timelineSlice as slice } from '../slices/timeline.slice';

export const actions = slice.actions;

export const setTimelinePosts = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setTimelinePosts(data));
  }
};

export const addTimelinePosts = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.addTimelinePosts(data));
  }
};


export const addTimelinePost = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.addTimelinePost(data));
  }
};

export const updateTimelinePost = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.updateTimelinePost(data));
  }
};

export const updateTimelinePostAttribute = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.updateTimelinePostAttribute(data));
  }
};

export const deleteTimelinePost = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.deleteTimelinePost(data));
  }
};

export const setTimelineMedias = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setTimelineMedias(data));
  }
};