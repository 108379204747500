import * as UserService from '../../../../../../services/user.service';
import Input from '../../../../../../components/Forms/Input';
import NormalButton from '../../../../../../components/Buttons/NormalButton';
import React, { useRef } from 'react';
import SHA1 from '../../../../../../services/crypto.service';
import { createNotification } from '../../../../../../utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getPasswordWeakness, validatePassword } from '../../../../../../utils/useValidation';
import { isKey } from 'src/utils/useFunctions';
import { Tooltip } from '@mui/material';
import { useStates } from '../../../../../../utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& > label': {
      display: 'flex',
      color: theme.colors.black,
      fontSize: '14px',
      marginTop: '16px',
      marginBottom: '7px',
      fontWeight: 'bold',
      width: '100%',
    },
    '& > span': {
      display: 'flex',
      color: theme.colors.black,
      fontSize: '14px',
      width: '100%',
    },
  },
  tooltip: {
    backgroundColor: theme.colors.systemRed[500],
    color: theme.colors.white,
  },
  tooltipArrow: {
    color: theme.colors.systemRed[500],
  },
}));

const PasswordChange: React.FunctionComponent = () => {

  const { t } = useTranslation();
  const classes = useStyles();
  const actualPasswordInput: any = useRef({});
  const newPasswordInput: any = useRef({});
  const confirmPasswordInput: any = useRef({});

  const defaultPasswordValues = {
    actualPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const [passwordValues, setPasswordValues] = useStates(defaultPasswordValues);

  const [state, setState] = useStates({
    isNewPasswordBlurred: true,
    isConfirmPasswordBlurred: false,
  });

  const isFilledPassword = passwordValues.actualPassword !== '' && passwordValues.newPassword !== '' && passwordValues.confirmPassword !== '';

  const passwordWeakness = getPasswordWeakness(passwordValues.newPassword);
  const newPasswordPopupShow = passwordValues.newPassword.length > 0 && passwordWeakness.length !== 0 && !state.isNewPasswordBlurred;
  const newPasswordPopupContent = () => {
    const content = (
      <>
        {
          passwordWeakness.indexOf("length") !== -1 ? (
            <>{t('password_required_length')}<br/></>
          ) : null
        }
        {
          passwordWeakness.indexOf("lowercase") !== -1 ? (
            <>{t('password_required_lowercase')}<br/></>
          ) : null
        }
        {
          passwordWeakness.indexOf("uppercase") !== -1 ? (
            <>{t('password_required_uppercase')}<br/></>
          ) : null
        }
        {
          passwordWeakness.indexOf("number") !== -1 ? (
            <>{t('password_required_number')}<br/></>
          ) : null
        }
      </>
    );
    return content;
  };

  const confirmPasswordPopupShow = passwordValues.newPassword.length > 0 && passwordValues.confirmPassword.length > 0 && passwordValues.newPassword !== passwordValues.confirmPassword && state.isConfirmPasswordBlurred && state.isNewPasswordBlurred;
  const confirmPasswordPopupContent = t('password_must_be_match');

  const handleFocusConfirmPasswordInput = () => {
    setState("isConfirmPasswordBlurred", false);
  };

  const handleBlurConfirmPasswordInput = () => {
    setState("isConfirmPasswordBlurred", true);
  };

  const handleFocusNewPasswordInput = () => {
    setState("isNewPasswordBlurred", false);
  };

  const handleBlurNewPasswordInput = () => {
    setState("isNewPasswordBlurred", true);
  };

  const handlePasswordInput = (name: any, value: any) => {
    setPasswordValues(name, value);
  };

  const savePasswordChanges = () => {
    let payload: any = {};
    Object.keys(passwordValues).forEach((key: any) => {
      if(key !== "confirmPassword") {
        payload[key] = SHA1(passwordValues[key as keyof typeof passwordValues]);
      }
    });
    if(Object.keys(payload).length > 0) {
      if(validatePassword(passwordValues.newPassword)) {
        if(passwordValues.newPassword === passwordValues.confirmPassword) {
          UserService && UserService.changePassword(payload).then((result: any) => {
            if(result.status === 201) { 
              setPasswordValues(defaultPasswordValues);
              actualPasswordInput.current.querySelector("input").value = '';
              newPasswordInput.current.querySelector("input").value = '';
              confirmPasswordInput.current.querySelector("input").value = '';
              createNotification(t('password_changed'), 'success');
            } else {
              createNotification(t('something_went_wrong'), 'error');
            }
          }).catch((e: any) => {
            if(e.response.status === 403) {
              createNotification(!isKey(e.response.data.message) ? e.response.data.message : t('password_incorrect'), 'error');
            } else {
              createNotification(!isKey(e.response.data.message) ? e.response.data.message : t('something_went_wrong'), 'error');
            }
          });
        } else {
          createNotification(t('password_not_match'), 'error');
        }
      } else {
        if(passwordWeakness[0] === "length") {
          createNotification(t('password_required_length'), 'error');
        } else if(passwordWeakness[0] === "lowercase") {
          createNotification(t('password_required_lowercase'), 'error');
        } else if(passwordWeakness[0] === "uppercase") {
          createNotification(t('password_required_uppercase'), 'error');
        } else if(passwordWeakness[0] === "number") {
          createNotification(t('password_required_number'), 'error');
        }
      }
    }
  };
  
  return (
    <>
      <Input type="password" label={t('password_actual') + '*'} name="actualPassword" onChange={handlePasswordInput} tabIndex="8" customRef={actualPasswordInput}/>
      <Tooltip classes={{tooltip: classes.tooltip, arrow: classes.tooltipArrow}} title={newPasswordPopupContent()} open={newPasswordPopupShow} placement="bottom" arrow>
        <div className={classes.inputWrapper}>
        <Input type="password" label={t('password_new') + '*'} name="newPassword" onChange={handlePasswordInput} onFocus={handleFocusNewPasswordInput} onBlur={handleBlurNewPasswordInput} tabIndex="10" customRef={newPasswordInput}/>
        </div>
      </Tooltip>
      <Tooltip classes={{tooltip: classes.tooltip, arrow: classes.tooltipArrow}} title={confirmPasswordPopupContent} open={confirmPasswordPopupShow} placement="bottom" arrow>
        <div className={classes.inputWrapper}>
          <Input type="password" label={t('password_confirm') + '*'} name="confirmPassword" onChange={handlePasswordInput} onFocus={handleFocusConfirmPasswordInput} onBlur={handleBlurConfirmPasswordInput} tabIndex="12" customRef={confirmPasswordInput}/>
        </div>
      </Tooltip>
      <NormalButton buttonType="primary" disabled={!isFilledPassword} onClick={savePasswordChanges} tabIndex="13">
        {t('change_password')}
      </NormalButton>
    </>
  );
};

export default PasswordChange;