import { AnyAction } from '@reduxjs/toolkit';
import { childrenSlice as slice } from '../slices/children.slice';
import { RootState } from '../index';
import { ThunkAction } from '@reduxjs/toolkit';

export const actions = slice.actions;

export const setChildrenViewMode = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setChildrenViewMode(data));
  }
};

export const setChildrenSelectedChildren = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setChildrenSelectedChildren(data));
  }
};