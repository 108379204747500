import CircleIcon from '@mui/icons-material/Circle';
import htmlParse from 'html-react-parser';
import React from 'react';
import SVG from '../../Images/SvgRenderer';
import Tooltip from 'src/components/Layouts/Tooltip';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';

interface Props {
  size?: any,
};

const useStyles = createUseStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& > span': {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      '& + span': {
        fontSize: '13px',
        color: theme.colors.grey[650],
      },
      '&:only-of-type': {
        fontSize: '13px',
        color: theme.colors.grey[650],
      },
    },
  },
  substitutionInfoItem: {
    position: 'relative',
    width: (props: Props) => props.size + 'px',
    height: (props: Props) => props.size + 'px',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  substitutionPartAM: {
    position: 'relative',
    top: '0px',
    left: (props: Props) => '-' + (props.size / 4) + 'px',
    bottom: '0px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    width: (props: Props) => (props.size / 2) + 'px',
    '& > svg': {
      width: (props: Props) => props.size + 'px',
      height: (props: Props) => props.size + 'px',
      color: '#00E5E5',
    }, 
  },
  substitutionPartPM: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    bottom: '0px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    width: (props: Props) => (props.size / 2) + 'px',
    '& > svg': {
      marginLeft: (props: Props) => '-' + (props.size / 2) + 'px',
      width: (props: Props) => props.size + 'px',
      height: (props: Props) => props.size + 'px',
      color: '#00E5E5',
    }, 
  },  
  attendancePartAM: {
    position: 'relative',
    top: '0px',
    left: (props: Props) => '-' + (props.size / 4) + 'px',
    bottom: '0px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    width: (props: Props) => (props.size / 2) + 'px',
    '& > svg': {
      width: (props: Props) => props.size + 'px',
      height: (props: Props) => props.size + 'px',
      color: theme.colors.primaryBlue[500],
    }, 
  },
  attendancePartPM: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    bottom: '0px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    width: (props: Props) => (props.size / 2) + 'px',
    '& > svg': {
      marginLeft: (props: Props) => '-' + (props.size / 2) + 'px',
      width: (props: Props) => props.size + 'px',
      height: (props: Props) => props.size + 'px',
      color: theme.colors.primaryBlue[500],
    }, 
  }, 
  createPartAM: {
    position: 'relative',
    top: '0px',
    left: (props: Props) => '-' + (props.size / 4) + 'px',
    bottom: '0px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    opacity: '0',
    transition: 'opacity 0.25s',
    width: (props: Props) => (props.size / 2) + 'px',
    '& > svg': {
      width: (props: Props) => props.size + 'px',
      height: (props: Props) => props.size + 'px',
      color: '#00E5E5',
    }, 
  },
  createPartPM: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    bottom: '0px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    opacity: '0',
    transition: 'opacity 0.25s',
    width: (props: Props) => (props.size / 2) + 'px',
    '& > svg': {
      marginLeft: (props: Props) => '-' + (props.size / 2) + 'px',
      width: (props: Props) => props.size + 'px',
      height: (props: Props) => props.size + 'px',
      color: '#00E5E5',
    }, 
  },  
  createImageMorning: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateY(-50%)',
    width: (props: Props) => (((props.size / 2) - 1) * 1.25) + 'px',
    height: (props: Props) => (((props.size / 2) - 1) * 1.25) + 'px',
    marginLeft: '-2px',
    '& > svg': {
      width: '100%',
      height: '100%',
      color: theme.colors.white,
    },
  },
  createImageAfternoon: {
    position: 'absolute',
    top: '50%',
    right: '50%',
    transform: 'translateY(-50%)',
    width: (props: Props) => (((props.size / 2) - 1) * 1.25) + 'px',
    height: (props: Props) => (((props.size / 2) - 1) * 1.25) + 'px',
    marginRight: '-1px',
    '& > svg': {
      width: '100%',
      height: '100%',
      color: theme.colors.white,
    },
  },
}));

type SubstitutionInfoType = {
  morningStatus?: any;
  afternoonStatus?: any;
  size?: any;
  text?: any;
  tooltip?: boolean;
};

const SubstitutionInfoItem: React.FunctionComponent<SubstitutionInfoType> = ({ morningStatus, afternoonStatus, size = 32, text, tooltip }) => {

  const { t } = useTranslation();
  const classes = useStyles({
    size: size,
  });

  const titles: any = {
    wholeDay: {
      substitution: t('substitution_whole_day'),
      attendance: t('attendance_programme_whole_day'),
      create: t('substitution_create_whole_day'),
    },
    morning: {
      substitution: t('substitution_morning'),
      attendance: t('attendance_programme_morning'),
      create: t('substitution_create_morning'),
    },
    afternoon: {
      substitution: t('substitution_afternoon'),
      attendance: t('attendance_programme_afternoon'),
      create: t('substitution_create_afternoon'),
    },
  };

  const positions: any = {
    wholeDay: "top",
    morning: "top-end",
    afternoon: "top-start",
  };

  const getTooltipTitle = (dayPart: any, status: any) => {
    if(dayPart && status) {
      return titles[dayPart][status];
    } else {
      return "";
    }
  };

  const getTooltipPosition = (dayPart: any) => {
    if(dayPart) {
      return positions[dayPart];
    } else {
      return "";
    }
  };

  const TooltipContainer = ({children, type, status}: any) => {
    return tooltip ? (
      <>
        {
          (type === "wholeDay" && morningStatus === afternoonStatus) || (type === "morning" && morningStatus && morningStatus !== afternoonStatus) || (type === "afternoon" && afternoonStatus && afternoonStatus !== morningStatus) ? (
            <Tooltip title={getTooltipTitle(type, status)} position={getTooltipPosition(type)} maxWidth={200} align='center'>
              {children}
            </Tooltip>
          ) : (
            <>
              {
                type === "wholeDay" ? (
                  <span>
                    {children}
                  </span>
                ) : children
              }
            </>
          )
        }
      </>
    ) : children;
  };
  
  return (
    <div className={classes.wrapper}>
      <TooltipContainer type="wholeDay" status={morningStatus}>
        <div className={classes.substitutionInfoItem}>
        <TooltipContainer type="morning" status={morningStatus}>
          {
            morningStatus === "substitution" ? (
              <div className={classes.substitutionPartAM}>
                <CircleIcon/>
              </div>
            ) : morningStatus === "attendance" ? (
              <div className={classes.attendancePartAM}>
                <CircleIcon/>
              </div>
            ) : morningStatus === "create" ? (
              <div className={classes.createPartAM}>
                <CircleIcon/>
                <span className={classes.createImageMorning}>
                  <SVG src="plus"/>
                </span>
              </div>
            ) : null
          }
        </TooltipContainer>
        <TooltipContainer type="afternoon" status={afternoonStatus}>
          {
            afternoonStatus === "substitution" ? (
              <div className={classes.substitutionPartPM}>
                <CircleIcon/>
              </div>
            ) : afternoonStatus === "attendance" ? (
              <div className={classes.attendancePartPM}>
                <CircleIcon/>
              </div>
            ) : afternoonStatus === "create" ? (
              <div className={classes.createPartPM}>
                <CircleIcon/>
                <span className={classes.createImageAfternoon}>
                  <SVG src="plus"/>
                </span>
              </div>
            ) : null
          }
        </TooltipContainer>
        </div>
      </TooltipContainer>
      {
        text ? (
          <span>{htmlParse(text)}</span>
        ) : null
      }
    </div>
  );
}

export default SubstitutionInfoItem;