import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { removeDuplicatesJSON } from 'src/utils/useFunctions';

interface Model {
  [key: string]: any;
};

interface ArrayModel {
  drafts: any;
  list: any;
  messages: any;
  notificationsCount: any;
  threadID: any;
  userID: any;
  viewMode: any;
};

const initialState: ArrayModel = {
  drafts: [],
  list: [],
  messages: [],
  notificationsCount: 0,
  threadID: null,
  userID: null,
  viewMode: null,
};

const slice = createSlice({
  name: 'communication',
  initialState: initialState,
  reducers: {
    setCommunicationDrafts(state, action: PayloadAction<Model>) {
      state.drafts = action.payload;
    },
    setCommunicationList(state, action: PayloadAction<Model>) {
      state.list = action.payload;
    },
    updateCommunicationList(state, action: PayloadAction<any>) {
      const originalData = state.list;
      const newData = action.payload;
      const dataMap = new Map();
      originalData.forEach((item: any) => dataMap.set(item.threadID, item));
      newData.forEach((item: any) => dataMap.set(item.threadID, item));
      const mergedData = Array.from(dataMap.values());
      state.list = mergedData;
    },
    addToCommunicationList(state, action: PayloadAction<Model>) {
      const newList = [...state.list, action.payload];
      const newFilteredList = newList.filter((item: any) => item !== undefined);
      const newRemovedDuplicatesList = removeDuplicatesJSON(newFilteredList);
      state.list = newRemovedDuplicatesList;
    },
    updateThreadData(state, action: PayloadAction<Model>) {
      const threadID = action.payload.threadID;
      const data = action.payload.data;
      const newList = state.list.map((item: any) => {
        if(item.threadID === threadID) {
          return {...item, ...data};
        } else {
          return item;
        }
      });
      state.list = newList;
    },
    deleteThreadData(state, action: PayloadAction<Model>) {
      const threadID = action.payload;
      const newList = state.list.filter((item: any) => item.threadID !== threadID);
      state.list = newList;
    },
    setCommunicationUserID(state, action: PayloadAction<Model>) {
      state.userID = action.payload;
    },
    setCommunicationNotificationsCount(state, action: PayloadAction<Model>) {
      state.notificationsCount = action.payload;
    },
    setCommunicationThreadID(state, action: PayloadAction<Model>) {
      state.threadID = action.payload;
    },
    setCommunicationViewMode(state, action: PayloadAction<Model>) {
      state.viewMode = action.payload;
    },
    resetCommunicationList(state) {
      state.list = [];
    },
    setCommunicationMessages(state, action: PayloadAction<Model>) {
      state.messages = action.payload;
    },
    addToCommunicationMessages(state, action: PayloadAction<Model>) {
      const newList = [...state.messages, action.payload];
      const newFilteredList = newList.filter((item: any) => item !== undefined);
      const newRemovedDuplicatesList = removeDuplicatesJSON(newFilteredList);
      state.messages = newRemovedDuplicatesList;
    },
    resetCommunicationMessages(state) {
      state.messages = [];
    },
  }
});

export const communicationSlice = slice;