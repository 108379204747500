type objectType = {
  [key: string]: any;
};

const t = (value: any) => { return value };

const tabs: objectType = {
  excuseNotes: [
    {
      name: t('calendar'),
      value: 'calendar',
      isEnabled: true,
      roles: [
        "parent",
        "teacher",
        "director",
      ],
    },
    {
      name: t('list'),
      value: 'list',
      isEnabled: true,
      roles: [
        "parent",
        "teacher",
        "director",
      ],
    },
    {
      name: t('class'),
      value: 'class',
      visibleOnlySelected: true,
      isEnabled: true,
      roles: [
        "teacher",
        "director",
      ],
    },
  ],
  finance: [
    {
      name: t('cards'),
      value: 'cards',
      isEnabled: true,
      roles: [
        "parent",
      ],
    },
    {
      name: t('tables'),
      value: 'tables',
      isEnabled: true,
      roles: [
        "parent",
      ],
    },
  ],
  children: [
    {
      name: t('cards'),
      value: 'cards',
      isEnabled: true,
      roles: [
        "parent",
        "teacher",
        "director",
      ],
    },
    {
      name: t('table'),
      value: 'table',
      isEnabled: true,
      roles: [
        "parent",
        "teacher",
        "director",
      ],
    },
    {
      name: 'press_reports',
      value: 'press_report',
      isEnabled: true,
      roles: [
        "teacher",
        "director",
      ],
      redirect: "/press-report"
    },
  ],
  inspiration: [
    {
      name: t('pinterest'),
      value: 'pinterest',
      isEnabled: true,
      roles: [
        "parent",
        "teacher",
        "director",
      ],
    },
    {
      name: t('blog'),
      value: 'blog',
      isEnabled: true,
      roles: [
        "parent",
        "teacher",
        "director",
      ],
    },
  ],
  settings: {
    customization: [
      {
        name: t('menu_items'),
        value: 'menu_items',
        isEnabled: true,
        roles: [
          "parent",
          "teacher",
          "director",
        ],
      },
      {
        name: t('homepage'),
        value: 'homepage',
        isEnabled: true,
        roles: [
          "parent",
          "teacher",
          "director",
        ],
      },
      /*
      {
        name: t('app_theme'),
        value: 'theme',
        isEnabled: true,
        roles: [
          "parent",
          "teacher",
          "director",
        ],
      },
      */
      {
        name: t('communication'),
        value: 'communication',
        isEnabled: true,
        roles: [
          "parent",
          "teacher",
          "director",
        ],
        requirements: [
          "communication",
        ],
      },
      {
        name: t('calendar'),
        value: 'calendar',
        isEnabled: true,
        roles: [
          "parent",
          "teacher",
          "director",
        ],
        requirements: [
          "calendar",
        ],
      },
    ],
    security: [
      {
        name: t('email_change'),
        value: 'email_change',
        isEnabled: true,
        roles: [
          "parent",
        ],
      },
      {
        name: t('email'),
        value: 'email_change',
        isEnabled: true,
        roles: [
          "teacher",
          "director",
        ],
      },
      {
        name: t('password_change'),
        value: 'password_change',
        isEnabled: true,
        roles: [
          "parent",
          "teacher",
          "director",
        ],
      },
      {
        name: t('logged_in_devices'),
        value: 'logged_in_devices',
        isEnabled: true,
        roles: [
          "parent",
          "teacher",
          "director",
        ],
      },
      {
        name: t('sso_one_time_login'),
        value: 'sso_one_time_login',
        isEnabled: true,
        roles: [
          "parent",
          "teacher",
          "director",
        ],
      },
    ],
  },
  pressReport: [
    {
      name: t('children'),
      value: 'children',
      isEnabled: true,
      roles: [
        "teacher",
        "director",
      ],
    },
  ],
};

export default tabs;