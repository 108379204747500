import htmlParse from 'html-react-parser';
import SubstitutionInfoItem from 'src/components/Items/SubstitutionInfoItem';
import { createUseStyles } from 'react-jss';
import { getUserRole } from 'src/utils/useUser';
import { useAppSelector } from '../../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  title: {
    fontWeight: '600',
  },
  subTitle: {
    fontWeight: '500',
    fontSize: '0.75rem',
    marginTop: '0.5rem',
  },
  text: {
    marginTop: '16px',
    marginBottom: '0px',
    color: theme.colors.grey[650],
    fontSize: '14px',
    maxWidth: '90%',
    '& a': {
      color: theme.colors.primaryBlue[500],
    },
  },
  subText: {
    marginTop: '8px',
    marginBottom: '0px',
    color: theme.colors.grey[650],
    fontSize: '12px',
    maxWidth: '90%',
    '& a': {
      color: theme.colors.primaryBlue[500],
    },
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
  },
  counts: {
    fontSize: '16px',
    '& > span': {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'bold',
    },
    '& > p': {
      marginTop: '8px',
    },
    '& svg': {
      width: '18px',
      height: '18px',
      color: 'inherit',
      marginRight: '2px',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    }, 
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '&:not(:first-of-type)': {
      marginTop: '32px',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flex: '0 0 100%',
      alignItems: 'center',
    },
  },
  fullblock: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: '0 0 100%',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  badges: {
    display: 'flex',
    marginTop: '16px',
    gap: '16px',
    '& div': {
      opacity: '1',
    },
  },
}));

const Substitutions: React.FunctionComponent = () => {

  const { t } = useTranslation();
  const classes = useStyles();
  const substitutionsData = useAppSelector((state: any) => state.substitutions);
  const userData = useAppSelector((state: any) => state.user);
  const childData = substitutionsData.childData;

  return (
    <>
      <div className={classes.row}>
        <div className={classes.fullblock}>
          <span className={classes.title}>
            {t('substitutions_title')}
          </span>
          <p className={classes.text}>
            {t('substitutions_info')}  
          </p>
        </div>
      </div>
      {
        getUserRole(userData.userObject.roleType) === "parent" ? (
          <>
            <div className={classes.row}>
              <div className={classes.block}>
                <span className={classes.title}>
                  {t('substitutions_credits')}
                </span>
                <p className={classes.text}>
                  {htmlParse(t('substitutions_available_description', {available: '<b>' + childData.credit + '</b>', excuse_notes: '<b>' + childData.allDates.length + '</b>', days: '<b>' + childData.schoolDays + '</b>'}))}  
                </p>
              </div>
              <div className={classes.block}>
                <span className={classes.title}>
                  {t('substitutions_rules')}
                </span>
                <div className={classes.text}>
                  {t('substitutions_rules_description')}
                  <ul>
                    <li>{t('substitutions_rules_schools_enabled')}</li>
                    <li>{t('substitutions_rules_schools_workdays')}</li>
                    <li>{t('substitutions_rules_schools_holidays')}</li>
                    <li>{t('substitutions_rules_children_attendance')}</li>
                    <li>{t('substitutions_rules_children_excuse_notes')}</li>
                    <li>{t('substitutions_rules_children_attendance_full_day')}</li>
                  </ul>  
                </div>
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.block}>
                <span className={classes.title}>
                  {t('substitutions_badges')}
                </span>
                <span className={classes.subTitle}>
                  {t('attendance_programme')}
                </span>
                <div className={classes.badges}>
                  <SubstitutionInfoItem morningStatus="attendance" text={t('morning')}/>
                  <SubstitutionInfoItem afternoonStatus="attendance" text={t('afternoon')}/>
                  <SubstitutionInfoItem morningStatus="attendance" afternoonStatus="attendance" text={t('whole_day')}/>
                </div>
                <span className={classes.subTitle}>
                  {t('substitutions')}
                </span>
                <div className={classes.badges}>
                  <SubstitutionInfoItem morningStatus="substitution" text={t('morning')}/>
                  <SubstitutionInfoItem afternoonStatus="substitution" text={t('afternoon')}/>
                  <SubstitutionInfoItem morningStatus="substitution" afternoonStatus="substitution" text={t('whole_day')}/>
                </div>
                <span className={classes.subTitle}>
                  {t('substitution_create')}
                </span>
                <div className={classes.badges}>
                  <SubstitutionInfoItem morningStatus="create" text={t('morning')}/>
                  <SubstitutionInfoItem afternoonStatus="create" text={t('afternoon')}/>
                  <SubstitutionInfoItem morningStatus="create" afternoonStatus="create" text={t('whole_day')}/>
                </div>
              </div>
              <div className={classes.block}>
                <span className={classes.title}>
                  {t('substitutions_combinations')}
                </span>
                <p className={classes.text}>
                  {t('substitutions_combinations_description')}  
                </p>
                <div className={classes.badges}>
                  <SubstitutionInfoItem morningStatus="attendance" afternoonStatus="create" text={t('morning')}/>
                  <SubstitutionInfoItem morningStatus="create" afternoonStatus="attendance" text={t('afternoon')}/>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className={classes.row}>
            <div className={classes.fullblock}>
              <span className={classes.title}>
                {t('substitutions_teacher_director')}
              </span>
              <p className={classes.text}>
                {t('substitutions_teacher_director_description')}  
              </p>
            </div>
          </div>
        )
      }
    </>
  );
};  

export default Substitutions;