import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { onlyUnique } from 'src/utils/useFunctions';

interface Model {
  [key: string]: any;
};

interface ArrayModel {
  attachments: any;
  images: any;
  svgs: any;
  videos: any;
};

const initialState: ArrayModel = {
  attachments: [],
  images: [],
  svgs: [],
  videos: [],
};

const slice = createSlice({
  name: 'medias',
  initialState: initialState,
  reducers: {
    setAttachments(state, action: PayloadAction<Model>) {
      const newData = [...state.attachments, action.payload];
      state.attachments = newData.filter(onlyUnique);
    },
    setImages(state, action: PayloadAction<Model>) {
      const newData = [...state.images, action.payload];
      state.images = newData.filter(onlyUnique);
    },
    setSvgs(state, action: PayloadAction<Model>) {
      const newData = [...state.svgs, action.payload];
      state.svgs = newData.filter(onlyUnique);
    },
    setVideos(state, action: PayloadAction<Model>) {
      const newData = [...state.videos, action.payload];
      state.videos = newData.filter(onlyUnique);
    },
    resetAttachments(state) {
      state.attachments = [];
    },
    resetImages(state) {
      state.images = [];
    },
    resetSvgs(state) {
      state.svgs = [];
    },
    resetVideos(state) {
      state.videos = [];
    },
  }
});

export const mediasSlice = slice;