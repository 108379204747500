import ChatBottomBar from '../ChatBottomBar';
import IconButton from 'src/components/Buttons/IconButton';
import Input from 'src/components/Forms/Input';
import React, { useCallback, useRef } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import useBreakpoint from 'src/utils/useBreakpoint';
import UsersInput from 'src/components/Forms/UsersInput';
import UsersSelect from 'src/components/Selects/UsersSelect';
import { addToCommunicationList, setCommunicationThreadID, setCommunicationUserID, setCommunicationViewMode } from 'src/store/actions/communication.actions';
import { base64ToArrayBuffer, getFileType, getQueryString, getSchoolSettings, isKey } from 'src/utils/useFunctions';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getUserRole } from 'src/utils/useUser';
import { setWarningModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    width: 'calc(100% - 24px)',
    padding: '12px',
    paddingTop: '12px',
    '& > span': {
      fontSize: '24px',
      fontWeight: '600',
    },
    '& > button': {
      width: '36px',
      height: '36px',
      backgroundColor: theme.colors.white,
      color: theme.colors.primaryBlue[500],
      zIndex: '1',
      '&:hover': {
        backgroundColor: theme.colors.grey[125],
      },
      '& > svg': {
        width: '100%',
        height: '100%',
        color: 'inherit',
      },
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '12px',
    },
  },
  row: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.colors.grey[325],
    '& > span': {
      padding: '4px 8px',
      whiteSpace: 'nowrap',
    },
  },
  selectInputWrapper: {
    width: '100%',
  },
  selectInput: {
    borderTopWidth: '0',
    borderLeftWidth: '0',
    borderRightWidth: '0',
    borderBottomWidth: '0',
    width: 'calc(100% - 21px)',
  },
  input: {
    position: 'relative',
    '& > div': {
      borderTopWidth: '0',
      borderLeftWidth: '0',
      borderRightWidth: '0',
      borderBottomWidth: '0',
      width: 'calc(100% - 52px)',
      backgroundColor: 'orange',
    },
    '& > span': {
      position: 'absolute',
      top: '0',
      right: '0',
      bottom: '0',
      width: '44px',
      display: 'flex',
      alignItems: 'center',
      marginTop: '0',
    },
  },
  chatBottomBar: {
    marginTop: 'auto',
  },
}));

const New: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const classes = useStyles();
  const { param: getUserID }: any = useParams();
  const communicationData = useAppSelector((state: any) => state.communication);
  const dataData = useAppSelector((state: any) => state.data);
  const userData = useAppSelector((state: any) => state.user);
  const communicationService = useAppSelector((state: any) => state.services).communicationService;

  const threadsList = communicationData.list;
  const maxUsers = userData.threadLimits["communication"].maxUsers;
  const userAccessSchools = userData.userAccessSchools;
  const schoolSettings = userData.schoolSettings;
  const cantCreateNewThread = userData.userObject.schoolSetting.every((schoolSetting: any) => schoolSetting.modules && schoolSetting.modules.communication && schoolSetting.modules.communication.banned === true);

  const queryString = getQueryString(location);
  const breakpoint: any = useBreakpoint();

  const layouts: any = {
    xxxxl: "desktop",
    xxxl: "desktop",
    xxl: "desktop",
    xl: "desktop",
    lg: "desktop",
    bg: "desktop",
    md: "mobile",
    co: "mobile",
    sm: "mobile",
    xs: "mobile",
  };

  const customViewMode = communicationData.viewMode;
  const customUserID = communicationData.userID;

  const layout = customViewMode ? "mobile" : layouts[breakpoint];

  const currentUserID = customUserID ? customUserID.toString() : (getUserID ? getUserID.toString() : "");
  const currentUserArray = currentUserID.length > 0 ? currentUserID.split(",").map((item: any) => {
    const data = item.split("-");
    return { userID: parseInt(data[0]), schoolID: parseInt(data[1]) };
  }) : [];
  
  const handleNavigate = useCallback((loc: any) => {
    if(customViewMode) {
      if(loc.includes("/")) {
        const getLoc = loc.split("/")[0];
        const thrdID = loc.split("/")[1];
        dispatch(setCommunicationThreadID(thrdID));
        dispatch(setCommunicationViewMode(getLoc));
      } else {
        dispatch(setCommunicationThreadID(null));
        dispatch(setCommunicationViewMode(loc));
      }
    } else {
      navigate(`/communication/${loc}${queryString}`);
    }
  }, [customViewMode, dispatch, navigate, queryString]);

  const getUserData = useCallback((userID: any) => {
    return dataData.users.filter((item: any) => item.userID === userID).length === 0 ? dataData.users.find((item: any) => item.userID === -1) : dataData.users.find((item: any) => item.userID === userID);
  }, [dataData.users]);

  const availableSchools = dataData.schools.filter((item: any) => userAccessSchools.communication.includes(item.schoolID)).map((item: any) => { return item; });
  const availableUsers = dataData.users.filter((item: any) => item.userID !== userData.userObject.userID && item.userID > 0);
  
  const availableRoles = () => {
    const result: any = {};
    availableSchools.forEach((item: any) => {
        const modules = getSchoolSettings(item.schoolID, 'modules', schoolSettings) === null ? 1 : getSchoolSettings(item.schoolID, 'modules', schoolSettings);
        const parentToParent = modules ? (modules.communication ? (modules.communication.parentToParent ? modules.communication.parentToParent : false) : false) : false;
        const roles = getUserRole(userData.userObject.roleType) === "parent" ? (parentToParent ? [4, 2, 3] : [4, 2]) : [4, 2, 3];
        result[item.schoolID] = roles;
    });
    return result;
  };

  const [state, setState] = useStates({
    isLoading: false,
    isProcesing: false,
    recipients: currentUserArray,
    threadName: "",
    isUsersSelectOpen: false,
    media: [],
    message: "",
  });

  const chatInputRef: any = useRef(null);
  const refMedia = useRef(state.media);

  const handleRecipients = (value: any) => {
    if(value.length > 1) {
      const users = value.map((item: any) => {
        return getUserData(item.userID).displayName;
      });
      const threadName = `${t('communication_group_thread')} ${userData.userObject.displayName}, ${users.slice(0,3).join(", ")}${users.length > 5 ? ` + ${users.length - 5} ${t('others').toLowerCase()}`: ''}`;
      setState("threadName", threadName);
    } else {
      setState("threadName", "");
    }
    setState("recipients", value);
  };

  const handleOnSubmit = (data: any) => {
    setState("isProcesing", true);
    const schoolID = state.recipients.map((item: any) => { return item.schoolID; })[0];
    const newMedias = state.media.filter((fileData: any) => fileData.status === "ready").map((fileData: any, key: any) => {
      return {id: key, name: fileData.name, type: getFileType(fileData.type), size: fileData.size};
    });
    const settings = {
      userID: state.recipients.map((item: any) => { return item.userID; }),
      schoolID: schoolID,
      text: data.value,
      name: state.threadName,
      mediaToUpload: newMedias,
    };
    communicationService && communicationService.createThread(settings).then((result: any) => {
      if(result) {
        if(result.data) {
          const response = result.data;
          const responseMedia = result.data.mediaToUpload;
          if(responseMedia.length > 0) {
            const uploadMedias = state.media.filter((fileData: any) => fileData.status === "ready").map((file: any, key: any) => {
              return {...file, uploadUrl: responseMedia.find((theFile: any) => theFile.id === key).uploadUrl};  
            })
            handleUploadMedias(response, uploadMedias);
          } else {
            handleFinish(response);
          }
        } else {
          createNotification(t("community_thread_failed_create"), "error");
          setState("isProcesing", false);
        }
      } else {
        createNotification(t("community_thread_failed_create"), "error");
        setState("isProcesing", false);
      }
    }).catch(() => {
      createNotification(t("community_thread_failed_create"), "error");
      setState("isProcesing", false);
    });
  };

  const handleUploadMedias = (response: any, sentFiles: any) => {
    const file = sentFiles[0];
    const newFiles = sentFiles.filter((fileData: any) => fileData.uid !== file.uid);
    const updateFiles = refMedia.current.map((fileData: any) => {
      if(fileData.uid === file.uid) {
        return {...fileData, status: "uploading"};
      } else {
        return fileData;
      }
    });
    setMedia(updateFiles);
    const reader = new FileReader();
    reader.onload = function() {
      const dataUrl: any = reader.result;
      const base64 = dataUrl.split(',')[1];
      const arrayBuffer = base64ToArrayBuffer(base64);
      communicationService && communicationService.uploadFile(file.uploadUrl.replace("http://", "https://"), arrayBuffer).then((result: any) => {
        if(result) {
          if(result.status === 201) {
            const updateFiles = refMedia.current.map((fileData: any) => {
              if(fileData.uid === file.uid) {
                return {...fileData, status: "uploaded"};
              } else {
                return fileData;
              }
            });
            setMedia(updateFiles);
            if(newFiles.length === 0) {
              handleFinish(response); 
            } else {
              handleUploadMedias(response, newFiles);  
            }
          } else {
            createNotification(t("community_thread_failed_create"), "error");
            const updateFiles = refMedia.current.map((fileData: any) => {
              if(fileData.status === "uploaded" || fileData.status === "uploading") {
                return {...fileData, status: "ready"};
              } else {
                return fileData;
              }
            });
            setMedia(updateFiles);
            setState("isProcesing", false);
          }
        } else {
          createNotification(t("community_thread_failed_create"), "error");
          const updateFiles = refMedia.current.map((fileData: any) => {
            if(fileData.status === "uploaded" || fileData.status === "uploading") {
              return {...fileData, status: "ready"};
            } else {
              return fileData;
            }
          });
          setMedia(updateFiles);
          setState("isProcesing", false);
        }
      }).catch((e: any) => {
        const updateFiles = refMedia.current.map((fileData: any) => {
          if(fileData.status === "uploaded" || fileData.status === "uploading") {
            return {...fileData, status: "ready"};
          } else {
            return fileData;
          }
        });
        setMedia(updateFiles);
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("community_thread_failed_create"), "error");
        setState("isProcesing", false);
      });
    };
    reader.readAsDataURL(file.blob);
  };

  const handleFinish = (response: any) => {
    if(threadsList.filter((item: any) => item.threadID === response.threadID).length === 0) {
      dispatch(addToCommunicationList(response));
    }
    handleNavigate(`thread/${response.threadID}`);
  };

  const handleBackToThreads = () => {
    handleNavigate("threads");
  };

  const setMessage = (message: any) => {
    setState("message", message);
  };

  const setMedia = (media: any) => {
    setState("media", media);
    refMedia.current = media;
  };

  const onInputChange = (name: any, value: any) => {
    setState(name, value);
  };

  useEffect(() => {
    dispatch(setCommunicationUserID(null));
  }, [dispatch], []);

  useEffect(() => {
    const url = location.pathname.replace("/communication/new/", "");
    if(url !== "/communication/new") {
      window.history.replaceState({},'',"/communication/new");
    }
  }, [location.pathname], []);

  useEffect(() => {
    if(cantCreateNewThread) {
      handleNavigate("threads");
      const settings = {
        isOpen: true,
        title: t('function_unavailable'),
        content: t('function_unavailable_communication_new'),
      };
      dispatch(setWarningModal(settings));
    }
  }, [cantCreateNewThread, handleNavigate, dispatch, t], []);

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        {
          layout === "mobile" ? (
            <IconButton tooltip={t('back')} tooltipPosition='bottom' tooltipMaxWidth={400} onClick={handleBackToThreads}>
              <SVG src="arrow-left"/>
            </IconButton>
          ) : null
        }
        <span>{t('communication_new_thread')}</span>
      </div>
      <div className={classes.row}>
        <span>{t('recipients')}:</span>
        <UsersInput showSelectLabelIcon={false} selectLabel={t('select_atleast_one_recipient')} className={classes.selectInput} classNameWrapper={classes.selectInputWrapper} maxRows={3} selectedUsers={state.recipients} setSelectedUsers={(value: any) => handleRecipients(value)} onClick={() => setState("isUsersSelectOpen", true)}/>
      </div>
      {
        state.isUsersSelectOpen ? (
          <UsersSelect
            selectedUsers={state.recipients.map((item: any) => { return { userID: item.userID, schoolID: item.schoolID }; })}
            isSelectAll={true}
            isMultipleSelect={true}
            isAllowSearch={true}
            isInModal={true}
            isModalOpen={true}
            isGroupByRoles={true}
            groupRoles={availableRoles()}
            modalTitle='recipients'
            modalOnClose={() => setState("isUsersSelectOpen", false)}
            modalOnSave={(value: any) => handleRecipients(value)}
            modalAllowClose={true}
            modalAllowClear={true}
            modalAllowNoUser={true}
            mode="select"
            defaultSchools={availableSchools}
            defaultUsers={availableUsers}
            validateMultipleSchool={true}
            maxUsersSelected={maxUsers}
            customCombineMessage={t('cannot_combine_users_school')}
            customLimitMessage={t('limit_exceeded_users_thread')}
          />
        ) : null
      }
      {
        state.recipients.length > 1 ? (
          <div className={classes.row}>
            <span>{t('communication_thread_name')}:</span>
            <Input className={classes.input} name="threadName" value={state.threadName} onChange={onInputChange} maxLength={255} showCounter={true} autoFocus/>
          </div>
        ) : null
      }
      {
        state.recipients.length > 0 ? (
          <ChatBottomBar message={state.message} setMessage={setMessage} media={state.media} setMedia={setMedia} onSubmit={handleOnSubmit} className={classes.chatBottomBar} disabled={state.isProcesing} chatInputRef={chatInputRef}/>
        ) : null
      }              
    </div>
  );
};

export default New;