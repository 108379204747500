import { AnyAction } from '@reduxjs/toolkit';
import { browserSlice as slice } from '../slices/browser.slice';
import { RootState } from '../index';
import { ThunkAction } from '@reduxjs/toolkit';

export const actions = slice.actions;

export const setBrowserWidth = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setBrowserWidth(data));
  }
};

export const setBrowserHeight = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setBrowserHeight(data));
  }
};

export const setBrowserIsStandalone = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setBrowserIsStandalone(data));
  }
};