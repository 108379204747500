import CloseButton from 'src/components/Buttons/CloseButton';
import IconButton from 'src/components/Buttons/IconButton';
import Modal from '../../../utils/modal';
import React from 'react';
import SVG from '../../Images/SvgRenderer';
import TimelineCard from '../../Cards/TimelineCard';
import { CircularProgress } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { setFilterParams } from '../../../store/actions/filters.actions';
import { setIsFiltersVisible } from '../../../store/actions/layout.actions';
import { setPostDetailModal } from '../../../store/actions/modals.actions';
import { setTimelinePosts } from '../../../store/actions/timeline.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation, useNavigate } from 'react-router';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';
import { getQueryParams, getQueryString } from 'src/utils/useFunctions';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    maxWidth: '100vw',
    maxHeight: '90vh',
    overflow: "visible",
    position: 'relative',
  },
  buttons: {
    position: 'absolute',
    top: '-5px',
    right: '-5px',
    zIndex: '3',
    display: 'flex', 
    gap: '0.5rem', 
  },
  button: {
    backgroundColor: theme.colors.white,
    transition: 'background-color 0.25s',
    boxShadow: theme.shadows[2],
    padding: '10px',
    width: '36px',
    height: '36px',
    '&:hover': {
      backgroundColor: theme.colors.grey[300],
    },
    '& svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  wrapper: {
    width: "800px",
    maxWidth: 'calc(90vw - 20px)',
    overflow: "auto",
    padding: '10px 10px 0 10px',
    maxHeight: 'calc(90vh - 40px)',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
}));

const PostDetailModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const modalsData = useAppSelector((state: any) => state.modals);
  const timelineService = useAppSelector((state: any) => state.services).timelineService;
  const postID = modalsData.postDetailModal.postID;

  const queryParams = getQueryParams(location);
  const queryString = getQueryString(location);
  const embed = queryParams.get("embed");
  const isEmbed = embed === "true" ? true : false;

  const [state, setState] = useStates({
    posts: [],
    isLoading: true,
  });
  
  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      childID: null,
    };
    dispatch(setPostDetailModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleTimeline = (e: any) => {
    e.stopPropagation();
    navigate(`/timeline${queryString}`);
    dispatch(setFilterParams({postID: postID.isArray ? postID : [postID]}));
    setTimeout(() => {
      dispatch(setFilterParams({postID: postID.isArray ? postID : [postID]}));
      dispatch(setIsFiltersVisible(true));
    }, 100);
  };
  
  useEffect(() => {
    timelineService && timelineService.listPosts({postID: postID.isArray ? postID.join(",") : postID}).then((result: any) => {
      if(result) {
        if(result.data) {
          if(result.data.posts) {
            let tempPosts: any = [];
            result.data.posts.forEach((post: any) => {
              let childrenID: any = [];
              post.children.forEach((child: any) => {
                childrenID = [...childrenID, child.childID];
              });
              post = {...post, uniqueID: post.postID + "_" + childrenID.join()};
              tempPosts = [...tempPosts, post];
            });
            dispatch(setTimelinePosts(tempPosts));
            setState("posts", tempPosts);
            setState("isLoading", false);
          }
        }
      }
    }); 
  }, [dispatch, postID, setState, timelineService], []); 
  
  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      {
        state.isLoading ? (
          <div className={classes.loading}>
            <CircularProgress className={classes.spinner}/>
          </div>
        ) : (
          <div className={classes.root} data-cy={isCypress() ? "postDetailModal" : null}>
            <div className={classes.buttons}>
              {
                !isEmbed ? (
                  <IconButton className={classes.button} onClick={handleTimeline} data-cy={isCypress() ? "openButton" : null} tooltip={t('view_posts')} tooltipMaxWidth={400} tooltipPosition='bottom'>
                    <SVG src="posts"/>
                  </IconButton>
                ) : null
              }
              <CloseButton className={classes.button} onClick={handleClose} dataCy="timesButton"/>
            </div>
            <div className={classes.wrapper}>
              <div className={classes.list}>
                {
                  state.posts.map((post: any, key: any) => (
                    <TimelineCard
                      uniqueID={post.uniqueID}
                      key={`k_${key}`} 
                    />
                  ))
                }
              </div>
            </div>
          </div>
        )
      }
    </Modal>
  );
};  

export default PostDetailModal;