import CloseButton from 'src/components/Buttons/CloseButton';
import config from 'src/constants/config';
import Currency from 'react-currency-formatter';
import Input from 'src/components/Forms/Input';
import Modal from '../../../utils/modal';
import NormalButton from 'src/components/Buttons/NormalButton';
import React, { useCallback, useRef } from 'react';
import Select from 'src/components/Forms/Select';
import ShopGallery from 'src/components/Gallery/ShopGallery';
import SVG from 'src/components/Images/SvgRenderer';
import theme from 'src/ui/theme';
import TooltipIcon from 'src/components/Icons/TooltipIcon';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getSchoolSettings, handlePositiveSum, handleSum } from 'src/utils/useFunctions';
import { isCypress } from '../../../utils/useCypress';
import { setShopItemDetailModal } from '../../../store/actions/modals.actions';
import { setStockCart, setStockItems } from 'src/store/actions/stock.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    padding: "20px",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
      maxWidth: '600px',
      display: 'block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  body: {
    marginTop: "20px",
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
  },
  footer: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: 'wrap',
    marginTop: '16px',
    gap: '16px',
  },
  item: {
    display: 'flex',
    paddingLeft: "25px",
    flexDirection: 'column',
    '& + div': {
      marginTop: '16px',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.colors.grey[700],
    '& > svg': {
      width: '18.5px',
      height: '18.5px',
      marginRight: '8px',  
    }, 
  },
  detail: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    fontSize: '14px',
    color: '#9B9EBB',
    maxHeight: '300px',
    overflow: 'auto',
    gap: '8px',
    '& > img': {
      width: '20px',
      height: '20px',
      marginRight: '8px',
      borderRadius: '100%',
      padding: '4px', 
    }, 
    '& > span': {
      maxWidth: '100%',
      display: 'block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    }
  },
  blockWrapper: {
    display: 'flex',
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  quantityItem: {
    width: 'auto',
    '& > div': {
      minHeight: '42px',
    },
  },
  addToCart: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    marginLeft: 'auto',
    '& svg': {
      width: '24px',
      height: '24px',
    },
  },
}));

const ShopItemDetailModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const configurationData = useAppSelector((state: any) => state.configuration).configuration;
  const modalsData = useAppSelector((state: any) => state.modals);
  const userData = useAppSelector((state: any) => state.user);
  const stockData = useAppSelector((state: any) => state.stock);
  const stockService = useAppSelector((state: any) => state.services).stockService;
  const schoolCart = stockData.cart;
  const quantityInputRef: any = useRef(null);

  const isValid = modalsData.shopItemDetailModal.itemID === null ? false : true;
  const itemID = isValid ? modalsData.shopItemDetailModal.itemID : null;
  const variantID = isValid ? modalsData.shopItemDetailModal.variantID : null;
  const schoolID = isValid ? modalsData.shopItemDetailModal.schoolID : null;
  const isItemExist = stockData.items.length > 0 ? stockData.items.filter((item: any) => item.itemID === itemID).length === 1 : false;
  const itemData = isValid ? (isItemExist ? stockData.items.find((item: any) => item.itemID === itemID) : {}) : {};

  const showCart = modalsData.shopItemDetailModal.showCart;
  const shoppingCart = stockData.cart;
  const schoolSettings = userData.schoolSettings;

  const shoppingCartItems = shoppingCart.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : shoppingCart.find((item: any) => item.schoolID === schoolID).items;

  const [state, setState] = useStates({
    selectedVariant: null,
    selectedVariantID: variantID ? variantID : null,
    quantity: 1,
  });

  const getVariantData = (state.selectedVariantID !== null && Object.keys(itemData).length !== 0 && itemData.variant.length !== 0) ? itemData.variant.filter((item: any) => item.variantID === state.selectedVariantID).length === 0 ? {} : itemData.variant.find((item: any) => item.variantID === state.selectedVariantID) : {};

  const currencyID = getSchoolSettings(schoolID, 'currencyID', schoolSettings) === null ? 1 : getSchoolSettings(schoolID, 'currencyID', schoolSettings);
  const getCurrency = currencyID ? (configurationData.currencies.filter((currency: any) => currency.currencyID === currencyID).length === 0 ? [] : configurationData.currencies.find((currency: any) => currency.currencyID === currencyID)) : [];

  const tax = (isItemExist && Object.keys(itemData).length !== 0) ? (itemData.taxRate / 100) + 1 : 1;
  const price: any = (isItemExist && Object.keys(itemData).length !== 0) ? (itemData.variant.length === 0 ? (itemData.price * tax) : (Math.min(...itemData.variant.map((item: any) => { return (item.price * tax); })) === Math.max(...itemData.variant.map((item: any) => { return (item.price * tax); })) ? (itemData.variant[0].price * tax) : [Math.min(...itemData.variant.map((item: any) => { return (item.price * tax); })), Math.max(...itemData.variant.map((item: any) => { return (item.price * tax); }))])) : [];
  const quantity = (isItemExist && Object.keys(itemData).length !== 0) ? (itemData.variant.length === 0 ? itemData.quantity : itemData.variant.map((item: any) => { return item.quantity; }).reduce(handlePositiveSum, 0)) : [];

  const variantPrice: any = state.selectedVariantID === null ? 0 : getVariantData.price * tax;
  const variantQuantity: any = state.selectedVariantID === null ? 0 : getVariantData.quantity;

  const viewQuantity = itemData.quantity === null ? null : (state.selectedVariantID === null ? quantity : variantQuantity);

  const cartItemQuantity = shoppingCartItems.filter((item: any) => item.itemID === itemID).length === 0 ? 0 : (shoppingCartItems.filter((item: any) => item.itemID === itemID).length === 1 ? shoppingCartItems.find((item: any) => item.itemID === itemID).quantity : shoppingCartItems.filter((item: any) => item.itemID === itemID).map((item: any) => { return item.quantity; }).reduce(handleSum, 0));
  const cartVariantQuantity = state.selectedVariantID === null ? 0 : shoppingCartItems.filter((item: any) => item.itemID === itemID && item.variantID === state.selectedVariantID).length === 0 ? 0 : shoppingCartItems.find((item: any) => item.itemID === itemID && item.variantID === state.selectedVariantID).quantity;

  const cartQuantity = state.selectedVariantID === null ? cartItemQuantity : cartVariantQuantity;

  const realQuantity = viewQuantity - cartQuantity;

  const onCloseModal = useCallback(() => {
    const settings = {
      isOpen: false,
      itemID: null,
      variantID: null,
      showCart: false,
      schoolID: null,
    };
    dispatch(setShopItemDetailModal(settings));
  }, [dispatch]);

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleAddToCart = () => {
    let showNotify = true;
    if(itemData.variant.length === 0) {
      const cartItems = schoolCart.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : stockData.cart.find((item: any) => item.schoolID === schoolID).items;
      const isExist = cartItems.filter((item: any) => item.itemID === itemData.itemID).length === 0 ? false : true;
      let newSchoolCart: any = schoolCart;
      if(isExist) {
        newSchoolCart = newSchoolCart.map((item: any) => {
          if(item.schoolID === schoolID) {
            const newItems = item.items.map((subItem: any) => {
              if(subItem.itemID === itemData.itemID) {
                const newQuantity = subItem.quantity + state.quantity;
                if(newQuantity > config.MAX_ITEMS_IN_CART) {
                  createNotification(t('stock_shop_item_exceeded', {max: config.MAX_ITEMS_IN_CART}), "info");
                  showNotify = false;
                }
                return {...subItem, quantity: newQuantity > config.MAX_ITEMS_IN_CART ? config.MAX_ITEMS_IN_CART : newQuantity};
              } else {
                return subItem;
              }
            });
            return { schoolID: schoolID, items: newItems };
          } else {
            return item;
          }
        });
      } else {
        const newItemToCart = {
          itemID: itemData.itemID,
          quantity: state.quantity,
        };
        newSchoolCart = newSchoolCart.map((item: any) => {
          if(item.schoolID === schoolID) {
            const newItems = [...item.items, newItemToCart];
            return { schoolID: schoolID, items: newItems };
          } else {
            return item;
          }
        });
      }
      dispatch(setStockCart(newSchoolCart));
      if(showNotify) createNotification(t('stock_item_added_to_cart'), 'success');
      onCloseModal();
    } else {
      const cartItems = schoolCart.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : stockData.cart.find((item: any) => item.schoolID === schoolID).items;
      const isExist = cartItems.filter((item: any) => item.itemID === itemData.itemID && item.variantID === state.selectedVariant.variantID).length === 0 ? false : true;
      let newSchoolCart: any = schoolCart;
      if(isExist) {
        newSchoolCart = newSchoolCart.map((item: any) => {
          if(item.schoolID === schoolID) {
            const newItems = item.items.map((subItem: any) => {
              if(subItem.itemID === itemData.itemID && subItem.variantID === state.selectedVariantID) {
                const newQuantity = subItem.quantity + state.quantity;
                if(newQuantity > config.MAX_ITEMS_IN_CART) {
                  createNotification(t('stock_shop_item_exceeded', {max: config.MAX_ITEMS_IN_CART}), "info");
                  showNotify = false;
                }
                return {...subItem, quantity: newQuantity > config.MAX_ITEMS_IN_CART ? config.MAX_ITEMS_IN_CART : newQuantity};
              } else {
                return subItem;
              }
            });
            return { schoolID: schoolID, items: newItems };
          } else {
            return item;
          }
        });
      } else {
        const newItemToCart = {
          itemID: itemData.itemID,
          variantID: state.selectedVariantID,
          quantity: state.quantity,
        };
        newSchoolCart = newSchoolCart.map((item: any) => {
          if(item.schoolID === schoolID) {
            const newItems = [...item.items, newItemToCart];
            const newItem = { schoolID: schoolID, items: newItems };
            return newItem;
          } else {
            return item;
          }
        });
      }
      const newCart = newSchoolCart;
      dispatch(setStockCart(newCart));
      if(showNotify) createNotification(t('stock_item_added_to_cart'), 'success');
      setState("selectedVariant", null);
      setState("selectedVariantID", null);
      quantityInputRef.current.value = 1;
    }
  };

  const setSelectedVariant = (value: any) => {
    setState("selectedVariant", value);
    if(value !== null) {
      setState("selectedVariantID", value.variantID);
    } else {
      setState("selectedVariantID", null);
    }
  };

  const handleInputChange = (name: any, value: any) => {
    const maxValue = quantityInputRef.current.max;
    if(value !== "" && parseInt(value) > 0) {
      if(parseInt(value) > parseInt(maxValue)) {
        value = parseInt(maxValue);
        quantityInputRef.current.value = parseInt(maxValue);
      }
      setState(name, parseInt(value));
    }
  };

  useEffect(() => {
    if(!isItemExist) {
      const settings = {
        itemID: itemID,
        includeDeleted: true,
      };
      stockService && stockService.listItems(settings).then((result: any) => {
        if(result) {
          if(result.data) {
            if(result.data.items.length === 0) {
              createNotification(t("stock_item_not_loaded"), "error");
              onCloseModal();
            } else {
              const newItems = [...stockData.items, result.data.items[0]];
              dispatch(setStockItems(newItems));
            }
          } else {
            createNotification(t("stock_items_not_loaded"), "error");
          }
        } else {
          createNotification(t("stock_items_not_loaded"), "error");
        }
      }).catch(() => {
        createNotification(t("stock_items_not_loaded"), "error");
      });
    }
  }, [isItemExist, itemID, setState, stockService, t, dispatch, stockData.items, onCloseModal], [isItemExist]);

  const handleEmptyQuantity = (quantity: any, ogquantity: any) => {
    if(quantity === "") {
      quantityInputRef.current.value = ogquantity;
    } else if(parseInt(quantity) <= 0) {
      quantityInputRef.current.value = ogquantity;
    }
  };

  return (isValid && isItemExist) ? (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "stockItemDetailModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p>{t('stock_item')} {itemData.name}</p>
          </div>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div className={classes.body}>
          <div className={classes.blockWrapper}>
            <div className={classes.block}>
              <div className={classes.item}>
                <p className={classes.title}>
                  <SVG src="info-circle-outlined"/>
                  {t('stock_item_name')}
                </p>
                <p className={classes.detail}>
                  <span>{state.selectedVariantID === null ? itemData.name : (itemData.name + " - " + getVariantData.name)}</span>
                </p>
              </div>
              <div className={classes.item}>
                <p className={classes.title}>
                  <SVG src="info-circle-outlined"/>
                  {t('stock_item_price')}
                </p>
                <p className={classes.detail}>
                {
                  Array.isArray(price) ? (
                    <>
                      {
                        state.selectedVariantID === null ? (
                          <>
                            <Currency quantity={parseFloat(price[0])} currency={getCurrency.iso}/>
                            &nbsp;-&nbsp;
                            <Currency quantity={parseFloat(price[1])} currency={getCurrency.iso}/>
                          </>
                        ) : (
                          <Currency quantity={parseFloat(variantPrice)} currency={getCurrency.iso}/>
                        )
                      }
                    </>
                  ) : (
                    <>
                    {
                      state.selectedVariantID === null ? (
                        <Currency quantity={parseFloat(price)} currency={getCurrency.iso}/>
                      ) : (
                        <Currency quantity={parseFloat(variantPrice)} currency={getCurrency.iso}/>
                      )
                    }
                    </>
                  )
                }
                </p>
              </div>
            </div>
            <div className={classes.block}>
              <div className={classes.item}>
                <p className={classes.title}>
                  <SVG src="info-circle-outlined"/>
                  {t('stock_item_available_quantity')}
                </p>
                <p className={classes.detail}>
                  {
                    cartQuantity === 0 ? (viewQuantity === null ? t('stock_quantity_unlimited') : viewQuantity) : (
                      <>{viewQuantity === null ? t('stock_quantity_unlimited') : (viewQuantity - cartQuantity)} ({cartQuantity} {t('stock_in_cart').toLowerCase()})</>
                    )
                  }
                </p>
              </div>
            </div>
          </div>
          {
            itemData.media.length !== 0 ? (
              <ShopGallery itemID={itemData.itemID}/>
            ) : null
          }
        </div>
        {
          (showCart && itemData.active && !itemData.deleted) ? (
            <div className={classes.footer}>
              <Input className={classes.quantityItem} placeholder={t('stock_item_quantity')} name="quantity" type="number" value={state.quantity} min={1} max={config.MAX_ITEMS_IN_CART} step={1} onChange={handleInputChange} onBlur={(name: any, value: any) => handleEmptyQuantity(value, state.quantity)} customRefInput={quantityInputRef}/>
              {
                itemData.variant.length !== 0 ? (
                  <Select selected={state.selectedVariant} setSelected={setSelectedVariant} items={itemData.variant}/>
                ) : null
              }
              <div className={classes.addToCart}>
                {
                  (viewQuantity !== null && state.quantity > realQuantity && (itemData.variant.length === 0 || state.selectedVariantID !== null)) ? (
                    <TooltipIcon icon="warning" maxWidth={200} iconColor={theme.colors.systemOrange[500]} position="top" title={t('stock_item_no_stock')}/>
                  ) : null
                }
                <NormalButton onClick={handleAddToCart} disabled={state.quantity > 0 && (itemData.variant.length === 0 ? (state.selectedVariantID !== null) : (state.selectedVariantID === null))} dataCy="addToCartButton">
                  {t('stock_add_to_cart')}
                </NormalButton>
              </div>
            </div>
          ) : null
        }
      </div>
    </Modal>
  ) : null;
};

export default ShopItemDetailModal;