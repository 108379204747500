import React from 'react';
import { JssProvider } from 'react-jss';

interface Props {
  children: any;
};

const ClassProvider: React.FunctionComponent<Props> = (props:Props) => {

  const config = require('../constants/config').default;

  return (
    <JssProvider id={{minify: config.APP_ENVIRONMENT === "production"}} classNamePrefix={config.APP_ENVIRONMENT === "localhost" ? "" : "twgs_"}>
      {props.children} 
    </JssProvider>
  );
};

export default ClassProvider;