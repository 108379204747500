import { AnyAction } from '@reduxjs/toolkit';
import { classbookSlice as slice } from '../slices/classbook.slice';
import { RootState } from '../index';
import { ThunkAction } from '@reduxjs/toolkit';

export const actions = slice.actions;

export const setClassbookFilterSchoolID = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setClassbookFilterSchoolID(data));
  }
};

export const setClassbookFilterClassID = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setClassbookFilterClassID(data));
  }
};

export const setClassbookDate = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setClassbookDate(data));
  }
};

export const setClassbookList = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setClassbookList(data));
  }
};

export const updateClassbookList = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.updateClassbookList(data));
  }
};

export const setClassbookAlerts = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setClassbookAlerts(data));
  }
};

export const removeClassbookAlert = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.removeClassbookAlert(data));
  }
};

export const resetClassbook = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.resetClassbook());
  }
};