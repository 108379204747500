import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Model {
  [key: string]: any;
};

interface ArrayModel {
  childID: any;
  type: any;
};

const initialState: ArrayModel = {
  childID: null,
  type: null,
};

const slice = createSlice({
  name: 'layout',
  initialState: initialState,
  reducers: {
    setDiagnosticsChildID(state, action: PayloadAction<Model>){
      state.childID = action.payload;
    },
    setDiagnosticsType(state, action: PayloadAction<Model>){
      state.type = action.payload;
    },
  }
});

export const diagnosticsSlice = slice;