import { AnyAction } from '@reduxjs/toolkit';
import { filtersSlice as slice } from '../slices/filters.slice';
import { RootState } from '../index';
import { ThunkAction } from '@reduxjs/toolkit';

export const actions = slice.actions;

export const setFilterParams = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setFilterParams(data));
  }
};

export const setFiltersSettings = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setFiltersSettings(data));
  }
};

export const setIsFilterParamsLoaded = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setIsFilterParamsLoaded(data));
  }
};

export const setIsFilterSettingsLoaded = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setIsFilterSettingsLoaded(data));
  }
};

export const setIsFilterSetupLoaded = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setIsFilterSetupLoaded(data));
  }
};

export const resetFilterParams = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.resetFilterParams());
  }
};

export const resetAllFiltersSettings = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.resetAllFiltersSettings());
  }
};