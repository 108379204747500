import { AnyAction } from '@reduxjs/toolkit';
import { notificationSlice as slice } from '../slices/notification.slice';
import { RootState } from '../index';
import { ThunkAction } from '@reduxjs/toolkit';

export const actions = slice.actions;

export const setNotification = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setNotifications(data));
  }
};