import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Model {
  [key: string]: any;
};

interface ArrayModel {
  isFailed: any;
  isLanguageChanging: any;
  isLanguageLoaded: any;
  isUserLoaded: any;
  isUserDataLoaded: any;
  userLoadedStatus: any;
};

const initialState: ArrayModel = {
  isFailed: false,
  isLanguageChanging: false,
  isLanguageLoaded: false,
  isUserLoaded: false,
  isUserDataLoaded: false,
  userLoadedStatus: 0,
};

const slice = createSlice({
  name: 'loading',
  initialState: initialState,
  reducers: {
    setIsFailed(state, action: PayloadAction<Model>){
      state.isFailed = action.payload;
    },
    setIsLanguageChanging(state, action: PayloadAction<Model>){
      state.isLanguageChanging = action.payload;
    },
    setIsLanguageLoaded(state, action: PayloadAction<Model>){
      state.isLanguageLoaded = action.payload;
    },
    setIsUserLoaded(state, action: PayloadAction<Model>){
      state.isUserLoaded = action.payload;
    },
    setIsUserDataLoaded(state, action: PayloadAction<Model>){
      state.isUserDataLoaded = action.payload;
    },
    setUserLoadedStatus(state, action: PayloadAction<Model>){
      state.userLoadedStatus = action.payload;
    },
    updateUserLoadedStatus(state, action: PayloadAction<Model>){
      state.userLoadedStatus = state.userLoadedStatus + action.payload;
    },
  }
});

export const loadingSlice = slice;