import DateFormat from 'src/utils/dateFormat';
import Flag from 'react-world-flags';
import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import Tooltip from 'src/components/Layouts/Tooltip';
import { Button } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

interface Props {
  isCurrentToken: any;
};

const useStyles = createUseStyles((theme: any) => ({
  loggedInDeviceListItem: {
    order: (props: Props) => {
      if(props.isCurrentToken) return '0';
      else return '1';
    },
  },
  deviceItem: {
    position: 'relative',
    padding: "10px 15px",
    display: "flex",
    flexDirection: 'column',
    width: '100%',
    minWidth: 'unset',
    borderRadius: '12px',
    gap: '8px',
    '&:hover': {
      backgroundColor: theme.colors.hoverBlack[4],
      cursor: "pointer",
    },
  },
  row: {
    display: "flex",
    justifyContent: 'flex-start',
    width: '100%',
    gap: '12px',
    '&:empty': {
      display: 'none',
    },
  },
  icon: {
    width: '32px',
    height: '32px',
    backgroundColor: theme.colors.grey[200],
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.colors.black,
    '& > svg': {
      width: '20px',
      height: '20px',
      color: theme.colors.black,
    },
  },
  title: {
    fontSize: '13px',
    textTransform: 'none',
    fontWeight: '400',
    color: theme.colors.black,
    lineHeight: '20px',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    '& > em': {
      display: 'inline-flex',
      height: 'fit-content',
      fontStyle: 'unset',
      fontSize: '9px',
      padding: '0px 5px',
      borderRadius: '6px',
      backgroundColor: theme.colors.primaryBlue[500],
      color: theme.colors.white,
    },
  },
  uses: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
    width: '22px',
    height: '22px',
    fontSize: '9px',
    lineHeight: '9px',
    backgroundColor: theme.colors.primaryBlue[500],
    color: theme.colors.white,
    borderWidth: '3px',
    borderStyle: 'solid',
    borderColor: theme.colors.white,
    borderRadius: '100%',
  },
  chevron: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
    '& > svg': {
      width: '20px',
      height: '20px',
      color: theme.colors.black,
    },
  },
  items: {
    display: "flex",
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '100%',
    gap: '4px',
  },
  detail: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    fontSize: '13px',
    textTransform: 'none',
    fontWeight: '400',
    color: theme.colors.black,
    '& > span': {
      whiteSpace: 'nowrap',
      fontWeight: '600',
      '&::after': {
        content: `':'`,
      },
    },
  },
  flagWrapper: {
    display: 'flex',
    height: 'fit-content',
  },
}));

type LoggedInDeviceListItemType = {
  tokenID: number;
  isCurrentToken: boolean;
  appVersion: string;
  countryCodes: string[];
  generatedDateTime: any;
  ipAddress: string;
  lastBrowser: string;
  lastOs: string;
  lastUseDateTime: any;
  platformID: string,
  tokenMask: string,
  tokenUses: any[];
  handleChangeToken: any;
};

const LoggedInDeviceListItem: React.FunctionComponent<LoggedInDeviceListItemType> = ({ tokenID, isCurrentToken, appVersion, countryCodes, generatedDateTime, ipAddress, lastBrowser, lastOs, lastUseDateTime, platformID, tokenMask, tokenUses, handleChangeToken }) => {
  
  const languageData = useAppSelector((state: any) => state.language);
  const { t } = useTranslation();

  const classes = useStyles({
    isCurrentToken: isCurrentToken,
  });
  
  return (
    <div className={classes.loggedInDeviceListItem}>
      <Button className={classes.deviceItem} onClick={() => handleChangeToken(tokenID)}>
        <div className={classes.row}>
          <span className={classes.icon}>
            <SVG src={`device-${platformID}`}/>
          </span>
          <span className={classes.title}>
            {tokenMask}
            {
              isCurrentToken ? (
                <em>{t('logged_in_devices_current_device')}</em>
              ) : null
            }
          </span>
          <span className={classes.uses}>
            {tokenUses.length}
          </span>
        </div>
        <div className={classes.row}>
          {
            (ipAddress || lastBrowser || lastOs || (countryCodes && countryCodes.length > 0)) ? (
              <div className={classes.items}>
                {
                  ipAddress ? (
                    <div className={classes.detail}>
                      <span>{t('logged_in_devices_ip_address')}</span>
                      {ipAddress}
                    </div> 
                  ) : null
                }
                {
                  lastBrowser ? (
                    <div className={classes.detail}>
                      <span>{t('logged_in_devices_browser')}</span>
                      {lastBrowser}
                    </div> 
                  ) : null
                }
                {
                  lastOs ? (
                    <div className={classes.detail}>
                      <span>{t('logged_in_devices_os')}</span>
                      {lastOs}
                    </div> 
                  ) : null
                }
                {
                  (countryCodes && countryCodes.length > 0) ? (
                    <div className={classes.detail}>
                      <span>{t('logged_in_devices_country')}</span>
                      {countryCodes.map((item: any, key: any) => (
                        <Tooltip title={item} key={`k_${key}`}>
                          <div className={classes.flagWrapper}>
                            <Flag code={(item || 'us')} height="16"/>
                          </div>
                        </Tooltip>
                      ))}
                    </div> 
                  ) : null
                }
              </div>
            ) : null
          }
          {
            (platformID || appVersion || generatedDateTime || lastUseDateTime) ? (
              <div className={classes.items}>
                {
                  platformID ? (
                    <div className={classes.detail}>
                      <span>{t('logged_in_devices_platform')}</span>
                      {platformID}
                    </div> 
                  ) : null
                }
                {
                  appVersion ? (
                    <div className={classes.detail}>
                      <span>{t('logged_in_devices_app_version')}</span>
                      {appVersion}
                    </div> 
                  ) : null
                }
                {
                  generatedDateTime ? (
                    <div className={classes.detail}>
                      <span>{t('logged_in_devices_first_date')}</span>
                      {DateFormat(generatedDateTime, "defaulttime", languageData)}
                    </div> 
                  ) : null
                }
                {
                  lastUseDateTime ? (
                    <div className={classes.detail}>
                      <span>{t('logged_in_devices_last_update')}</span>
                      {DateFormat(lastUseDateTime, "defaulttime", languageData)}
                    </div> 
                  ) : null
                }
              </div>
            ) : null
          }
        </div>
        <span className={classes.chevron}>
          <SVG src="chevron-right"/>
        </span>
      </Button>
    </div>
  );
}

export default LoggedInDeviceListItem;