import { AnyAction } from '@reduxjs/toolkit';
import { commentsSlice as slice } from '../slices/comments.slice';
import { RootState } from '../index';
import { ThunkAction } from '@reduxjs/toolkit';

export const actions = slice.actions;

export const setComments = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setComments(data));
  }
};

export const updateComments = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.updateComments(data));
  }
};

export const replaceComment = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.replaceComment(data));
  }
};

export const setCommentsEdited = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCommentsEdited(data));
  }
};

export const setCommentsVisibility = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCommentsVisibility(data));
  }
};

export const setCommentsDeleted = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCommentsDeleted(data));
  }
};

export const setCommentsReplyTo = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCommentsReplyTo(data));
  }
};