import config from 'src/constants/config';
import IconButton from 'src/components/Buttons/IconButton';
import LoggedInDeviceInfoItem from 'src/components/Items/LoggedInDeviceInfoItem';
import LoggedInDeviceListItem from 'src/components/Items/LoggedInDeviceListItem';
import NormalButton from 'src/components/Buttons/NormalButton';
import React, { useCallback } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { CircularProgress } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { isKey } from 'src/utils/useFunctions';
import { setConfirmModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '8px',
    '& > label': {
      display: 'flex',
      color: theme.colors.black,
      fontSize: '14px',
      marginTop: '16px',
      marginBottom: '7px',
      fontWeight: 'bold',
      width: '100%',
    },
    '& > span': {
      display: 'flex',
      color: theme.colors.black,
      fontSize: '14px',
      width: '100%',
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '0 5px 0px 5px',
    height: '52px',
    whiteSpace: 'nowrap',
    '& > button': {
      '& > svg': {
        width: '16px',
        height: '16px',
      },
    },
    '& > span': {
      fontSize: '18px',
      fontWeight: '600',
    },
    '& > em': {
      display: 'inline-flex',
      height: 'fit-content',
      fontStyle: 'unset',
      fontSize: '14px',
      padding: '3px 9px',
      borderRadius: '6px',
      backgroundColor: theme.colors.primaryBlue[500],
      color: theme.colors.white,
      marginLeft: 'auto',
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    '& > span': {
      fontSize: '14px',
      fontWeight: '600',
    },
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    flex: '1 1 100%',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500]
    }
  },
  smallButton: {
    height: '32px',
    minWidth: '0px',
    padding: '5px 12px',
    marginLeft: 'auto',
  },
}));

const LoggedInDevices: React.FunctionComponent = () => {

  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const limit = 16;

  const [state, setState] = useStates({
    isLoading: true,
    isLoaded: false,
    page: 1,
    tokens: [],
    total: 0,
    isEndOfList: false,
    currentToken: null,
  });

  const securityService = useAppSelector((state: any) => state.services).securityService;

  const listTokens = useCallback(() => {
    const settings = {
      page: state.page,
      limit: limit,
    };
    securityService && securityService.listTokens(settings).then((result: any) => {
      if(result) {
        if(result.data) {
          const response = result.data[config.JSONLD_DATA];
          const count = result.data[config.JSONLD_COUNT];
          if(count < limit) {
            setState("isEndOfList", true);
            const newData = [...state.tokens, ...response];
            setState("tokens", newData);
          } else {
            const newData = [...state.tokens, ...response];
            setState("tokens", newData);
            setState("page", state.page + 1);
            setState("total", count);
          }
          setState("isLoaded", true);
          setState("isLoading", false);
        } else {
          createNotification(t("logged_in_devices_not_loaded"), "error");
        }
      } else {
        createNotification(t("logged_in_devices_not_loaded"), "error");
      }
    }).catch((e: any) => {
      createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("logged_in_devices_not_loaded"), "error");
    });
  }, [securityService, setState, state.page, state.tokens, t]);

  const handleClickDelete = (tokenID: any) => {
      const onAccept = () => {
        securityService && securityService.deleteToken(tokenID).then((result: any) => {
          if(result) {
            if(result.status === 204) {
              createNotification(t("logged_in_devices_logouted"), "success");
              const newTokens = state.tokens.filter((item: any) => item.tokenID !== tokenID);
              setState("tokens", newTokens);
              setState("total", state.total - 1);
              setState("currentToken", null);
            } else {
              createNotification(t("logged_in_devices_not_logouted"), "error");
            }
          } else {
            createNotification(t("logged_in_devices_not_logouted"), "error");
          }
        }).catch((e: any) => {
          createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("logged_in_devices_not_logouted"), "error");
        });
      };
      const settings = {
        isOpen: true,
        title: t('logged_in_devices_logout'),
        content: t('logged_in_devices_logout_confirm'),
        onAccept: onAccept,
        onDecline: null,
      };
      dispatch(setConfirmModal(settings));
    };

  useEffect(() => {
    listTokens();
  }, [listTokens], []);

  const handleChangeToken = (token: number) => {
    setState("currentToken", token);
  };

  const handleGoBack = () => {
    setState("currentToken", null);
  };

  const getTokenData = (tokenID: number) => {
    const isTokenExist = state.tokens.filter((item: any) => item.tokenID === tokenID).length === 1;
    if(isTokenExist) {
      const tokenData = state.tokens.find((item: any) => item.tokenID === tokenID);
      return tokenData;
    } else {
      return {};
    }
  };

  const handleLoadMoreDevices = () => {
    setState("isLoading", true);
    listTokens();
  };

  return (
    <>
      <div className={classes.inputWrapper}>
        <label>{t('logged_in_devices')}</label>
        <div>
          {
            state.currentToken ? (
              <div className={classes.section}>
                {
                  Object.keys(getTokenData(state.currentToken)).length > 0 ? (
                    <>
                      <div className={classes.header}>
                        <IconButton onClick={handleGoBack}>
                          <SVG src="arrow-left"/>
                        </IconButton>
                        <span>
                          {getTokenData(state.currentToken).tokenMask}
                        </span>
                        {
                          !getTokenData(state.currentToken).isCurrentToken ? (
                            <NormalButton className={classes.smallButton} buttonType='clear' onClick={() => handleClickDelete(state.currentToken)}>
                              {t('logged_in_devices_logout')}
                            </NormalButton>
                          ) : (
                            <em>{t('logged_in_devices_current_device')}</em>
                          )
                        }
                      </div>
                      <div className={classes.list}>
                        <span>{t('logged_in_devices_list_of_tokens')}:</span>
                        {getTokenData(state.currentToken).tokenUses.map((item: any) => (
                          <LoggedInDeviceInfoItem tokenID={state.currentToken} appVersion={getTokenData(state.currentToken).appVersion} browser={item.browser} countryCode={item.countryCode} ipAddress={item.ipAddress} lastUseDateTime={item.lastUseDt} os={item.os} platformID={getTokenData(state.currentToken).platformID}/>
                        ))}
                      </div>
                    </>
                  ) : null
                }
              </div>
            ) : (
              <div className={classes.section}>
                {
                  state.tokens.map((item: any, key: any) => (
                    <LoggedInDeviceListItem key={`k_${key}`} tokenID={item.tokenID} isCurrentToken={item.isCurrentToken} appVersion={item.appVersion} countryCodes={item.countryCodes} generatedDateTime={item.generatedDt} ipAddress={item.ipAddress} lastBrowser={item.lastBrowser} lastOs={item.lastOs} lastUseDateTime={item.lastUseDt} platformID={item.platformID} tokenMask={item.tokenMask} tokenUses={item.tokenUses} handleChangeToken={handleChangeToken}/>
                  ))
                }
              </div>
            )
          }
        </div>
        {
          state.isLoading ? (
            <div className={classes.loading}>
              <CircularProgress className={classes.spinner}/>
            </div>
          ) : null
        }
        {
          (state.isLoaded && !state.currentToken && state.total > state.tokens.length) ? (
            <NormalButton onClick={handleLoadMoreDevices} disabled={state.isLoading}>
              {t('logged_in_devices_load_more')}
            </NormalButton>
          ) : null
        }
      </div>
    </>
  );
};

export default LoggedInDevices;