import NormalButton from '../../../../../../components/Buttons/NormalButton';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { setSSOGetModal } from 'src/store/actions/modals.actions';
import { useAppDispatch } from '../../../../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& > label': {
      display: 'flex',
      color: theme.colors.black,
      fontSize: '14px',
      marginTop: '16px',
      marginBottom: '7px',
      fontWeight: 'bold',
      width: '100%',
    },
    '& > span': {
      display: 'flex',
      color: theme.colors.black,
      fontSize: '14px',
      width: '100%',
    },
  },
  info: {
    fontSize: '12px',
    marginBottom: '8px',
  },
  buttons: {
    display: 'flex',
    gap: '8px',
  },
}));

const SSOGenerator: React.FunctionComponent = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const handleGenerateQRCode = () => {
    const settings = {
      isOpen: true,
      type: 'qrcode',
    };
    dispatch(setSSOGetModal(settings));
  };

  const handleGenerateLink = () => {
    const settings = {
      isOpen: true,
      type: 'link',
    };
    dispatch(setSSOGetModal(settings));
  };
  
  return (
    <>
      <div className={classes.inputWrapper}>
        <label>{t('sso_one_time_login')}</label>
        <p className={classes.info}>
          {t('sso_one_time_login_info')}
        </p>
        <div className={classes.buttons}>
          <NormalButton buttonType="primary" onClick={handleGenerateQRCode} tabIndex="14">{t('sso_generate_qr_code')}</NormalButton>
          <NormalButton buttonType="secondary" onClick={handleGenerateLink} tabIndex="15">{t('sso_generate_link')}</NormalButton>
        </div>
      </div>
    </>
  );
};

export default SSOGenerator;