import React from 'react';
import { createUseStyles } from 'react-jss';
import { isMobile } from 'react-device-detect';
import { Tooltip as MuiTooltip } from '@mui/material';

interface Props {
  maxWidth: any;
  multiline: any;
  align: any;
  fitContent: any;
};

const useStyles = createUseStyles(() => ({
  tooltipCustom: {
    maxWidth: (props: Props) => {
      if(typeof props.maxWidth === "number") return `${props.maxWidth}px`;
      else return props.maxWidth;
    },
    whiteSpace: (props: Props) => {
      if(props.multiline) return 'pre-line';
      else return '';
    },
    textAlign: (props: Props) => props.align,
    width: (props: Props) => {
      if(props.fitContent) return 'fit-content';
      else return '';
    },
  },
}));

type TooltipType = {
  title?: any;
  position?: 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top';
  align?: 'left' | 'center' | 'right',
  arrow?: boolean;
  maxWidth?: any;
  fitContent?: boolean;
  multiline?: boolean;
  children?: any;
  className?: any;
  disableTouchListener?: boolean;
  disableHoverListener?: boolean;
  disableInteractive?: boolean;
  disableOnMobile?: boolean;
};

const Tooltip: React.FunctionComponent<TooltipType> = ({ title, position = "right", align = "left", arrow = true, maxWidth = 100, fitContent = false, multiline = false, children, className, disableTouchListener, disableHoverListener, disableInteractive, disableOnMobile}) => {

  const classes = useStyles({
    maxWidth: maxWidth,
    multiline: multiline,
    align: align,
    fitContent: fitContent,
  });

  return (
    <MuiTooltip title={title} classes={{ tooltip: `${classes.tooltipCustom} ${className ? className : null}` }} placement={position} arrow={arrow} open={(disableOnMobile && isMobile) ? false : undefined} disableTouchListener={disableTouchListener} disableHoverListener={disableHoverListener} disableInteractive={disableInteractive}>
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;