import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import theme from 'src/ui/theme';
import { createUseStyles } from 'react-jss';
import { Tooltip } from '@mui/material';
import { isCypress } from 'src/utils/useCypress';

interface Props {
  maxWidth: any;
  iconColor: any;
};

const useStyles = createUseStyles((theme: any) => ({
  tooltip: {
    display: 'inline-flex',
    color: (props: Props) => props.iconColor,
    cursor: 'help',
    '& > svg': {
      width: '100%',
      height: '100%',
    }
  },
  tooltipWidth: {
    maxWidth: (props: Props) => {
      if(typeof props.maxWidth === "number") return `${props.maxWidth}px`;
      else return props.maxWidth;
    },
  },
}));

type TooltipIconType = {
  icon?: string;
  className?: any;
  title: any;
  iconColor?: any;
  position?: 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top';
  arrow?: boolean;
  maxWidth?: any;
  onClick?: any;
  dataCy?: any;
};

const TooltipIcon: React.FunctionComponent<TooltipIconType> = ({ icon = "info-circle-outlined", className, title, iconColor = theme.colors.primaryBlue[500], position = "right", arrow = true, maxWidth = 100, dataCy, onClick }) => {

  const classes = useStyles({
    maxWidth: maxWidth,
    iconColor: iconColor,
  });

  return (
    <Tooltip title={title} classes={{ tooltip: classes.tooltipWidth }} placement={position} arrow={arrow}>
      <span className={`${classes.tooltip} ${className}`} data-cy={isCypress() ? dataCy : null} onClick={onClick}>
        <SVG src={icon}/>
      </span>
    </Tooltip>
  );
};

export default TooltipIcon;