import CalendarEventItem from 'src/components/Items/CalendarEventItem';
import CalendarEventsItem from 'src/components/Items/CalendarEventsItem';
import Clamp from 'react-multiline-clamp';
import moment from '../../../../../utils/moment';
import React, { useCallback, useMemo, useRef } from 'react';
import theme from '../../../../../ui/theme';
import { calendarViewModes } from 'src/utils/useCalendar';
import { compareDates } from '../../../../../utils/date';
import { createUseStyles } from 'react-jss';
import { getCalendarBackgroundColor, getCalendarDateColor } from 'src/utils/useFunctions';
import { getUserRole } from 'src/utils/useUser';
import { isCypress } from '../../../../../utils/useCypress';
import { setCalendarDate, setCalendarViewMode } from 'src/store/actions/calendar.actions';
import { setCalendarEventHandleModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  getCalendarBackgroundColor: any;
  getCalendarDateColor: any;
};

const useStyles = createUseStyles((theme: any) => ({
  eventsWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    //minWidth: '240px',
    height: '100%',
    minHeight: '100px',
    gap: '10px',
    backgroundColor: (props: Props) => props.getCalendarBackgroundColor,
    '& .fontGray': {
      color: '#bcbcbc',
    },
    '& .fontDarkGray': {
      color: theme.colors.grey[550],
      fontWeight: 'normal',
    },
    [theme.breakpoints.down('sm')]: {
    },
  },
  date: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    fontSize: '14px',
    marginTop: '5px',
    fontWeight: '800',
    position: 'relative',
    '& > span': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '28px',
      height: '28px',
      borderRadius: '100%',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: (props: Props) => props.getCalendarDateColor,
      },
    },
  },
  today: {
    '& > span': {
      color: theme.colors.primaryBlue[500],
      backgroundColor: theme.colors.grey[250],
      '&:hover': {
        backgroundColor: `${theme.colors.grey[250]} !important`,
      },
    },
  },
  events: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    gap: '4px',
    padding: '8px 0',
    height: 'calc(100% - 16px)',
    maxHeight: 'calc(100% - 16px)',
  },
  moreEvents: {
    backgroundColor: theme.colors.white,
    borderRadius: '12px',
    width: 'calc(100% - 24px)',
    padding: '0 8px',
    height: '30px',
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    position: 'relative',
    color: theme.colors.black,
    transition: 'background-color 0.25s',
    cursor: 'pointer',
    '& > svg': {
      display: 'block',
      width: '16px',
      height: '16px',
    },
    '&:hover': {
      backgroundColor: theme.colors.primaryBlue[50],
    },
  },
  name: {
    maxWidth: '100%',
    fontSize: '11px',
    lineHeight: '13px',
    textAlign: 'center',
    wordBreak: 'break-all',
    margin: 'auto 0',
    fontWeight: '600',
    [theme.breakpoints.down('sm')]: {
      fontSize: '9px',
    },
  },
}));

type DateItemType = {
  index?: any;
  curDate?: any;
  date?: any;
};

const DateItem: React.FunctionComponent<DateItemType> = ({index, curDate, date}) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const browserData = useAppSelector((state: any) => state.browser);
  const calendarData = useAppSelector((state: any) => state.calendar);
  const userData = useAppSelector((state: any) => state.user);    
  const allEvents = calendarData.events;
  const isLoading = calendarData.isLoading;

  const getEvents = (allEvents: any, date: any) => {
    return allEvents.filter((item: any) => moment(date).isSameOrAfter(item.dateTimeFrom, 'day') && moment(date).isSameOrBefore(item.dateTimeTo, 'day'));
  };

  const events = useMemo(() => getEvents(allEvents, date), [allEvents, date]); 

  const eventWrapperRefs: any = useRef([]);
  const eventsRef: any = useRef(null);

  const [state, setState] = useStates({
    anchorEl: null,
    eventsCount: 1,
  });

  const classes = useStyles({
    getCalendarBackgroundColor: getCalendarBackgroundColor(date, index, theme),
    getCalendarDateColor: getCalendarDateColor(date, index, theme),
  });

  const getCount = useCallback(() => {
    if(eventsRef.current) {
      const height = eventsRef.current.clientHeight - 16;
      const count = Math.floor(height / 34);
      setState("eventsCount", count < 1 ? 1 : count);
   } else {
    setState("eventsCount", 1);
   }
  }, [setState]);

  useEffect(() => {
    setTimeout(getCount, 100);
  }, [getCount], [browserData.height, isLoading]);

  useEffect(() => {
    setTimeout(getCount, 1000);
  }, [getCount], []);

  const handleClickMoreItems = (e: any) => {
    setState("anchorEl", eventsRef.current);
  };

  const handleCloseMoreItems = () => {
    setState("anchorEl", null);
  };
  
  const handleAddEvent = (e: any) => {
    if(getUserRole(userData.userObject.roleType) === "director" || getUserRole(userData.userObject.roleType) === "teacher") {
      if(eventWrapperRefs.current["wrapper"] === e.target || eventWrapperRefs.current["date"] === e.target || eventsRef.current === e.target) {
        e.preventDefault();
        e.stopPropagation();
        const settings = {
          isOpen: true,
          date: date,
          dateTime: null,
          eventID: null,
        };
        dispatch(setCalendarEventHandleModal(settings));
      }
    }
  };

  const handleSetDay = () => {
    dispatch(setCalendarDate(moment(date)));
    dispatch(setCalendarViewMode(calendarViewModes[0]));
  };

  const moreEvents = state.eventsCount === events.length ? false : events.length > state.eventsCount;
  const moreEventsCount = events.length - state.eventsCount + 1;

  return (
    <div className={classes.eventsWrapper} onDoubleClick={handleAddEvent} data-cy={isCypress() ? "calendarDayItem-" + moment(date).format("YYYY-MM-DD") : null} ref={(el: any) => (eventWrapperRefs.current["wrapper"] = el)}>
      <p className={`${classes.date} ${compareDates(date, moment()) ? classes.today : ''} ${moment(date).month() !== moment(curDate).month() ? 'fontGray' : null} ${(moment(date).day() === 6 || moment(date).day() === 0) ? 'fontDarkGray' : null}`} ref={(el: any) => (eventWrapperRefs.current["date"] = el)}>
        <span onClick={handleSetDay} data-clarity-unmask="true">
          {date.date()}
        </span>
      </p>
      {
        events.length !== 0 ? (
          <div className={classes.events} ref={eventsRef}>
            {
              events.slice(0, state.eventsCount === events.length ? events.length : (state.eventsCount - 1)).map((item: any, key: any) => (
                <CalendarEventItem data={item} date={date} overlaping={true} key={`k_${key}`}/>
              ))
            }
            {
              moreEvents ? (
                <>
                  <div className={classes.moreEvents} onClick={handleClickMoreItems}>
                    <Clamp withTooltip lines={1}>
                      <span className={classes.name} data-clarity-unmask="true">{ moreEventsCount < 5 ? t('calendar_more', {count: moreEventsCount}).toLowerCase() : t('calendar_more_more', {count: moreEventsCount}).toLowerCase()}</span>
                    </Clamp> 
                  </div> 
                  <CalendarEventsItem anchorEl={state.anchorEl} onClose={handleCloseMoreItems} date={date} events={events}/>
                </>
              ) : null
            }
          </div>
        ) : null}
    </div>
  );
};

export default DateItem;