import * as AuthService from '../../../services/auth.service';
import AuthenticatedImage from '../../Items/AuthenticatedImage';
import Button from '@mui/material/Button';
import Clamp from 'react-multiline-clamp';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import config from 'src/constants/config';
import IconButton from 'src/components/Buttons/IconButton';
import React from 'react';
import SVG from '../../../components/Images/SvgRenderer';
import SwipeableViews from 'react-swipeable-views';
import { createCypressDataText, isCypress } from '../../../utils/useCypress';
import { createUseStyles } from 'react-jss';
import { getUserRole, getUserSetting } from 'src/utils/useUser';
import { hasUpperCase, validateItemRequirements } from 'src/utils/useFunctions';
import { languages } from 'src/constants/languages';
import { setChangelogModal, setCookiesModal, setLanguageModal } from '../../../store/actions/modals.actions';
import { setDeinitializeUserController } from 'src/store/actions/controllers.actions';
import { setIsOpenWelcomeScreen } from 'src/store/actions/layout.actions';
import { setUserStatus } from '../../../store/actions/user.actions';
import { Tooltip } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useNavigate } from 'react-router-dom';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen: any;
};

const useStyles = createUseStyles((theme: any) => ({
  profileMenuWrapper: {
    position: "relative",
  },
  profileMenu: {
    position: "absolute",
    top: '-5px',
    right: '-7px',
    backgroundColor: theme.colors.white,
    width: '300px',
    borderRadius: "20px",
    boxShadow: theme.shadows[2],
    zIndex: theme.zIndex.profileMenu,
    opacity: (props: Props) => {
      if(props.isOpen) return '1';
      else return '0';
    },
    transition: 'opacity 0.25s',
    pointerEvents: (props: Props) => {
      if(!props.isOpen) return 'none';
      else return '';
    },
  },
  profileButton: {
    padding: '0px',
    minWidth: 'initial',
    borderRadius: '50%',
    position: 'relative',
    zIndex: theme.zIndex.profileMenuItem, 
  },
  profileImageWrapper: {
    width: 'auto',
    height: 'auto',
    position: 'relative',
    zIndex: theme.zIndex.profileMenuItem, 
    '& > svg': {
      width: '16px',
      height: '16px',
      position: 'absolute',
      bottom: '-6px',
      right: '-6px',
      color: theme.colors.yellow[500],
      zIndex: theme.zIndex.profileMenuItem,  
      backgroundColor: theme.colors.white,
      borderRadius: '100%',
      padding: '4px',
      boxShadow: theme.shadows[2],
    },
  },
  profileImage: {
    width: "38px",
    height: "38px",
    borderRadius: "12px",
    margin: "0",
    position: 'relative',
    backgroundColor: theme.colors.white,
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 5px 5px 15px',
    height: '48px',
  },
  userName: {
    marginBottom: '0px',
    fontSize: '14px',
    fontWeight: 'bold',
    maxWidth: 'calc(100% - 48px)',
    color: theme.colors.black,
    lineHeight: '1',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '0 5px 0px 5px',
    height: '52px',
    whiteSpace: 'nowrap',
    '& > button': {
      '& > svg': {
        width: '16px',
        height: '16px',
      },
    },
    '& > span': {
      fontSize: '18px',
      fontWeight: '600',
    },
  },
  menuItem: {
    position: 'relative',
    padding: "10px 15px",
    display: "flex",
    justifyContent: 'flex-start',
    alignItems: "center",
    width: '100%',
    minWidth: 'unset',
    borderRadius: '0',
    gap: '12px',
    '&:hover': {
      backgroundColor: theme.colors.hoverBlack[4],
      cursor: "pointer",
    },
    '&.disabled': {
      color: '#5A5C7B',
      backgroundColor: theme.colors.grey[125],
      cursor: 'default',  
    },
  },
  icon: {
    width: '32px',
    height: '32px',
    backgroundColor: theme.colors.grey[200],
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.colors.black,
    '& > svg': {
      width: '20px',
      height: '20px',
      color: theme.colors.black,
    },
  },
  title: {
    fontSize: '13px',
    textTransform: 'none',
    fontWeight: '400',
    color: theme.colors.black,
    lineHeight: '20px',
    whiteSpace: 'nowrap',
    '&.bold': {
      color: theme.colors.primaryBlue[500],
      fontWeight: '700',
      textTransform: 'uppercase',
      fontSize: '12px',
    },
  },
  chevron: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
    '& > svg': {
      width: '20px',
      height: '20px',
      color: theme.colors.black,
    },
  },
  footerRow: {
    display: 'flex',
    justifyContent: 'center',
    gap: '0.5rem',
    width: '100%',
    maxWidth: '100%',
    padding: '8px 0',
    '& > span': {
      color: theme.colors.primaryBlue[500],
      fontSize: '10px',  
      whiteSpace: 'nowrap',
      '& > span': {
        fontWeight: 'bold',
      },
      '&:last-of-type': {
        cursor: 'pointer',
        transition: 'all 0.25s',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
}));

type ItemsType = {
  items: any;
  dataCy?: any;
};

const ProfileMenu: React.FunctionComponent<ItemsType> = ({ items, dataCy }) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dataData = useAppSelector((state: any) => state.data);
  const languageData = useAppSelector((state: any) => state.language);
  const layoutData = useAppSelector((state: any) => state.layout);
  const userData = useAppSelector((state: any) => state.user);

  const userObject = userData.userObject;
  const userSettings = userData.userSettings;
  const userAccess = userData.userAccess;
  const userAccessSchools = userData.userAccessSchools;
  const userMembership = userData.membership;

  const [state, setState] = useStates({
    isOpen: false,
    index: 0,
    currentNode: null,
  });

  const classes = useStyles({
    isOpen: state.isOpen,
  });

  const handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setState("index", 0);
    setState("isOpen", !state.isOpen);
  };
  
  const handleClickLanguage = () => {
    const settings = {
      isOpen: true,
    };
    dispatch(setLanguageModal(settings));
  };

  const handleClickSwitchUser = () => {
    navigate('/auth/switch');
  };

  const handleClickContactSupport = () => {
    window.location.href = 'mailto:' + config.SUPPORT_EMAIL;
  };

  const handleClickKnowledgeBase = () => {
    const langs: any = languages;
    const url: any = langs.find((lang: any) => lang.acronym === languageData.language).knowledgeBaseLink;
    window.open(url);
  }; 

  const handleClickCookies = () => {
    const settings = {
      isOpen: true,
    };
    dispatch(setCookiesModal(settings));
  }; 

  const handleClickPersonalDataProtection = () => {
    const langs: any = languages;
    const url: any = langs.find((lang: any) => lang.acronym === languageData.language).personalDataProtectionLink;
    window.open(url);
  }; 

  const handleClickWelcomeScreen = () => {
    dispatch(setIsOpenWelcomeScreen(true));
  };
  
  const handleClickAway = () => {
    setState("isOpen", false);
    setState("index", 0);
  };
  
  const handleClickItem = (node: any) => {
    setState("isOpen", false);
    setState("index", 0);
    navigate(node.to);  
  };

  const handleClickAction = (node: any) => {
    const action = node.to.replace("action/", "");
    if(action === "language") {
      handleClickLanguage();
    } else if(action === "knowledgeBase") {
      handleClickKnowledgeBase();
    } else if(action === "cookies") {
      handleClickCookies();
    } else if(action === "personalDataProtection") {
      handleClickPersonalDataProtection();
    } else if(action === "contactSupport") {
      handleClickContactSupport();
    } else if(action === "welcomeScreen") {
      handleClickWelcomeScreen();
    }
  };

  const handleLogout = () => {
    dispatch(setDeinitializeUserController(true));
  };

  const handleClickLogout = () => {
    dispatch(setUserStatus("loggingOff"));
    AuthService && AuthService.logout().then(() => {
      handleLogout();
    }).catch(() => {
      handleLogout();
    });
  };

  const handleOnClickVersion = () => {
    //dispatch(setIsOpenWelcomeScreen(true));
    const settings = {
      isOpen: true,
      isAutoOpen: false,
    };
    dispatch(setChangelogModal(settings));
  };

  const handleChangeIndex = (index: number) => {
    setState("index", index);
  };

  const handleChangeSection = (node: any) => {
    setState("currentNode", node.to);
    setState("index", 1);
  };

  const handleGoBack = () => {
    setState("index", 0);
    setTimeout(() => {
      setState("currentNode", null);
    }, 300);
  };

  const getNodeData = (node: any) => {
    return items.filter((item: any) => item.to === node).length === 1 ? items.find((item: any) => item.to === node) : [];
  };

  const revalidateMenuItems = (items: any) => {
    return items.map((item: any) => {
      return validateItemRequirements(item, { dataData: dataData, isCypress: isCypress, layoutData: layoutData, userAccess: userAccess, userAccessSchools: userAccessSchools, userMembership: userMembership, userObject: userObject, userSettings: userSettings, getUserRole: getUserRole, getUserSetting: getUserSetting});
    }).filter((item: any) => item !== null && item.isVisible);
  };
  
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.profileMenuWrapper} data-cy={isCypress() ? dataCy : null}>
        <Button className={classes.profileButton} onClick={handleClick} data-cy={isCypress() ? "profileMenuMainButton" : null}>
          <div className={classes.profileImageWrapper}>
            <AuthenticatedImage className={classes.profileImage} thumbLink={typeof userData.userObject.photo === "string" ? userData.userObject.photo : userData.userObject.photo.thumbLink}/>
            {
              userData && userData.userObject.membership && userData.membership.active ? (
                <SVG src="premium"/>
              ) : null
            }
          </div>
        </Button>
        <div className={classes.profileMenu} data-cy={isCypress() ? "profileMenu" : null}>
          <div className={classes.userInfo}>
            <Clamp withTooltip lines={2}><p className={classes.userName}>{userData.userObject.firstname + ' ' + userData.userObject.surname}</p></Clamp>
          </div>
          <SwipeableViews axis='x' index={state.index} onChangeIndex={handleChangeIndex} animateHeight={true}>
            <div className={classes.section}>
              {
                revalidateMenuItems(items).filter((item: any) => item.isEnabled && item.isVisible).map((node: any, key: any) => {
                  return node.items ? (
                    <Button key={`k_${key}`} className={classes.menuItem} onClick={() => handleChangeSection(node)} data-cy={isCypress() ?"profileMenu" + createCypressDataText(node.title) : null}>
                      <span className={classes.icon}>
                        <SVG src={node.icon}/>
                      </span>
                      <span className={`${classes.title} ${node.isBold ? 'bold' : ''}`}>
                        {t(node.title)}
                      </span>
                      <span className={classes.chevron}>
                        <SVG src="chevron-right"/>
                      </span>
                    </Button>
                  ) : (
                    <Button key={`k_${key}`} className={classes.menuItem} onClick={() => handleClickItem(node)} data-cy={isCypress() ?"profileMenu" + createCypressDataText(node.title) : null}>
                      <span className={classes.icon}>
                        <SVG src={node.icon}/>
                      </span>
                      <span className={`${classes.title} ${node.isBold ? 'bold' : ''}`}>
                        {t(node.title)}
                      </span>
                    </Button>
                  )
                })
              }
              {
                getUserSetting(userData.userSettings, "addons", ["app", "app_user_switching"]) ? (
                  <Button className={classes.menuItem} onClick={handleClickSwitchUser} data-cy={isCypress() ? "profileMenuUserSwitchButton" : null}>
                    <span className={classes.icon}>
                      <SVG src="user-switch"/>
                    </span>
                    <span className={classes.title}>
                      {t('switch_user')}
                    </span>
                  </Button>
                ) : null
              }
              <Button className={classes.menuItem} onClick={handleClickLogout} data-cy={isCypress() ? "profileMenuLogoutButton" : null}>
                <span className={classes.icon}>
                  <SVG src="logout"/>
                </span>
                <span className={classes.title}>
                  {t('logout')}
                </span>
              </Button>
            </div>
            <div className={classes.section}>
              {
                state.currentNode ? (
                  <>
                    <div className={classes.header}>
                      <IconButton onClick={handleGoBack}>
                        <SVG src="arrow-left"/>
                      </IconButton>
                      <span>
                        {t(getNodeData(state.currentNode).title)}
                      </span>
                    </div>
                    {
                      revalidateMenuItems(getNodeData(state.currentNode).items).filter((item: any) => item.isEnabled && item.isVisible).map((subnode: any, nkey: any) => {
                        return subnode.to.startsWith("/") ? (
                          <Button key={`k_${nkey}`} className={classes.menuItem} onClick={() => handleClickItem(subnode)} data-cy={isCypress() ?"profileMenu" + createCypressDataText(subnode.title) : null}>
                            <span className={classes.icon}>
                              <SVG src={subnode.icon}/>
                            </span>
                            <span className={`${classes.title} ${subnode.isBold ? 'bold' : ''}`}>
                              {t(subnode.title)}
                            </span>
                          </Button>
                        ) : (
                          <Button key={`k_${nkey}`} className={classes.menuItem} onClick={() => handleClickAction(subnode)} data-cy={isCypress() ? "profileMenuLanguageButton" : null}>
                            <span className={classes.icon}>
                              {
                                hasUpperCase(subnode.icon) ? (
                                  <>
                                    {languageData.language || 'EN'}
                                  </>
                                ) : (
                                  <SVG src={subnode.icon}/>
                                )
                              }
                            </span>
                            <span className={`${classes.title} ${subnode.isBold ? 'bold' : ''}`}>
                              {t(subnode.title)}
                            </span>
                          </Button>
                        );
                      })
                    }
                  </>
                ) : null
              }
            </div>
          </SwipeableViews>
          <div className={classes.footerRow}>
            <Tooltip title={t('twigsee_since')} arrow>
              <span data-clarity-unmask="true" data-cy={isCypress() ? `mainLayoutFooterCopyright` : null}>
                &copy; {t('twigsee')} {new Date().getFullYear()}
              </span>
            </Tooltip>
            <span data-cy={isCypress() ? `mainLayoutFooterDivider` : null}>|</span>
            <span data-clarity-unmask="true" data-cy={isCypress() ? `mainLayoutFooterVersion` : null} onClick={handleOnClickVersion}>
              {t('version')} {config.APP_VERSION}
            </span>
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
}

export default ProfileMenu;