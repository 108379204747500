import CloseButton from 'src/components/Buttons/CloseButton';
import Currency from 'react-currency-formatter';
import DateFormat from 'src/utils/dateFormat';
import Modal from '../../../utils/modal';
import moment from 'src/utils/moment';
import NormalButton from 'src/components/Buttons/NormalButton';
import React, { useCallback } from 'react';
import TabsMenu from 'src/components/Menus/TabsMenu';
import theme from 'src/ui/theme';
import TooltipIcon from 'src/components/Icons/TooltipIcon';
import { a2ab, createFileName, getSchoolSettings, handleSum, isKey } from 'src/utils/useFunctions';
import { CircularProgress } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getUserRole } from 'src/utils/useUser';
import { isCypress } from '../../../utils/useCypress';
import { saveAs } from 'file-saver';
import { setAttachmentModal, setShopItemDetailModal, setShopOrderDetailModal, setShopOrderStatusModal } from '../../../store/actions/modals.actions';
import { setStockOrders } from 'src/store/actions/stock.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    padding: "20px",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    marginTop: "20px",
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
    '& table': {
      width: '100%',
      '& > thead': {
        '& > tr': {
          '& > th': {
            textAlign: 'left',
          },
        },
      },
    },
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: '16px',
    gap: '10px',
  },
  tabsWrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
    justifyContent: 'center',
    '& > div': {
      width: '100%',
    },
    paddingBottom: '16px',
  },
  bottom: {
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  itemName: {
    fontWeight: 'bold',
    color: theme.colors.primaryBlue[500],
    cursor: 'pointer',
  },
  limitInput: {
    width: '100px',
  },
  remove: {
    cursor: 'pointer',
    color: theme.colors.primaryBlue[500],
    transition: 'color 0.25s',
    '&:hover': {
      color: theme.colors.primaryBlue[600],
    },
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(238,238,238,0.5)',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500]
    }
  },
  tableCellWrapper: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  table: {
    width: '100%',
    maxWidth: '100%',
    '& > tbody': {
      '& > tr': {
        '& > td': {
          fontSize: '14px',
          '&:first-of-type': {
            fontWeight: '500',
            whiteSpace: 'nowrap',
          },
          '&:last-of-type': {
            paddingLeft: '8px',
            maxWidth: 'calc(100% - 8px)',
            textAlign: 'right',
          },
          '& a': {
            maxWidth: '100%',
            color: theme.colors.primaryBlue[500],
            cursor: 'pointer',
            transition: 'color 0.25s',
            fontWeight: 'bold',
            textDecoration: 'none',
            wordBreak: 'break-all',
            whiteSpace: 'pre-wrap',
            '&:hover': {
              color: theme.colors.primaryBlue[600],
            },
          },
          '& > small': {
            fontSize: '12px',
          },
        },
      },
    },
  },
}));

const ShopOrderDetailModal: React.FunctionComponent = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const configurationData = useAppSelector((state: any) => state.configuration).configuration;
  const dataData = useAppSelector((state: any) => state.data);
  const languageData = useAppSelector((state: any) => state.language);
  const modalsData = useAppSelector((state: any) => state.modals);
  const userData = useAppSelector((state: any) => state.user);
  const stockData = useAppSelector((state: any) => state.stock);
  const exportService = useAppSelector((state: any) => state.services).exportService;
  const stockService = useAppSelector((state: any) => state.services).stockService;

  const schoolSettings = userData.schoolSettings;

  const schoolID = modalsData.shopOrderDetailModal.schoolID;
  const orderID = modalsData.shopOrderDetailModal.orderID;
  const isNewCreated = modalsData.shopOrderDetailModal.isNewCreated;

  const showWarning: any = false;

  const [state, setState] = useStates({
    isLoading: true,
    isEdit: false,
    items: [],
    orderData: {},
    viewMode: 'items',
  });

  const tabsItems: any = [
    {
      name: 'stock_shop_order_basic',
      value: 'basic',
      isEnabled: true,
    },
    (Object.keys(state.orderData).length > 0 && state.orderData.items.length > 0) ? {
      name: 'stock_shop_order_items',
      value: 'items',
      badge: Object.keys(state.orderData).length > 0 ? state.orderData.items.length : 0,
      badgeType: 'primary',
      isEnabled: true,
    } : null,
    (Object.keys(state.orderData).length > 0 && state.orderData.orderHistory.length > 0) ? {
      name: 'stock_shop_order_history',
      value: 'history',
      badge: Object.keys(state.orderData).length > 0 ? state.orderData.orderHistory.length : 0,
      badgeType: 'primary',
      isEnabled: true,
    } : null,
  ];

  const classes = useStyles();

  const currencyID = getSchoolSettings(schoolID, 'currencyID', schoolSettings) === null ? 1 : getSchoolSettings(schoolID, 'currencyID', schoolSettings);
  const getCurrency = currencyID ? (configurationData.currencies.filter((currency: any) => currency.currencyID === currencyID).length === 0 ? [] : configurationData.currencies.find((currency: any) => currency.currencyID === currencyID)) : [];

  const setViewMode = useCallback((value: any) => {
    setState("viewMode", value);
  }, [setState]);

  const getChildData = useCallback((childID: any) => {
    return dataData.children.filter((item: any) => item.childID === childID).length === 0 ? [] : dataData.children.find((item: any) => item.childID === childID);
  }, [dataData.children]);

  const getItemData = useCallback((itemID: any) => {
    return state.items.filter((item: any) => item.itemID === itemID).length === 0 ? [] : state.items.find((item: any) => item.itemID === itemID);
  }, [state.items]);

  const getOrderStatusName = useCallback((orderStatusID: any) => {
    return configurationData.stockOrderStatuses.filter((item: any) => item.orderStatusID === orderStatusID).length === 0 ? "" : configurationData.stockOrderStatuses.find((item: any) => item.orderStatusID === orderStatusID).name;
  }, [configurationData.stockOrderStatuses]);

  const getSchoolData = useCallback((schoolID: any) => {
    return dataData.schools.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : dataData.schools.find((item: any) => item.schoolID === schoolID);
  }, [dataData.schools]);

  const getQuantity = useCallback((itemID: any, variantID?: any) => {
    const quantity = variantID ? (getItemData(itemID).variant ? (getItemData(itemID).variant.filter((variant: any) => variant.variantID === variantID).length === 0 ?  0 : getItemData(itemID).variant.find((variant: any) => variant.variantID === variantID).quantity) : 0) : (getItemData(itemID).quantity);
    return quantity;
  }, [getItemData]);

  const getTotalPrice = useCallback(() => {
    let price = 0;
    if(state.orderData.items.length !== 0) {
      state.orderData.items.forEach((subItem: any) => {
        price = price + ((subItem.orderPrice * (1 + (subItem.orderTax / 100))) * subItem.orderQuantity);
      });
    }
    return price;
  }, [state.orderData.items]);
  
  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      isNewCreated: false,
      orderID: false,
      schoolID: false,
    };
    dispatch(setShopOrderDetailModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const getItems = useCallback((allItems: any) => {
    const settings = {
      itemID: allItems.join(","),
      limit: allItems.length,
      includeDeleted: true,
    };
    stockService && stockService.listItems(settings).then((result: any) => {
      if(result) {
        if(result.data) {
          setState("items", result.data.items);
          setState("isLoading", false);
        } else {
          createNotification(t("stock_items_not_loaded"), "error");
          setState("isLoading", false);
        }
      } else {
        createNotification(t("stock_items_not_loaded"), "error");
        setState("isLoading", false);
      }
    }).catch(() => {
      createNotification(t("stock_items_not_loaded"), "error");
      setState("isLoading", false);
    });
  }, [setState, stockService, t]);

  const getOrder = useCallback(() => {
    setState("isLoading", true);
    const settings = {
      schoolID: schoolID,
      orderID: orderID, 
    };
    stockService && stockService.listOrders(settings).then((result: any) => {
      if(result) {
        if(result.data) {
          setState("orderData", result.data.orders[0]);
          const items = result.data.orders[0].items.map((item: any) => { return item.itemID});
          if(items.length > 0) {
            getItems(items);
          } else {
            setState("isLoading", false);
          }
        } else {
          createNotification(t("stock_orders_not_loaded"), "error");
          setState("isLoading", false);
        }
      } else {
        createNotification(t("stock_orders_not_loaded"), "error");
        setState("isLoading", false);
      }
    }).catch(() => {
      createNotification(t("stock_orders_not_loaded"), "error");
      setState("isLoading", false);
    });
  }, [t, stockService, setState, orderID, schoolID, getItems]);

  useEffect(() => {
    getOrder();
  }, [getOrder], []);

  const handleOpenItem = (itemID: any, variantID?: any) => {
    let settings;
    if(variantID) {
      settings = {
        isOpen: true,
        itemID: itemID,
        variantID: variantID,
        showCart: false,
        schoolID: parseInt(state.viewMode),
      };
    } else {
      settings = {
        isOpen: true,
        itemID: itemID,
        showCart: false,
        schoolID: parseInt(state.viewMode),
      };
    }
    dispatch(setShopItemDetailModal(settings)); 
  };

  const handleClickChangeStatus = () => {
    const settings = {
      isOpen: true,
      orderID: orderID,
      onClose: () => getOrder(),
    };
    dispatch(setShopOrderStatusModal(settings)); 
  };

  const handleClickDelete = () => {
    stockService && stockService.deleteOrder(orderID).then((result: any) => {
      if(result) {
        if(result.status === 204) {
          createNotification(t("stock_order_canceled"), "success");
          const newOrders = stockData.orders.map((item: any) => {
            if(item.orderID === orderID) {
              return {...item, orderStatusID: 7, isEditable: false};
            } else {
              return item;
            }
          }).filter((item: any) => item !== null);
          dispatch(setStockOrders(newOrders));
          getOrder();
        } else {
          createNotification(t("stock_order_not_canceled"), "error");
        }
      } else {
        createNotification(t("stock_order_not_canceled"), "error");
      }
    }).catch((e: any) => {
      createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("stock_order_not_canceled"), "error");
    });
  };

  const getTotalQuantity = () => {
    const totalQuantity = state.orderData.items.map((item: any) => {
      return item.orderQuantity;
    }).reduce(handleSum, 0);
    return totalQuantity;
  };

  const handleExportDeliveryList = useCallback((title: any, name: any, format: any) => {
    const fileName = createFileName(name);
    const handleExportPDF = () => {
      exportService && exportService.exportStockDeliveryList(orderID).then((result: any) => {
        const buf = a2ab(result.data);
        const buftype = 'application/pdf';
        const blob = new Blob([buf], {
          type: buftype,
        });
        saveAs(blob, `${fileName}.${format}`);
      }).catch(() => {
        createNotification(t('something_went_wrong'), 'error');
      });
    };
    const settings = {
      isOpen: true,
      title: `${title} <span>${name}</span>`,
      content: null,
      download: null,
    };
    dispatch(setAttachmentModal(settings));
    exportService && exportService.exportStockDeliveryList(orderID).then((result: any) => {
      const buf = a2ab(result.data);
      const buftype = 'application/pdf';
      const blob = new Blob([buf], {
        type: buftype,
      });
      const content = URL.createObjectURL(blob);
      const settings = {
        content: content,
        download: handleExportPDF,
        type: format,
      };
      dispatch(setAttachmentModal(settings));
    }).catch(() => {
      createNotification(t('something_went_wrong'), 'error');
      const settings = {
        isOpen: false,
        title: null,
        content: null,
        download: null,
      };
      dispatch(setAttachmentModal(settings));
    });
  }, [dispatch, exportService, orderID, t]);
   
  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "shopOrderDetailModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p>{isNewCreated ? t('stock_order_added') : t('stock_order_detail')}</p>
          </div>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div className={classes.body}>
          {
            state.isLoading ? (
              <div className={classes.loading}>
                <CircularProgress className={classes.spinner}/>
              </div>
            ) : (
              <>
                {
                  state.orderData.items.length !== 0 ? (
                    <>
                      <div className={classes.tabsWrapper}>
                        <TabsMenu items={tabsItems} selected={state.viewMode} onSelect={setViewMode}/>
                      </div>
                      {
                        state.viewMode === 'basic' ? (
                          <table className={classes.table}>
                            <tbody>
                              <tr>
                                <td>{t('stock_order_number')}:</td>
                                <td>{state.orderData.orderID}</td>
                              </tr>
                              <tr>
                                <td>{t('stock_order_date')}:</td>
                                <td>{DateFormat(moment(state.orderData.dateTime), "defaulttime", languageData, t)}</td>
                              </tr>
                              <tr>
                                <td>{t('stock_order_status')}:</td>
                                <td>{getOrderStatusName(state.orderData.orderStatusID)}</td>
                              </tr>
                              {
                                getUserRole(userData.userObject.roleType) === "director" ? (
                                  <tr>
                                    <td>{t('stock_order_user')}:</td>
                                    <td>{state.orderData.userName}</td>
                                  </tr>
                                ) : null
                              }
                              <tr>
                                <td>{t('stock_order_school')}:</td>
                                <td>{getSchoolData(state.orderData.schoolID).name}</td>
                              </tr>
                              {
                                state.orderData.childID ? (
                                  <tr>
                                    <td>{t('stock_order_child')}:</td>
                                    <td>{getChildData(state.orderData.childID).displayName}</td>
                                  </tr>
                                ) : null
                              }
                              <tr>
                                <td>{t('stock_total_price')}:</td>
                                <td><Currency quantity={getTotalPrice()} currency={getCurrency.iso}/></td>
                              </tr>
                              <tr>
                                <td>{t('stock_total_items')}:</td>
                                <td>{getTotalQuantity()}</td>
                              </tr>
                              {
                                state.orderData.note ? (
                                  <tr>
                                    <td>{t('stock_order_note')}:</td>
                                    <td>{state.orderData.note}</td>
                                  </tr>
                                ) : null
                              }
                            </tbody>
                          </table>
                        ) : state.viewMode === 'items' ? (
                          <table>
                            <thead>
                              <tr>
                                <th>
                                  {t('stock_item_name')}
                                </th>
                                <th>
                                  {t('stock_item_quantity')}
                                </th>
                                <th>
                                  {t('stock_item_price')}
                                </th>
                                {
                                  state.isEdit ? (
                                    <th/>
                                  ) : null
                                }
                              </tr>
                            </thead>
                            <tbody>
                              {
                                state.orderData.items.length === 0 ? [] : state.orderData.items.map((item: any, key: any) => (
                                  <tr key={`k_${key}`} >
                                    <td>
                                      <div className={classes.tableCellWrapper}>
                                      <span className={classes.itemName} onClick={() => item.variant.length > 0 ? handleOpenItem(item.itemID, item.variant[0].variantID) : handleOpenItem(item.itemID)}>
                                        {
                                          getItemData(item.itemID).length === 0 ? (
                                            <>
                                              {t('stock_undefined_item')}
                                            </>
                                          ) : (
                                            <>
                                              {getItemData(item.itemID).name}
                                              {
                                                item.variant.length > 0 ? (
                                                  <> 
                                                  &nbsp;-&nbsp;{(getItemData(item.itemID).variant ? (getItemData(item.itemID).variant.filter((variant: any) => variant.variantID === item.variant[0].variantID).length === 0 ?  "" : getItemData(item.itemID).variant.find((variant: any) => variant.variantID === item.variant[0].variantID).name) : "")}
                                                  </>
                                                ) : null
                                              }
                                            </>
                                          )
                                        }
                                      </span>
                                      {
                                        item.variant.length > 0 ? (
                                          <> 
                                          {
                                            (getQuantity(item.itemID, item.variant[0].variantID) !== null && item.orderQuantity > getQuantity(item.itemID, item.variant[0].variantID) && state.orderData.orderStatusID !== 7 && state.orderData.orderStatusID !== 6 && state.orderData.orderStatusID !== 5 && showWarning === true) ? (
                                              <TooltipIcon icon="warning" maxWidth={200} iconColor={theme.colors.systemOrange[500]} position="top" title={t('stock_order_item_no_stock')}/>
                                            ) : null
                                          }
                                          </>
                                        ) : (
                                          <>
                                          {
                                            (getQuantity(item.itemID) !== null && item.orderQuantity > getQuantity(item.itemID) && state.orderData.orderStatusID !== 7 && state.orderData.orderStatusID !== 6 && state.orderData.orderStatusID !== 5 && showWarning === true) ? (
                                              <TooltipIcon icon="warning" maxWidth={200} iconColor={theme.colors.systemOrange[500]} position="top" title={t('stock_order_item_no_stock')}/>
                                            ) : null
                                          }
                                          </>
                                        )
                                      }
                                      </div>
                                    </td>
                                    <td>
                                      {item.orderQuantity}
                                    </td>
                                    <td>
                                      <Currency quantity={(item.orderPrice * (1 + (item.orderTax / 100))) * item.orderQuantity} currency={getCurrency.iso}/>
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        ) : state.viewMode === 'history' ? (
                          <table>
                            <thead>
                              <tr>
                                <th>
                                  {t('stock_order_date_change')}
                                </th>
                                <th>
                                  {t('stock_order_status')}
                                </th>
                                <th>
                                  {t('stock_order_change_note')}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                (state.orderData.orderHistory.length === 0 ? [] : state.orderData.orderHistory).map((item: any, key: any) => (
                                  <tr key={`k_${key}`}>
                                    <td>
                                    {DateFormat(moment(item.date), "defaulttime", languageData, t)}
                                    </td>
                                    <td>
                                      {getOrderStatusName(item.orderStatusID)}
                                    </td>
                                    <td>
                                      {item.note}
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        ) : null
                      }
                    </>
                  ) : null
                }
              </>
            )
          }
          {
            (!state.isLoading && state.viewMode === 'items') ? (
              <div className={classes.bottom}>
                { 
                  state.orderData.note ? (
                    <span>{t('stock_order_note')}: {state.orderData.note}</span>
                  ) : null
                }
                <span>{t('stock_total_price')}: <b><Currency quantity={getTotalPrice()} currency={getCurrency.iso}/></b></span>
              </div>
            ) : null
          }
          <div className={classes.footer}>
            {
              (!state.isLoading && getUserRole(userData.userObject.roleType) === "director") ? (
                <NormalButton buttonType="secondary" onClick={() => handleExportDeliveryList(t('stock_order_delivery_list'), `${t('stock_order_delivery_list_short')} ${orderID} - ${DateFormat(moment(state.orderData.dateTime), "defaulttime", languageData, t)}`, "pdf")} dataCy="exportDeliveryListButton">
                  {t('stock_order_export_delivery_list')}
                </NormalButton>
              ) : null
            }
            {
              (!state.isLoading && getUserRole(userData.userObject.roleType) === "director" && state.orderData.orderStatusID !== 7 && state.orderData.orderStatusID !== 6) ? (
                <NormalButton buttonType="primary" onClick={handleClickChangeStatus} dataCy="changeStatusButton">
                  {t('stock_order_change_status')}
                </NormalButton>
              ) : null
            }
            {
              (!state.isLoading && state.orderData.isEditable) ? (
                <NormalButton buttonType="clear" onClick={handleClickDelete} dataCy="deleteButton">
                  {t('stock_order_cancel')}
                </NormalButton>
              ) : null
            }
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ShopOrderDetailModal;