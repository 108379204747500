import ClassProvider from './ui/classProvider';
import globalStyles from './ui/globalStyles';
import store from './store';
import theme from './ui/theme';
import ThemeProvider from './ui/themeProvider';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { GlobalStyles } from '@mui/material';
import { Provider } from 'react-redux';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import './i18n';

import ErrorScreen from './components/Screen/ErrorScreen';

//Homepage
import Homepage from './containers/Homepage';

//Custom controllers bundle
import Controllers from './controllers';

//Custom routes  
import AuthRoute from './routes/AuthRoute';
import PrivateRoute from './routes/PrivateRoute';
import PublicRoute from './routes/PublicRoute';

//Default Containers
import AboutAppContainer from './containers/Default/About/App';
import AboutGDPRContainer from './containers/Default/About/GDPR';
import ForbiddenContainer from './containers/Default/403';
import NotFoundContainer from './containers/Default/404';
import InternalServerErrorContainer from './containers/Default/500';

//Auth Containers
import LogoutContainer from './containers/Auth/Logout';
import LoginContainer from './containers/Auth/Login';
import ResetPasswordContainer from './containers/Auth/ResetPassword';
import ResetPasswordRequestContainer from './containers/Auth/ResetPasswordRequest';
import SSOContainer from './containers/Auth/SSO';
import SwitchContainer from './containers/Auth/Switch';

//Main Containers
import AttendanceContainer from './containers/Main/Attendance';
import CalendarContainer from './containers/Main/Calendar';
import ClassbookContainer from './containers/Main/Classbook';
import ClubsContainer from './containers/Main/Clubs';
import CommunicationContainer from './containers/Main/Communication';
import ContactsContainer from './containers/Main/Contacts';
import DashboardContainer from './containers/Main/Dashboard';
import DiagnosticsContainer from './containers/Main/Diagnostics';
import InspirationContainer from './containers/Main/Inspiration';
import EmployeesContainer from './containers/Main/Employees';
import ExcuseNotesContainer from './containers/Main/ExcuseNotes';
import FinanceContainer from './containers/Main/Finance';
import ChildrenContainer from './containers/Main/Children';
import GalleryContainer from './containers/Main/Gallery';
import MealsContainer from './containers/Main/Meals';
import NewsContainer from './containers/Main/News';
import PhotoArchiveContainer from './containers/Main/PhotoArchive';
import PremiumStatusContainer from './containers/Main/Settings/components/PremiumStatus';
import PressReportContainer from './containers/Main/PressReport';
import SettingsContainer from './containers/Main/Settings';
import ShopContainer from './containers/Main/Shop';
import StockContainer from './containers/Main/Stock';
import SubstitutionsContainer from './containers/Main/Substitutions';
import TimelineContainer from './containers/Main/Timeline';
import TimetableContainer from './containers/Main/Timetable';

const App = () => {
  
  return (
    <BrowserRouter>
      <Provider store={store}>
        <ClassProvider>
          <MuiThemeProvider theme={theme}>
            <ThemeProvider>
              <GlobalStyles styles={globalStyles()}/>
              <ErrorBoundary FallbackComponent={ErrorScreen}>
                <Controllers>
                  <Routes>
                    <Route path='*' element={
                      <PublicRoute>
                        <NotFoundContainer/>
                      </PublicRoute>
                    } />
                    <Route path="/403" element={
                      <PublicRoute>
                        <ForbiddenContainer/>
                      </PublicRoute>
                    } />     
                    <Route path="/404" element={
                      <PublicRoute>
                        <NotFoundContainer/>
                      </PublicRoute>
                    } />  
                    <Route path="/500" element={
                      <PublicRoute>
                        <InternalServerErrorContainer/>
                      </PublicRoute>
                    } />  
                    <Route path="/" element={
                      <Homepage/>
                    } />
                    <Route path="/auth" element={
                      <Navigate to="/auth/login"/>
                    } />
                    <Route path="/dashboard" element={
                      <PrivateRoute>
                        <DashboardContainer/>
                      </PrivateRoute>
                    } />
                    <Route path="/timeline" element={
                      <PrivateRoute>
                        <TimelineContainer/>
                      </PrivateRoute>
                    } />
                    <Route path="/timetable" element={
                      <PrivateRoute>
                        <TimetableContainer/>
                      </PrivateRoute>
                    } />
                    <Route path="/contacts" element={
                      <PrivateRoute parentChildren={<ContactsContainer/>}/>
                    } />
                    <Route path="/clubs" element={
                      <PrivateRoute>
                        <ClubsContainer/>
                      </PrivateRoute>
                    } />
                    <Route path="/children" element={
                      <PrivateRoute>
                        <ChildrenContainer/>
                      </PrivateRoute>
                    } />
                    <Route path="/children/:tab" element={
                      <PrivateRoute>
                        <ChildrenContainer/>
                      </PrivateRoute>
                    } />
                    <Route path="/diagnostics" element={
                      <PrivateRoute teacherChildren={<DiagnosticsContainer/>} directorChildren={<DiagnosticsContainer/>}/>
                    } />
                    <Route path="/employees" element={
                      <PrivateRoute directorChildren={<EmployeesContainer/>}/>
                    } />
                    <Route path="/finance" element={
                      <PrivateRoute parentChildren={<FinanceContainer/>}/>
                    } />
                    <Route path="/finance/:tab" element={
                      <PrivateRoute parentChildren={<FinanceContainer/>}/>
                    } />
                    <Route path="/excuse-notes" element={
                      <PrivateRoute>
                        <ExcuseNotesContainer/>
                      </PrivateRoute>
                    } />
                    <Route path="/attendance" element={
                      <PrivateRoute teacherChildren={<AttendanceContainer/>} directorChildren={<AttendanceContainer/>}/>
                    } />
                    <Route path="/classbook" element={
                      <PrivateRoute teacherChildren={<ClassbookContainer/>} directorChildren={<ClassbookContainer/>}/>
                    } />
                    <Route path="/classbook/:tab" element={
                      <PrivateRoute teacherChildren={<ClassbookContainer/>} directorChildren={<ClassbookContainer/>}/>
                    } />
                    <Route path="/communication" element={
                      <PrivateRoute>
                        <CommunicationContainer/>
                      </PrivateRoute>
                    } />
                    <Route path="/communication/:tab" element={
                      <PrivateRoute>
                        <CommunicationContainer/>
                      </PrivateRoute>
                    } />
                    <Route path="/communication/:tab/:param" element={
                      <PrivateRoute>
                        <CommunicationContainer/>
                      </PrivateRoute>
                    } />
                    <Route path="/gallery" element={
                      <PrivateRoute parentChildren={<GalleryContainer/>}/>
                    } />
                    <Route path="/inspiration" element={
                      <PrivateRoute>
                        <InspirationContainer/>
                      </PrivateRoute>
                    } />
                    <Route path="/inspiration/:tab" element={
                      <PrivateRoute>
                        <InspirationContainer/>
                      </PrivateRoute>
                    } />
                    <Route path="/inspiration/:tab/:section" element={
                      <PrivateRoute>
                        <InspirationContainer/>
                      </PrivateRoute>
                    } />
                    <Route path="/inspiration/:tab/:board/:boardUrl" element={
                      <PrivateRoute>
                        <InspirationContainer/>
                      </PrivateRoute>
                    } />
                    <Route path="/meals" element={
                      <PrivateRoute>
                        <MealsContainer/>
                      </PrivateRoute>
                    } />
                    <Route path="/news" element={
                      <PrivateRoute>
                        <NewsContainer/>
                      </PrivateRoute>
                    } />
                    <Route path="/press-report" element={
                      <PrivateRoute teacherChildren={<PressReportContainer/>} directorChildren={<PressReportContainer/>}/>
                    } />
                    <Route path="/press-report/:tab" element={
                      <PrivateRoute teacherChildren={<PressReportContainer/>} directorChildren={<PressReportContainer/>}/>
                    } />
                    <Route path="/photo-archive" element={
                      <PrivateRoute parentChildren={<PhotoArchiveContainer/>}/>
                    } />
                    <Route path="/calendar" element={
                      <PrivateRoute>
                        <CalendarContainer/>
                      </PrivateRoute>
                    } />
                    <Route path="/settings" element={
                      <PrivateRoute>
                        <SettingsContainer/>
                      </PrivateRoute>
                    } />
                    <Route path="/settings/:tab" element={
                      <PrivateRoute>
                        <SettingsContainer/>
                      </PrivateRoute>
                    } />
                    <Route path="/settings/:tab/:viewmode" element={
                      <PrivateRoute>
                        <SettingsContainer/>
                      </PrivateRoute>
                    } />
                    <Route path="/shop" element={
                      <PrivateRoute parentChildren={<ShopContainer/>}/>
                    } />
                    <Route path="/stock" element={
                      <PrivateRoute directorChildren={<StockContainer/>}/>
                    } />
                    <Route path="/premium/status" element={
                      <PremiumStatusContainer/>
                    } />
                    <Route path="/substitutions" element={
                      <PrivateRoute>
                        <SubstitutionsContainer/>
                      </PrivateRoute>
                    } />
                    <Route path="/auth/logout" element={
                      <LogoutContainer/>
                    } />
                    <Route path="/auth/login" element={
                      <AuthRoute isAuthPage>
                        <LoginContainer/>
                      </AuthRoute>
                    } />
                    <Route path="/auth/reset-password/:token" element={
                      <AuthRoute>
                        <ResetPasswordContainer/>
                      </AuthRoute>
                    } />
                    <Route path="/auth/reset-password-request" element={
                      <AuthRoute>
                        <ResetPasswordRequestContainer/>
                      </AuthRoute>
                    } />
                    <Route path="/auth/sso/:token" element={
                      <AuthRoute>
                        <SSOContainer/>
                      </AuthRoute>
                    } />
                    <Route path="/auth/switch" element={
                      <AuthRoute>
                        <SwitchContainer/>
                      </AuthRoute>
                    } />
                    <Route path="/about/app" element={
                      <PublicRoute>
                        <AboutAppContainer/>
                      </PublicRoute>
                    } />
                    <Route path="/about/gdpr" element={
                      <PublicRoute>
                        <AboutGDPRContainer/>
                      </PublicRoute>
                    } />
                  </Routes>
                </Controllers>
              </ErrorBoundary>
            </ThemeProvider>
          </MuiThemeProvider>
        </ClassProvider>
      </Provider>
    </BrowserRouter>
  );
}

export default App;