import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Model {
  [key: string]: any;
};

interface ArrayModel {
  medias: any;
  selected: any;
  zipState: any;
  zipSelected: any;
  zipFilesData: any;
  zipCurrentIndex: any;
};

const initialState: ArrayModel = {
  medias: [],
  selected: [],
  zipState: "none",
  zipSelected: [],
  zipFilesData: [],
  zipCurrentIndex: 1,
};

const slice = createSlice({
    name: 'gallery',
    initialState: initialState,
    reducers: {
      setGalleryMedias(state, action: PayloadAction<Model>){
        state.medias = action.payload;
      },
      setGallerySelected(state, action: PayloadAction<Model>){
        state.selected = action.payload;
      },
      setGalleryZipState(state, action: PayloadAction<Model>){
        state.zipState = action.payload;
      },
      setGalleryZipSelected(state, action: PayloadAction<Model>){
        state.zipSelected = action.payload;
      },
      setGalleryZipCurrentIndex(state, action: PayloadAction<Model>){
        state.zipCurrentIndex = action.payload;
      },
      setGalleryZipFilesData(state, action: PayloadAction<Model>){
        state.zipFilesData = action.payload;
      },
      clearGalleryMedias(state){
        state.medias = [];
      },
    }
});

export const gallerySlice = slice;