import { AnyAction } from '@reduxjs/toolkit';
import { gallerySlice as slice } from '../slices/gallery.slice';
import { RootState } from '../index';
import { ThunkAction } from '@reduxjs/toolkit';

export const actions = slice.actions;

export const setGalleryMedias = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setGalleryMedias(data));
  }
};

export const setGallerySelected = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setGallerySelected(data));
  }
};

export const setGalleryZipState = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setGalleryZipState(data));
  }
};

export const setGalleryZipSelected = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setGalleryZipSelected(data));
  }
};


export const setGalleryZipFilesData = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setGalleryZipFilesData(data));
  }
};

export const setGalleryZipCurrentIndex = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setGalleryZipCurrentIndex(data));
  }
};

export const clearGalleryMedias = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.clearGalleryMedias());
  }
};