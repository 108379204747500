import moment from '../../utils/moment';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Model {
  [key: string]: any;
};

interface ArrayModel {
  currentChild: any;
  currentChildren: any;
  currentChildrenData: any;
  currentClass: any;
  date: any;
  list: any;
  months: any;
  viewMode: any,
};

const initialState: ArrayModel = {
  currentChild: null,
  currentChildren: [],
  currentChildrenData: [],
  currentClass: null,
  date: moment(),
  list: [],
  months: [],
  viewMode: 'calendar',
};

const slice = createSlice({
  name: 'excusenotes',
  initialState: initialState,
  reducers: {
    setExcuseNotesCurrentChild(state, action: PayloadAction<Model>) {
      state.currentChild = action.payload;
    },
    setExcuseNotesCurrentChildren(state, action: PayloadAction<Model>) {
      state.currentChildren = action.payload;
    },
    setExcuseNotesCurrentChildrenData(state, action: PayloadAction<Model>) {
      state.currentChildrenData = action.payload;
    },
    setExcuseNotesCurrentClass(state, action: PayloadAction<Model>) {
      state.currentClass = action.payload;
    },
    setExcuseNotesDate(state, action: PayloadAction<Model>) {
      state.date = action.payload;
    },
    setExcuseNotesList(state, action: PayloadAction<Model>) {
      state.list = state.list.concat(action.payload);
    },
    addToExcuseNotesMonths(state, action: PayloadAction<Model>) {
      const childID = action.payload.childID;
      const month = action.payload.month;
      if(state.months.filter((item: any) => item.childID === childID).length > 0) {
        state.months = state.months.map((item: any) => {
          if(item.childID === childID) {
            return {...item, months: item.months.concat(month)};
          } else {
            return item;
          }
        });
      } else {
        state.months = state.months.concat({childID: childID, months: [month]});
      }
    },
    addToExcuseNotesList(state, action: PayloadAction<Model>) {
      const childID = action.payload.childID;
      const dates = action.payload.dates;
      if(state.list.filter((item: any) => item.childID === childID).length === 0) {
        const newData = { childID: childID, dates: dates };
        state.list = state.list.concat(newData);
      } else {
        state.list = state.list.map((item: any) => {
          if(item.childID === childID) {
            return {...item, dates: item.dates.concat(dates)};
          } else {
            return item;
          }
        });
      }
    },
    addExcuseNotesList(state, action: PayloadAction<Model>) {
      action.payload.forEach((data: any) => {
        if(state.list.filter((item: any) => item.childID === data.childID).length === 1) {
          state.list = state.list.map((item: any) => {
            if(item.childID === data.childID) {
              return {...item, dates: [...item.dates, data]};
            } else {
              return item;
            }
          });
        }
      });
    },
    updateExcuseNotesList(state, action: PayloadAction<Model>) {
      const absences = action.payload.absences;
      state.list = state.list.map((data: any) => {
        return {...data, dates: data.dates.map((date: any) => {
          if(absences.length > 0) {
            if(absences.filter((item: any) => item.absenceID === date.absenceID).length === 1) {
              return absences.find((item: any) => item.absenceID === date.absenceID);
            } else {
              return date;
            }
          } else {
            return date;
          }
        })};
      });
    },
    updateExcuseNotesGroupList(state, action: PayloadAction<Model>) {
      state.list = state.list.map((data: any) => {
        if(data.childID === action.payload.childID) {
          return {...data, dates: data.dates.map((date: any) => {
            if(date.absenceGroupID === action.payload.absenceGroupID) {
              return {...date, absencePartID: action.payload.absencePartID, absenceTypeID: action.payload.absenceTypeID, note: action.payload.note};
              //return action.payload.data.filter((item: any) => item.absenceID === date.absenceID).length === 0 ? date : action.payload.data.find((item: any) => item.absenceID === date.absenceID);
            } else {
              return date;
            }
          })};
        } else {
          return data;
        }
      });
    },
    deleteExcuseNotesAbsence(state, action: PayloadAction<Model>) {
      const absences = action.payload.absences;
      state.list = state.list.filter((data: any) => data.childID === action.payload.childID).map((data: any) => {
        return {...data, dates: data.dates.filter((date: any) => date.absenceID !== action.payload.absenceID).map((absence: any) => {
          if(absences.length > 0) {
            if(absences.filter((item: any) => item.absenceID === absence.absenceID).length === 1) {
              return absences.find((item: any) => item.absenceID === absence.absenceID);
            } else {
              return absence;
            }
          } else {
            return absence;
          }
        })};
      });
    },
    deleteExcuseNotesAbsenceGroup(state, action: PayloadAction<Model>) {
      state.list = state.list.filter((data: any) => data.childID === action.payload.childID).map((data: any) => {
        return {...data, dates: data.dates.filter((date: any) => date.absenceGroupID !== action.payload.absenceGroupID)};
      });
    },
    clearExcuseNotesList(state) {
      state.list = [];
    },
    clearExcuseNotesMonths(state) {
      state.months = [];
    },
    setExcuseNotesViewMode(state, action: PayloadAction<Model>) {
      state.viewMode = action.payload;
    },
  }
});

export const excusenotesSlice = slice;