import DateFormat from 'src/utils/dateFormat';
import Flag from 'react-world-flags';
import React from 'react';
import Tooltip from 'src/components/Layouts/Tooltip';
import { createUseStyles } from 'react-jss';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  loggedInDeviceInfoItem: {
    position: 'relative',
    padding: "10px 15px",
    display: "flex",
    flexDirection: 'column',
    width: '100%',
    minWidth: 'unset',
    borderRadius: '12px',
    gap: '8px',
  },
  row: {
    display: "flex",
    justifyContent: 'flex-start',
    width: '100%',
    gap: '12px',
    '&:empty': {
      display: 'none',
    },
  },
  items: {
    display: "flex",
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '100%',
    gap: '4px',
  },
  detail: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    fontSize: '13px',
    textTransform: 'none',
    fontWeight: '400',
    color: theme.colors.black,
    '& > span': {
      whiteSpace: 'nowrap',
      fontWeight: '600',
      '&::after': {
        content: `':'`,
      },
    },
  },
  flagWrapper: {
    display: 'flex',
    height: 'fit-content',
  },
}));

type LoggedInDeviceInfoItemType = {
  tokenID: number;
  appVersion: string;
  browser: any;
  countryCode: any;
  ipAddress: string;
  lastUseDateTime: any;
  os: string;
  platformID: string;
};

const LoggedInDeviceInfoItem: React.FunctionComponent<LoggedInDeviceInfoItemType> = ({ tokenID, appVersion, browser, countryCode, ipAddress, lastUseDateTime, os, platformID }) => {
  
  const classes = useStyles();
  const languageData = useAppSelector((state: any) => state.language);

  const { t } = useTranslation();
  
  return (
    <div className={classes.loggedInDeviceInfoItem}>
      <div className={classes.row}>
        {
          (ipAddress || browser || os || countryCode) ? (
            <div className={classes.items}>
              {
                ipAddress ? (
                  <div className={classes.detail}>
                    <span>{t('logged_in_devices_ip_address')}</span>
                    {ipAddress}
                  </div> 
                ) : null
              }
              {
                browser ? (
                  <div className={classes.detail}>
                    <span>{t('logged_in_devices_browser')}</span>
                    {browser}
                  </div> 
                ) : null
              }
              {
                os ? (
                  <div className={classes.detail}>
                    <span>{t('logged_in_devices_os')}</span>
                    {os}
                  </div> 
                ) : null
              }
              {
                countryCode ? (
                  <div className={classes.detail}>
                    <span>{t('logged_in_devices_country')}</span>
                    <Tooltip title={countryCode}>
                      <div className={classes.flagWrapper}>
                        <Flag code={(countryCode || 'us')} height="16"/>
                      </div>
                    </Tooltip>
                  </div> 
                ) : null
              }
            </div>
          ) : null
        }
        {
          (platformID || appVersion || lastUseDateTime) ? (
            <div className={classes.items}>
              {
                platformID ? (
                  <div className={classes.detail}>
                    <span>{t('logged_in_devices_platform')}</span>
                    {platformID}
                  </div> 
                ) : null
              }
              {
                appVersion ? (
                  <div className={classes.detail}>
                    <span>{t('logged_in_devices_app_version')}</span>
                    {appVersion}
                  </div> 
                ) : null
              }
              {
                lastUseDateTime ? (
                  <div className={classes.detail}>
                    <span>{t('logged_in_devices_last_update')}</span>
                    {DateFormat(lastUseDateTime, "defaulttime", languageData)}
                  </div> 
                ) : null
              }
            </div>
          ) : null
        }
      </div>
    </div>
  );
}

export default LoggedInDeviceInfoItem;