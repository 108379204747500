import { AnyAction } from '@reduxjs/toolkit';
import { firebasenotificationSlice as slice } from '../slices/firebasenotification.slice';
import { RootState } from '../index';
import { ThunkAction } from '@reduxjs/toolkit';

export const actions = slice.actions;

export const setFirebaseNotification = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setFirebaseNotifications(data));
  }
};

export const clearFirebaseNotification = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.clearFirebaseNotifications());
  }
};

export const setFirebasePosition = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setFirebaseNotificationsPosition(data));
  }
};

export const setFirebaseTimeout = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setFirebaseNotificationsTimeout(data));
  }
};

export const setFirebasePermission = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setFirebaseNotificationsPermission(data));
  }
};

export const resetFirebaseNotifications = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.resetFirebaseNotifications());
  }
};