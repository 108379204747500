import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ArrayModel {
  initializeUserController: boolean;
  deinitializeUserController: boolean;
  reinitializeUserController: boolean;
  resetUserController: boolean;
};

const initialState: ArrayModel = {
  initializeUserController: true,
  deinitializeUserController: false,
  reinitializeUserController: false,
  resetUserController: false,
};

const slice = createSlice({
  name: 'controllers',
  initialState: initialState,
  reducers: {
    setInitializeUserController(state, action: PayloadAction<boolean>){
      state.initializeUserController = action.payload;
    },
    setDeinitializeUserController(state, action: PayloadAction<boolean>){
      state.deinitializeUserController = action.payload;
    },
    setReinitializeUserController(state, action: PayloadAction<boolean>){
      state.reinitializeUserController = action.payload;
    },
    setResetUserController(state, action: PayloadAction<boolean>){
      state.resetUserController = action.payload;
    },
  }
});

export const controllersSlice = slice;