import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Model {
  [key: string]: any;
}

interface ArrayModel {
  settings: any;
  widgets: any;
}

const initialState: ArrayModel = {
  settings: [],
  widgets: [],
};

const slice = createSlice({
  name: 'dashboard',
  initialState: initialState,
  reducers: {
    setDashboardSettings(state, action: PayloadAction<Model>){
      state.settings = action.payload;
    },
    setDashboardWidgets(state, action: PayloadAction<Model>){
      state.widgets = action.payload;
    },
    addDashboardWidget(state, action: PayloadAction<Model>){
      const newWidgetKey = state.widgets.length;
      const newWidgetX = 0;
      const newWidgetY = Math.max(...state.widgets.map((item: any) => item.y));
      const newWidgetData = {key: newWidgetKey, type: action.payload.key, x: newWidgetX, y: newWidgetY, settings: action.payload.settings};
      state.widgets = [...state.widgets, newWidgetData];
    },
  }
});

export const dashboardSlice = slice;