import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Model {
  [key: string]: any;
};

interface ArrayModel {
  employees: any;
  employeesID: any;
  children: any;
  childrenID: any;
  classes: any;
  classesID: any;
  schools: any;
  schoolsID: any;
  users: any;
  usersID: any;
};

const initialState: ArrayModel = {
  employees: [],
  employeesID: [],
  children: [],
  childrenID: [],
  classes: [],
  classesID: [],
  schools: [],
  schoolsID: [],
  users: [],
  usersID: [],
};

const slice = createSlice({
  name: 'data',
  initialState: initialState,
  reducers: {
    setEmployees(state, action: PayloadAction<Model>){
      state.employees = action.payload;
    },
    setEmployeesID(state, action: PayloadAction<Model>){
      state.employeesID = action.payload;
    },
    setChildren(state, action: PayloadAction<Model>){
      state.children = action.payload;
    },
    setChildrenID(state, action: PayloadAction<Model>){
      state.childrenID = action.payload;
    },
    setClasses(state, action: PayloadAction<Model>){
      state.classes = action.payload;
    },
    setClassesID(state, action: PayloadAction<Model>){
      state.classesID = action.payload;
    },
    setSchools(state, action: PayloadAction<Model>){
      state.schools = action.payload;
    },
    setSchoolsID(state, action: PayloadAction<Model>){
      state.schoolsID = action.payload;
    },
    setUsers(state, action: PayloadAction<Model>){
      state.users = action.payload;
    },
    setUsersID(state, action: PayloadAction<Model>){
      state.usersID = action.payload;
    },
    addEmployee(state, action: PayloadAction<Model>){
      state.employees = [...state.employees, action.payload];
      state.employeesID = [...state.employeesID, action.payload.userID];
    },
    addChild(state, action: PayloadAction<Model>){
      state.children = [...state.children, action.payload];
      state.childrenID = [...state.childrenID, action.payload.childID];
    },
    addClass(state, action: PayloadAction<Model>){
      state.classes = [...state.classes, action.payload];
      state.classesID = [...state.classesID, action.payload.classID];
    },
    addSchool(state, action: PayloadAction<Model>){
      state.schools = [...state.schools, action.payload];
      state.schoolsID = [...state.schoolsID, action.payload.schoolID];
    },
    addUser(state, action: PayloadAction<Model>){
      state.users = [...state.users, action.payload];
    },
    addUserID(state, action: PayloadAction<Model>){
      state.usersID = [...state.usersID, action.payload];
    },
  }
});

export const dataSlice = slice;