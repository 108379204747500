import config from 'src/constants/config';
import MultipleTabScreen from 'src/components/Screen/MultipleTabScreen';
import PropTypes from 'prop-types';
import React from 'react';
import { MultiTabDetection } from 'multi-tab-detection';
import { setIsTooManyTabs } from 'src/store/actions/layout.actions';
import { useAppDispatch } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useState } from 'src/utils/useState';

interface Props {
  children: React.ReactNode;
};

const WindowController: React.FunctionComponent<Props> = ({ children }) => {

  const dispatch = useAppDispatch();
  const [numberOfTabs, setNumberOfTabs] = useState(1);
  const currentNumberOfTabs = numberOfTabs;

  useEffect(() => {
    const multiTabDetection = new MultiTabDetection();
    let lastTabCount = currentNumberOfTabs;

    const updateTabCount = () => {
      setTimeout(() => {
        const totalTabs = multiTabDetection.NumberOfTabsOpened;
        if(totalTabs !== lastTabCount) {
          setNumberOfTabs(totalTabs);
          lastTabCount = totalTabs;
        }
      }, 1000);
    };

    const handleExistingTab = () => {
      if(lastTabCount !== multiTabDetection.NumberOfTabsOpened) {
        updateTabCount();
      }
    };

    const handleNewTab = (totalNumberOfTabsOpened: number) => {
      if (totalNumberOfTabsOpened !== lastTabCount) {
        setNumberOfTabs(totalNumberOfTabsOpened);
        lastTabCount = totalNumberOfTabsOpened;
      }
    };

    const handleClosedTab = (totalNumberOfTabsOpened: number) => {
      if(totalNumberOfTabsOpened !== lastTabCount) {
        setNumberOfTabs(totalNumberOfTabsOpened);
        lastTabCount = totalNumberOfTabsOpened;
      }
    };

    const existingTabSubscription = multiTabDetection.ExistingTabDetectedEvent.subscribe(handleExistingTab);
    const newTabSubscription = multiTabDetection.NewTabDetectedEvent.subscribe(handleNewTab);
    const closedTabSubscription = multiTabDetection.ClosedTabDetectedEvent.subscribe(handleClosedTab);

    return () => {
      existingTabSubscription.unsubscribe();
      newTabSubscription.unsubscribe();
      closedTabSubscription.unsubscribe();
    };
  }, [], []);

  useEffect(() => {
    if(config.APP_LIMIT_TABS) {
      dispatch(setIsTooManyTabs(currentNumberOfTabs !== 1));
    }
  }, [], [currentNumberOfTabs]);

  return (
    <> 
      {
        (config.APP_LIMIT_TABS && currentNumberOfTabs !== 1) ? (
          <MultipleTabScreen/>
        ) : null
      }
      {children}
    </>
  );
};

WindowController.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WindowController;
