import CircleIcon from '@mui/icons-material/Circle';
import React from 'react';
import SVG from '../../Images/SvgRenderer';
import Tooltip from 'src/components/Layouts/Tooltip';
import { createUseStyles } from 'react-jss';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

interface Props {
  absenceApprovalStatusID?: any;
  size?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  absenceInfoItem: {
    position: 'relative',
    width: (props: Props) => props.size + 'px',
    height: (props: Props) => props.size + 'px',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '0',
    '&:after': {
      content: `''`,
      position: 'absolute',
      top: '2px',
      left: '2px',
      right: '2px',
      bottom: '2px',
      borderWidth: '1.5px',
      borderStyle: 'solid',
      borderColor: (props: Props) => {
        if(props.absenceApprovalStatusID === 1) return theme.colors.systemGreen[500];
        else if(props.absenceApprovalStatusID === 2) return theme.colors.systemRed[500];
        else if(props.absenceApprovalStatusID === 3) return theme.colors.systemOrange[500];
        else return 'transparent';
      },
      borderRadius: '100%',
      zIndex: '1',
    },
  },
  absenceAbsentPartAM: {
    position: 'relative',
    top: '0px',
    left: (props: Props) => '-' + (props.size / 4) + 'px',
    bottom: '0px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    width: (props: Props) => (props.size / 2) + 'px',
    '& > svg': {
      width: (props: Props) => props.size + 'px',
      height: (props: Props) => props.size + 'px',
      color: 'inherit',
    }, 
  },
  absenceAbsentPartPM: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    bottom: '0px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    width: (props: Props) => (props.size / 2) + 'px',
    '& > svg': {
      marginLeft: (props: Props) => '-' + (props.size / 2) + 'px',
      width: (props: Props) => props.size + 'px',
      height: (props: Props) => props.size + 'px',
      color: 'inherit',
    }, 
  },
  absenceImageMorning: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateY(-50%)',
    zIndex: '2',
    width: (props: Props) => ((props.size / 2) - 1) + 'px',
    height: (props: Props) => ((props.size / 2) - 1) + 'px',
  },
  absenceImageAfternoon: {
    position: 'absolute',
    top: '50%',
    right: '50%',
    transform: 'translateY(-50%)',
    zIndex: '2',
    width: (props: Props) => ((props.size / 2) - 1) + 'px',
    height: (props: Props) => ((props.size / 2) - 1) + 'px',
  },
  absenceLateMorning: {
    position: 'absolute',
    left: '0px',
    zIndex: '3',
    bottom: (props: Props) => '-' + (props.size / 4) + 'px',
    pointerEvents: 'none',
    '& > svg': {
      backgroundColor: theme.colors.systemRed[500],
      borderRadius: '100%',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.colors.white,
      width: (props: Props) => ((props.size / 2) - 4) + 'px',
      height: (props: Props) => ((props.size / 2) - 4) + 'px',
      color: theme.colors.white,
      padding: '2px',
    },
  },
  absenceLateDay: {
    position: 'absolute',
    zIndex: '3',
    bottom: (props: Props) => '-' + (props.size / 4) + 'px',
    pointerEvents: 'none',
    '& > svg': {
      backgroundColor: theme.colors.systemRed[500],
      borderRadius: '100%',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.colors.white,
      width: (props: Props) => ((props.size / 2) - 4) + 'px',
      height: (props: Props) => ((props.size / 2) - 4) + 'px',
      color: theme.colors.white,
      padding: '2px',
    },
    
  },
  absenceLateAfternoon: {
    position: 'absolute',
    right: '0px',
    zIndex: '3',
    bottom: (props: Props) => '-' + (props.size / 4) + 'px',
    pointerEvents: 'none',
    '& > svg': {
      backgroundColor: theme.colors.systemRed[500],
      borderRadius: '100%',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.colors.white,
      width: (props: Props) => ((props.size / 2) - 4) + 'px',
      height: (props: Props) => ((props.size / 2) - 4) + 'px',
      color: theme.colors.white,
      padding: '2px',
    },
  },
}));

type AbsenceInfoType = {
  absencePartID: any;
  morningAbsenceStatusID?: any;
  afternoonAbsenceStatusID?: any;
  absenceApprovalStatusID?: any;
  isLate: any;
  size?: any;
  tooltip?: boolean;
};

const AbsenceInfoItem: React.FunctionComponent<AbsenceInfoType> = ({ absencePartID, morningAbsenceStatusID, afternoonAbsenceStatusID, isLate = false, absenceApprovalStatusID, size = 32, tooltip }) => {
  
  const { t } = useTranslation();
  const classes = useStyles({
    absenceApprovalStatusID: absenceApprovalStatusID,
    size: size,
  });
  const configurationData = useAppSelector((state: any) => state.configuration);
  const absenceTypes = configurationData.configuration.absenceTypes;
  const morningType = morningAbsenceStatusID ? (absenceTypes.filter((absenceTypeData: any) => absenceTypeData.absenceTypeID === morningAbsenceStatusID).length === 0 ? absenceTypes[0] : absenceTypes.find((absenceTypeData: any) => absenceTypeData.absenceTypeID === morningAbsenceStatusID)) : null;
  const afternoonType = afternoonAbsenceStatusID ? (absenceTypes.filter((absenceTypeData: any) => absenceTypeData.absenceTypeID === afternoonAbsenceStatusID).length === 0 ? absenceTypes[0] : absenceTypes.find((absenceTypeData: any) => absenceTypeData.absenceTypeID === afternoonAbsenceStatusID)) : null;

  const statuses: any = {
    1: "wholeDay",
    2: "morning",
    3: "afternoon",
  };

  const titles: any = {
    wholeDay: t("excuse_note_whole_day_reason"),
    morning: t("excuse_note_morning_reason"),
    afternoon: t("excuse_note_afternoon_reason"),
  };

  const positions: any = {
    wholeDay: "top",
    morning: "top-end",
    afternoon: "top-start",
  };

  const getTooltipTitle = (dayPart: any, reason: any) => {
    if(dayPart) {
      return t(titles[dayPart], {reason: (reason.name).toLowerCase()});
    } else {
      return "";
    }
  };

  const getTooltipPosition = (dayPart: any) => {
    if(dayPart) {
      return positions[dayPart];
    } else {
      return "";
    }
  };

  const TooltipContainer = ({children, type, status, reason}: any) => {
    const getStatus = statuses[status];
    return tooltip ? (
      <>
        {
          (type === "wholeDay" && getStatus === type) || (type === "morning" && morningAbsenceStatusID && getStatus === type) || (type === "afternoon" && afternoonAbsenceStatusID && getStatus === type) ? (
            <Tooltip title={getTooltipTitle(type, reason)} position={getTooltipPosition(type)} maxWidth={200} align='center'>
              {children}
            </Tooltip>
          ) : (
            <>
              {
                type === "wholeDay" ? (
                  <span>
                    {children}
                  </span>
                ) : children
              }
            </>
          )
        }
      </>
    ) : children;
  };

  return (
    <div className={classes.absenceInfoItem}>
      <TooltipContainer type="wholeDay" status={absencePartID} reason={morningType}>
        <TooltipContainer type="morning" status={absencePartID} reason={morningType}>
        {
          (morningAbsenceStatusID !== null && morningAbsenceStatusID !== undefined && morningType !== null) ? (
            <div className={classes.absenceAbsentPartAM} style={{color: morningType.color}}>
              <CircleIcon/>
              {
                morningType.image ? (
                  <img className={classes.absenceImageMorning} src={morningType.image} alt={morningType.name} />
                ) : null
              }
            </div>
          ) : null
        }
        </TooltipContainer>
        <TooltipContainer type="afternoon" status={absencePartID} reason={afternoonType}>
        {
          (afternoonAbsenceStatusID !== null && afternoonAbsenceStatusID !== undefined && afternoonType !== null) ? (
            <div className={classes.absenceAbsentPartPM} style={{color: afternoonType.color}}>
              <CircleIcon/>
              {
                afternoonType.image ? (
                  <img className={classes.absenceImageAfternoon} src={afternoonType.image} alt={afternoonType.name} />
                ) : null
              }
            </div>
          ) : null
        }
        </TooltipContainer>
      </TooltipContainer>
      {
        isLate ? (
          <>
            {
              (morningAbsenceStatusID !== null && morningAbsenceStatusID !== undefined && morningType !== null && afternoonAbsenceStatusID !== null && afternoonAbsenceStatusID !== undefined && afternoonType !== null) ? (
                <span className={classes.absenceLateDay}>
                  <SVG src="exclamation-mark"/>
                </span>  
              ) : (morningAbsenceStatusID !== null && morningAbsenceStatusID !== undefined && morningType !== null) ? (
                <span className={classes.absenceLateMorning}>
                  <SVG src="exclamation-mark"/>
                </span>  
              ) : (afternoonAbsenceStatusID !== null && afternoonAbsenceStatusID !== undefined && afternoonType !== null) ? (
                <span className={classes.absenceLateAfternoon}>
                  <SVG src="exclamation-mark"/>
                </span>  
              ) : null
            }
          </>
        ) : null
      }  
    </div>
  );
}

export default AbsenceInfoItem;