import * as UserService from '../../../../../../services/user.service';
import Banner from '../../../../../../components/Layouts/Banner';
import Input from '../../../../../../components/Forms/Input';
import NormalButton from '../../../../../../components/Buttons/NormalButton';
import React, { useRef } from 'react';
import { createNotification } from '../../../../../../utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getUserRole } from '../../../../../../utils/useUser';
import { isKey } from 'src/utils/useFunctions';
import { setUserObject } from '../../../../../../store/actions/user.actions';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/redux-hooks';
import { useStates } from '../../../../../../utils/useState';
import { useTranslation } from 'react-i18next';
import { validateEmail } from '../../../../../../utils/useValidation';
import { getAppEnvironmentApiData } from 'src/utils/useApp';

const useStyles = createUseStyles((theme: any) => ({
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& > label': {
      display: 'flex',
      color: theme.colors.black,
      fontSize: '14px',
      marginTop: '16px',
      marginBottom: '7px',
      fontWeight: 'bold',
      width: '100%',
    },
    '& > span': {
      display: 'flex',
      color: theme.colors.black,
      fontSize: '14px',
      width: '100%',
    },
  },
  banner: {
    marginTop: '16px',
  },
  link: {
    color: theme.colors.primaryBlue[500],
    cursor: 'pointer',
    textDecoration: 'underline',
    marginLeft: '0px !important',
  },
}));

const EmailChange: React.FunctionComponent = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const userData = useAppSelector((state: any) => state.user);
  const actualEmailInput: any = useRef({});
  const newEmailInput: any = useRef({});

  const defaultEmailValues = {
    actualEmail: userData.userObject.email,
    newEmail: '',
  };

  const [emailValues, setEmailValues] = useStates(defaultEmailValues);

  const [state, setState] = useStates({
    isEmailChanged: false,
  });

  const isFilledEmail = emailValues.actualEmail !== '' && emailValues.newEmail !== '' && emailValues.newEmail !== emailValues.actualEmail;

  const handleEmailInput = (name: any, value: any) => {
    setEmailValues(name, value);
  };

  const sendEmailChange = () => {
    let payload: any = {};
    Object.keys(emailValues).forEach((key: any) => {
      if(key !== "actualEmail") {
        payload[key] = emailValues[key as keyof typeof emailValues];
      }
    });
    if(Object.keys(payload).length > 0) {
      if(validateEmail(emailValues.newEmail)) {
        if(emailValues.newEmail !== emailValues.actualEmail) {
          UserService && UserService.changeEmail(payload).then((result: any) => {
            if(result.status === 201) { 
              let newUser = userData.userObject;
              const newRequest = {
                open: true,
                email: emailValues.newEmail,
              }
              newUser = {...newUser, emailChangeRequest: newRequest};
              dispatch(setUserObject(newUser));
              setState("isEmailChanged", true);
              createNotification(t('email_request_sent'), 'success');
            } else {
              createNotification(t('something_went_wrong'), 'error');
            }
          }).catch((e: any) => {
            if(e.response.status === 403) {
              createNotification(!isKey(e.response.data.message) ? e.response.data.message : t('email_already_used'), 'error');
            } else if(e.response.status === 404) {
              createNotification(!isKey(e.response.data.message) ? e.response.data.message : t('email_request_exist'), 'error');
            } else {
              createNotification(!isKey(e.response.data.message) ? e.response.data.message : t('something_went_wrong'), 'error');
            }
          });
        } else {
          createNotification(t('email_same'), 'error');
        }
      } else {
        createNotification(t('email_invalid'), 'error');
      }
    }
  };

  const cancelEmailChange = () => {
    UserService && UserService.deleteChangeEmail().then((result: any) => {
      if(result.status === 201) { 
        let newUser = userData.userObject;
        const newRequest = {
          open: false,
          email: "",
        }
        newUser = {...newUser, emailChangeRequest: newRequest};
        dispatch(setUserObject(newUser));
        newEmailInput.current.querySelector("input").value = '';
        createNotification(t('email_request_canceled'), 'success');
      } else {
        createNotification(t('something_went_wrong'), 'error');
      }
    }).catch((e: any) => {
      createNotification(!isKey(e.response.data.message) ? e.response.data.message : t('something_went_wrong'), 'error');
    });
  };
  
  const adminUrl = getAppEnvironmentApiData().adminUrl;
  const adminUrlClear = adminUrl.replace("https://", "").replace("/", "");

  const openAdmin = () => {
    window.open(adminUrl);
  };

  return (
    <>
      {
        getUserRole(userData.userObject.roleType) === "parent" ? (
          <>
            <Input type="email" label={t('email_actual')} value={emailValues.actualEmail} name="actaulEmail" onChange={handleEmailInput} tabIndex="1" readOnly={true} customRef={actualEmailInput}/>
            <Input type="email" label={t('email_new') + '*'} value={userData.userObject.emailChangeRequest.open ? userData.userObject.emailChangeRequest.email : ''} name="newEmail" onChange={handleEmailInput} tabIndex="3" customRef={newEmailInput} disabled={userData.userObject.emailChangeRequest.open}/>
            {
              userData.userObject.emailChangeRequest.open ? (
                <>
                  {
                    state.isEmailChanged ? (
                      <Banner className={classes.banner} type="success" icon={true}>{t('email_request_sent_info')}</Banner>
                    ) : (
                      <>
                        <Banner className={classes.banner} type="info" icon={true}>{t('email_request_info')}</Banner>
                        <NormalButton buttonType="clear" onClick={cancelEmailChange} tabIndex="7">{t('email_request_delete')}</NormalButton>
                      </>
                    )
                  }
                </>
              ) : (
                <NormalButton buttonType="primary" disabled={!isFilledEmail} onClick={sendEmailChange} tabIndex="7">
                  {t('email_request_send')}
                </NormalButton>
              )
            }
          </>
        ) : (
          <div className={classes.inputWrapper}>
            <label>{t('email_actual')}</label>
            <span>{emailValues.actualEmail}</span>
            <Banner className={classes.banner} type="info" icon={true}>
              {t('email_request_not_available_only_admin')} <span className={classes.link} onClick={openAdmin}>{adminUrlClear}</span>.
            </Banner>
          </div>
        )
      }
    </>
  );
};

export default EmailChange;