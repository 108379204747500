import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Model {
  [key: string]: any;
};

interface ArrayModel {
  cart: any;
  categories: any;
  category: any;
  items: any;
  itemsCount: any;
  orders: any;
  selectedOrders: any;
  ordersCount: any;
  school: any;
  tags: any;
  viewMode: any;
  filterCurrentPage: any;
  filterOrderID: any;
  filterSchoolID: any;
  filterChildID: any;
  filterUserID: any;
  filterOrderStatusID: any;
};

const initialState: ArrayModel = {
  cart: [],
  categories: [],
  category: null,
  items: [],
  itemsCount: 0,
  orders: [],
  selectedOrders: [],
  ordersCount: 0,
  school: null,
  tags: [],
  viewMode: "schoolSelect",
  filterCurrentPage: 1,
  filterOrderID: null,
  filterSchoolID: [],
  filterChildID: null,
  filterUserID: [],
  filterOrderStatusID: [],
};

const slice = createSlice({
    name: 'stock',
    initialState: initialState,
    reducers: {
      setStockCart(state, action: PayloadAction<Model>){
        state.cart = action.payload;
      },
      setStockCategories(state, action: PayloadAction<Model>){
        state.categories = action.payload;
      },
      setStockCategory(state, action: PayloadAction<Model>){
        state.category = action.payload;
      },
      setStockItems(state, action: PayloadAction<Model>){
        state.items = action.payload;
      },
      setStockItemsCount(state, action: PayloadAction<Model>){
        state.itemsCount = action.payload;
      },
      setStockOrders(state, action: PayloadAction<Model>){
        state.orders = action.payload;
      },
      setStockSelectedOrders(state, action: PayloadAction<Model>){
        state.selectedOrders = action.payload;
      },
      setStockOrdersCount(state, action: PayloadAction<Model>){
        state.ordersCount = action.payload;
      },
      setStockSchool(state, action: PayloadAction<Model>){
        state.school = action.payload;
      },
      setStockTags(state, action: PayloadAction<Model>){
        state.tags = action.payload;
      },
      setStockViewMode(state, action: PayloadAction<Model>){
        state.viewMode = action.payload;
      },
      setStockFilterCurrentPage(state, action: PayloadAction<Model>){
        state.filterCurrentPage = action.payload;
      },
      setStockFilterOrderID(state, action: PayloadAction<Model>){
        state.filterOrderID = action.payload;
      },
      setStockFilterSchoolID(state, action: PayloadAction<Model>){
        state.filterSchoolID = action.payload;
      },
      setStockFilterChildID(state, action: PayloadAction<Model>){
        state.filterChildID = action.payload;
      },
      setStockFilterUserID(state, action: PayloadAction<Model>){
        state.filterUserID = action.payload;
      },
      setStockFilterOrderStatusID(state, action: PayloadAction<Model>){
        state.filterOrderStatusID = action.payload;
      },
    }
});

export const stockSlice = slice;