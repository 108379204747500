import { AnyAction } from '@reduxjs/toolkit';
import { attendanceSlice as slice } from '../slices/attendance.slice';
import { RootState } from '../index';
import { ThunkAction } from '@reduxjs/toolkit';

export const actions = slice.actions;

export const setAttendanceDate = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setAttendanceDate(data));
  }
};

export const setAttendanceSelectedChildren = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setAttendanceSelectedChildren(data));
  }
};