import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUniquePosts } from 'src/utils/useFunctions';

interface Model {
  [key: string]: any;
};

interface ArrayModel {
  posts: any;
  medias: any;
};

const initialState: ArrayModel = {
  posts: [],
  medias: [],
};

const slice = createSlice({
    name: 'timeline',
    initialState: initialState,
    reducers: {
      setTimelinePosts(state, action: PayloadAction<Model>){
        state.posts = action.payload;
      },
      addTimelinePosts(state, action: PayloadAction<Model>){
        const postData: any = action.payload;
        const newPosts = [...state.posts, ...postData];
        state.posts = getUniquePosts(newPosts);
      },
      addTimelinePost(state, action: PayloadAction<Model>){
        const postData = action.payload;
        const newPosts = [...state.posts, postData]
        state.posts = getUniquePosts(newPosts);
      },
      updateTimelinePost(state, action: PayloadAction<Model>){
        const postData = action.payload;
        const posts = [...state.posts];
        const newPosts = posts.map((post: any) => {
          if (post.postID === postData.postID) {
            return { ...post, ...postData };
          } else {
            return post;
          }
        });
        state.posts = newPosts;
      },
      updateTimelinePostAttribute(state, action: PayloadAction<Model>){
        const data = action.payload;
        const postID = data.postID;
        const attribute = data.attribute;
        const posts = [...state.posts];
        const newPosts = posts.map((post: any) => {
          if (post.postID === postID) {
            return {...post, attributes: post.attributes.map((attr: any) => {
              if(attr.name === attribute.name) {
                return {...attribute, value: attribute.value};
              } else {
                return attribute;
              }
            })};
          } else {
            return post;
          }
        });
        state.posts = newPosts;
      },
      deleteTimelinePost(state, action: PayloadAction<Model>){
        const postID = action.payload;
        const posts = [...state.posts];
        const newPosts = posts.filter((post: any) => post.postID !== postID);
        state.posts = newPosts;
      },
      setTimelineMedias(state, action: PayloadAction<Model>){
        state.medias = action.payload;
      },
    }
});

export const timelineSlice = slice;