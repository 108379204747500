import DrawersController from './DrawersController';
import LanguageController from './LanguageController';
import LayoutController from './LayoutController';
import LoadingController from './LoadingController';
import ModalsController from './ModalsController';
import NotificationController from './NotificationController';
import PopupsController from './PopupsController';
import React from 'react';
import SvgController from './SvgController';
import TitleController from './TitleController';
import UserController from './UserController';
import WindowController from './WindowController';

interface Props {
  children: any;
}

const Controllers: React.FunctionComponent<Props> = (props:Props) => {
  return (
    <WindowController>
      <LanguageController>
        <LayoutController>
          <LoadingController>
            <DrawersController>
              <ModalsController>
                <NotificationController>
                  <PopupsController>
                    <SvgController>
                      <TitleController>
                        <UserController>
                          {props.children}
                        </UserController>
                      </TitleController>
                    </SvgController>
                  </PopupsController>
                </NotificationController>
              </ModalsController>
            </DrawersController>
          </LoadingController>
        </LayoutController>
      </LanguageController>
    </WindowController>
  );
};

export default Controllers;