import moment from '../../utils/moment';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Model {
  [key: string]: any;
};

interface ArrayModel {
  filterSchoolID: any;
  filterClassID: any;
  date: any;
  list: any;
  alerts: any;
};

const initialState: ArrayModel = {
  filterSchoolID: null,
  filterClassID: null,
  date: null,
  list: [],
  alerts: [],
};

const slice = createSlice({
  name: 'classbook',
  initialState: initialState,
  reducers: {
    setClassbookFilterSchoolID(state, action: PayloadAction<Model>) {
      state.filterSchoolID = action.payload;
    },
    setClassbookFilterClassID(state, action: PayloadAction<Model>) {
      state.filterClassID = action.payload;
    },
    setClassbookDate(state, action: PayloadAction<Model>) {
      state.date = action.payload;
    },
    setClassbookList(state, action: PayloadAction<Model>) {
      state.list = state.list.concat(action.payload);
    },
    updateClassbookList(state, action: PayloadAction<Model>) {
      state.list = state.list.map((data: any) => {
        if(data.classID === action.payload.classID && moment(data.startDate).format("YYYY-MM-DD") === moment(action.payload.startDate).format("YYYY-MM-DD") && moment(data.endDate).format("YYYY-MM-DD") === moment(action.payload.endDate).format("YYYY-MM-DD")) {
          return {...data, records: data.records.map((record: any) => {
            if(action.payload.type === "weekly") {
              if(moment(record.dateFrom).format("YYYY-MM-DD") === moment(action.payload.dateFrom).format("YYYY-MM-DD") && moment(record.dateTo).format("YYYY-MM-DD") === moment(action.payload.dateTo).format("YYYY-MM-DD")) {
                if(action.payload.isSchoolCare) {
                  return {...record, interestActivities: action.payload.interestActivities, relaxationRecreationalActivities: action.payload.relaxationRecreationalActivities, preparationTeaching: action.payload.preparationTeaching, otherActivities: action.payload.otherActivities, internalNote: action.payload.internalNote};
                } else {
                  return {...record, theme: action.payload.theme, morning: action.payload.morning, afternoon: action.payload.afternoon, notes: action.payload.notes, visit: action.payload.visit};
                }  
              } else {
                return record;
              } 
            } else {
              if(moment(record.date).format("YYYY-MM-DD") === moment(action.payload.date).format("YYYY-MM-DD")) {
                if(action.payload.isSchoolCare) {
                  return {...record, interestActivities: action.payload.interestActivities, relaxationRecreationalActivities: action.payload.relaxationRecreationalActivities, preparationTeaching: action.payload.preparationTeaching, otherActivities: action.payload.otherActivities, internalNote: action.payload.internalNote};
                } else {
                  return {...record, theme: action.payload.theme, morning: action.payload.morning, afternoon: action.payload.afternoon, notes: action.payload.notes, visit: action.payload.visit};
                }  
              } else {
                return record;
              }
            }
          })};
        } else {
          return data;
        }
      });
    },
    setClassbookAlerts(state, action: PayloadAction<Model>) {
      state.alerts = state.alerts.concat(action.payload);
    },
    removeClassbookAlert(state, action: PayloadAction<Model>) {
      state.alerts = state.alerts.filter((item: any) => item.classID !== action.payload.classID || item.date !== action.payload.date);
    },
    resetClassbook(state) {
      state.filterSchoolID = null;
      state.filterClassID = null;
      state.date = moment();
      state.list = [];
      state.alerts = [];
    },
  }
});

export const classbookSlice = slice;