import { AnyAction } from '@reduxjs/toolkit';
import { layoutSlice as slice } from '../slices/layout.slice';
import { RootState } from '../index';
import { ThunkAction } from '@reduxjs/toolkit';

export const actions = slice.actions;

export const setInitialPage = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setInitialPage(data));
  }
};

export const setIsFiltersVisible = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setIsFiltersVisible(data));
  }
};

export const setIsSearchVisible = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setIsSearchVisible(data));
  }
};

export const setIsDashboardEditable = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setIsDashboardEditable(data));
  }
};

export const setIsOpenSubMenu = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setIsOpenSubMenu(data));
  }
};

export const setIsOpenMoreMenu = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setIsOpenMoreMenu(data));
  }
};

export const setIsOpenSetupScreen = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setIsOpenSetupScreen(data));
  }
};

export const setIsOpenWelcomeScreen = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setIsOpenWelcomeScreen(data));
  }
};

export const setIsAutoOpenWelcomeScreen = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setIsAutoOpenWelcomeScreen(data));
  }
};

export const setIsUserSettingsLoaded = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setIsUserSettingsLoaded(data));
  }
};

export const setContainerHeight = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setContainerHeight(data));
  }
};

export const setCurrentMenu = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCurrentMenu(data));
  }
};

export const setCypressCurrentTag = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCypressCurrentTag(data));
  }
};

export const setCookies = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCookies(data));
  }
};

export const setIsModalFullscreen = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setIsModalFullscreen(data));
  }
};

export const setIsTooManyTabs = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setIsTooManyTabs(data));
  }
};

export const setReRender = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setReRender(data));
  }
};

export const setSideContent = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setSideContent(data));
  }
};

export const setTheme = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setTheme(data));
  }
};

export const setUrlParams = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setUrlParams(data));
  }
};