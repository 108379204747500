import { AnyAction } from '@reduxjs/toolkit';
import { clubSlice as slice } from '../slices/club.slice';
import { RootState } from '../index';
import { ThunkAction } from '@reduxjs/toolkit';

export const actions = slice.actions;

export const setClubItems = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setClubItems(data));
  }
};

export const setClubItemsCount = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setClubItemsCount(data));
  }
};

export const setClubFilterCurrentPage = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setClubFilterCurrentPage(data));
  }
};