import Button from '@mui/material/Button';
import hexToRgba from 'hex-to-rgba';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';

const useStyles = createUseStyles((theme: any) => ({
  blockPickerWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(72px, 1fr))',
    justifyItems: 'center',
    gap: '16px',
  },
  buttonWrapper: {
    display: 'flex',
    width: '72px',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    '& > p': {
      marginTop: '4px',
      marginBottom: '0px',
      fontSize: '14px',
      whiteSpace: 'break-spaces',
      maxWidth: '100%',
      textAlign: 'center',
      color: '#CFD2E3',
      transition: 'color 0.25s',
    },
    '&.selected': {
      '& > button': {
        backgroundColor: theme.colors.primaryBlue[500] + ' !important',
      },
      '& > p': {
        color: theme.colors.primaryBlue[500] + ' !important',
      },
    },
    '&.inactive': {
      '& > button': {
        backgroundColor: '#CFD2E3 !important',
        '& > img': {
          filter: 'contrast(0%) brightness(0%) invert(100%)',
        },
      },
      '& > p': {
        color: '#CFD2E3 !important',
      },
    },
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '56px',
    height: '56px',
    minWidth: '56px',
    minHeight: '56px',
    maxWidth: '56px',
    maxHeight: '56px',
    backgroundColor: '#CFD2E3',
    borderRadius: '16px',
    transition: 'background 0.25s',
    '& > img': {
      width: '20px',
      height: '20px',  
    },
  },
}));

type BlockPickerType = {
  items: any;
  value?: any;
  values?: any;
  valueCompare: any;
  onChange: any;
  className?: any;
  buttonSelected?: boolean;
  buttonInactive?: boolean;
  buttonOpacity?: any;
};

const BlockPicker: React.FunctionComponent<BlockPickerType> = ({items, value, values, valueCompare, onChange, className, buttonSelected = true, buttonInactive = false, buttonOpacity = 1}) => {

  const classes = useStyles();
  
  return (
    <div className={`${classes.blockPickerWrapper} ${className ? className : null}`}>
      {
        items.map((item: any, key: any) => (
          <div className={`${classes.buttonWrapper} ${buttonSelected ? (value ? (item[valueCompare] === value ? 'selected' : null) : values ? (values.indexOf(item[valueCompare]) !== -1 ? 'selected' : null) : null) : null} ${buttonInactive ? (value ? (item[valueCompare] !== value ? 'inactive' : null) : values ? (values.indexOf(item[valueCompare]) === -1 ? 'inactive' : null) : null) : null}`} onClick={() => onChange(item[valueCompare])} key={`k_${key}`} data-cy={isCypress() ? "blockPickerButton" + item[valueCompare] : null}>
            <Button className={classes.button} style={{backgroundColor:  hexToRgba(item.color, buttonOpacity)}}>
              {
                item.image ? (
                  <img src={item.image} alt={item.name}/>
                ) : null
              }            
            </Button>
            <p style={{color: item.color}} data-clarity-unmask="true">{item.name}</p>
          </div>
        ))
      }
    </div>
  );
}

export default BlockPicker;