import AuthenticatedImage from '../../Items/AuthenticatedImage';
import React, { useMemo, useRef } from 'react';
import SVG from '../../../components/Images/SvgRenderer';
import theme from '../../../ui/theme';
import { createUseStyles } from 'react-jss';
import { formatFileSize } from 'src/utils/useFunctions';
import { Grid, useMediaQuery } from '@mui/material';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';

interface Props {
  gridWidth?: any;
  isClickable?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  galleryWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  more: {
    position: 'relative',
    cursor: 'pointer',
  },
  title: {
    color: theme.colors.white,
    fontWeight: '600',
    fontSize: '25px',
    position: 'absolute',
    borderRadius: '24px',
    top: '8px',
    left: '8px',
    right: '0px',
    bottom: '0px',
    backgroundColor: 'rgba(0,0,0,0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    '& > svg': {
      width: '24px',
      height: '24px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      gap: '4px',
      '& > svg': {
        width: '16px',
        height: '16px',
      },
    },
  },
  wrapper: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    '& > video': {
      borderRadius: '24px',    
    },
    '& > svg': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%) scale(2.5)',
      filter: 'drop-shadow(1px 1px 2px rgb(0 0 0 / 0.4))',
      color: theme.colors.white,
      width: '24px',
      height: '24px',
    },
    '&.selected': {
      '&::after': {
        content: `''`,
        position: 'absolute',
        width: 'calc(100% - 6px)',
        height: 'calc(100% - 6px)',
        borderWidth: '3px',
        borderStyle: 'solid',
        borderColor: theme.colors.primaryBlue[500],
        borderRadius: '24px',
      },
    }
  },
  itemWrapper: {
    position: 'relative',
  },
  relativeWrapper: {
    position: 'relative',
    maxWidth: '100%',
  },
  star: {
    position: 'absolute',
    top: '1rem',
    right: '0.5rem',
    padding: '10px',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "0px",
    outline: "none",
    backgroundColor: 'rgba(0,0,0,0.1)',
    color: theme.colors.yellow[500],
    minWidth: "24px",
    minHeight: "24px",
    width: "24px",
    height: "24px",
    maxWidth: "24px",
    maxHeight: "24px",
    borderRadius: '100%',
    opacity: "1",
    cursor: 'default',
    transition: "color 0.25s, background-color 0.25s, opacity 0.25s",
    zIndex: '1',
    pointerEvents: 'none',
  },
  image: {
    display: 'flex',
    borderRadius: '24px',
    justifyContent: 'center',
    cursor: (props: Props) => {
      if(props.isClickable) return 'pointer';
      else return 'auto';
    },
    '& > div': {
      position: 'relative',
    },
    '& > img': {
      maxWidth: '100%',
      maxHeight: '400px',
      pointerEvents: 'none',
    },
    '& > span > canvas': {
      maxWidth: '100%',
      maxHeight: '400px',
    },
    '& > span': {
      maxHeight: '400px',
    },
  },
  file: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'calc(100% - 8px)',
    backgroundColor: theme.colors.grey[350],
    borderRadius: '24px',
    color: '#888FAE',
    cursor: (props: Props) => {
      if(props.isClickable) return 'pointer';
      else return 'auto';
    },
    '& > span': {
      fontSize: '14px',
      marginBottom: '4px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
      },
    },
    '& > svg': {
      width: 'auto',
      maxWidth: '100%',
      height: '50%',
      [theme.breakpoints.down('sm')]: {
        width: '48px',
        height: '48px',
      },
    },
    '& > p': {
      marginTop: '4px',
      marginBottom: '0',
      fontSize: '16px',
      maxWidth: '80%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
        maxWidth: '60%',
      },
    },
  },
  grid: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    gap: '4px',
    '& > div': {
      width: (props: Props) => ((props.gridWidth / 3) - 8) + 'px',
      height: (props: Props) => ((props.gridWidth / 3) - 8) + 'px',
      minHeight: (props: Props) => ((props.gridWidth / 3) - 8) + 'px',
      '& > div': {
        width: '100%',
        height: '100%',
        '& > div': {
          width: '100%',
          height: '100%',
        },
      },
    },
  },
}));

type MediaGridType = {
  data: any;
  currentMediaID?: any;
  display?: any;
  width: any;
  maxWidth: any;
  onModalOpen: any;
  size?: any;
  isClickable?: any;
};

const MediaGrid: React.FunctionComponent<MediaGridType> = ({ data, currentMediaID, display = "responsive", width, maxWidth, onModalOpen, size = 1, isClickable = true }) => {

  const browserData = useAppSelector((state: any) => state.browser);
  const smallDevice = useMediaQuery(theme.breakpoints.down('md'));
  const gridRef: any = useRef(null);
  const [state, setState] = useStates({
    gridWidth: 0,
    isClickable: isClickable,
  });

  const imageHeightSmall = smallDevice ? 95 * size : 195 * size;
  const imageHeightLarge = smallDevice ? 200 * size : 400 * size;

  const classes = useStyles({
    gridWidth: state.gridWidth,
    isClickable: state.isClickable,
  });

  useEffect(() => {
    if(gridRef && gridRef.current) {
      setState("gridWidth", gridRef.current.clientWidth);
    }
  }, [setState], [browserData]);
  
  const isAPhoto = useMemo(() => (type: any) => {
    if(type === "photo") {
      return true;
    }
    return false;
  }, []); 
    
  const isAVideo = useMemo(() => (type: any) => {
    if(type === "video") {
      return true;
    }
    return false;
  }, []);
  
  const isAFile = useMemo(() => (type: any) => {
    if(type === "attachment") {
      return true;
    }
    return false;
  }, []);  
  
  const hasAVideo = useMemo(() => (types: any) => {
    let hasVideo = false;
    types.forEach((type: any) => {
      if(type === "video") {
        hasVideo = true;
      }
    });
    return hasVideo;
  }, []);
  
  const handleOpenModal = (mediaID: any) => {
    if(isClickable) onModalOpen(mediaID);  
  };
  
  const displayGrid = (data: any) => {
    return (
      <Grid className={classes.grid} container ref={gridRef}>
        {
          data.map((item: any, key: any) => {
            return isAVideo(item.type) ? (
              <Grid key={`k_${key}`} container direction="row" className={`${classes.wrapper} ${currentMediaID === item.mediaID ? 'selected' : ''}`} onClick={() => handleOpenModal(item.mediaID)}>
                {
                  item.favorite ? (
                    <div className={classes.star}>
                      <SVG src="star-filled"/>
                    </div>
                  ) : null
                }
                <AuthenticatedImage className={classes.image} style={{width: '100%', height: imageHeightLarge + 'px'}} thumbLink={item.thumbLink} preferQuality="thumb"/>
                <SVG src="play"/>
              </Grid>
            ) : isAFile(item.type) ? (
              <Grid key={`k_${key}`} container direction="row" className={`${classes.wrapper} ${currentMediaID === item.mediaID ? 'selected' : ''}`}>
                <div className={classes.relativeWrapper} onClick={() => handleOpenModal(item.mediaID)}>
                  {
                    item.favorite ? (
                      <div className={classes.star}>
                        <SVG src="star-filled"/>
                      </div>
                    ) : null
                  }
                  <div className={classes.file} onClick={() => handleOpenModal(item.mediaID)}>
                    {
                      item.size ? (
                        <span>{formatFileSize(item.size)}</span>
                      ) : null
                    }
                    <SVG src={item.icon} children={<SVG src="file"/>}/>
                    <p>{item.name}</p>
                  </div>
                </div>
              </Grid>
            ) : isAPhoto(item.type) ? (
              <Grid key={`k_${key}`} container direction="row" className={`${classes.wrapper} ${currentMediaID === item.mediaID ? 'selected' : ''}`}>
                <div className={classes.relativeWrapper} onClick={() => handleOpenModal(item.mediaID)}>
                  {
                    item.favorite ? (
                      <div className={classes.star}>
                        <SVG src="star-filled"/>
                      </div>
                    ) : null
                  }
                  <AuthenticatedImage className={classes.image} thumbLink={item.thumbLink} fullsizeLink={item.fullsizeLink} useLoader={true}/>
                </div>
              </Grid>
            ) : null;
          })
        }
      </Grid>
    );
  };

  const displayResponsiveGrid = (data: any) => {
    if(data.length > 0) {
      if(data.length === 1) {
        if(isAVideo(data[0].type)) {
          return (
            <Grid container direction="row" className={classes.wrapper} onClick={() => handleOpenModal(data[0].mediaID)}>
              {
                data[0].favorite ? (
                  <div className={classes.star}>
                    <SVG src="star-filled"/>
                  </div>
                ) : null
              }
              <AuthenticatedImage className={classes.image} style={{width: '100%', height: imageHeightLarge + 'px'}} thumbLink={data[0].thumbLink} preferQuality="thumb"/>
              <SVG src="play"/>
            </Grid>
          );
        } else if(isAFile(data[0].type)) {
          return (
            <Grid container direction="row" className={classes.wrapper}>
              <div className={classes.relativeWrapper} style={{width: '100%'}} onClick={() => handleOpenModal(data[0].mediaID)}>
                {
                  data[0].favorite ? (
                    <div className={classes.star}>
                      <SVG src="star-filled"/>
                    </div>
                  ) : null
                }
                <div className={classes.file} style={{height: imageHeightLarge + 'px'}} onClick={() => handleOpenModal(data[0].mediaID)}>
                  {
                    data[0].size ? (
                      <span>{formatFileSize(data[0].size)}</span>
                    ) : null
                  }
                  <SVG src={data[0].icon} children={<SVG src="file"/>}/>
                  <p>{data[0].name}</p>
                </div>
              </div>
            </Grid>
          );
        } else if(isAPhoto(data[0].type)) {
          return (
            <Grid container direction="row" className={classes.wrapper}>
              <div className={classes.relativeWrapper} onClick={() => handleOpenModal(data[0].mediaID)}>
                {
                  data[0].favorite ? (
                    <div className={classes.star}>
                      <SVG src="star-filled"/>
                    </div>
                  ) : null
                }
                <AuthenticatedImage className={classes.image} isResponsive={false} thumbLink={data[0].thumbLink} fullsizeLink={data[0].fullsizeLink} preferQuality="fullsize"/>
              </div>
            </Grid>
          );
        } else {
          return null;
        }
      } else if(data.length === 2) {
        if(hasAVideo(data)) {
          return (
            <Grid container spacing={1}>
              {
                data.map((item: any, key: any) => {
                  if(isAVideo(item.type)) {
                    return (
                      <Grid item md={12} lg={12} xl={12} xs={12} sm={12} key={`k_${key}`} className={classes.itemWrapper} onClick={() => handleOpenModal(item.mediaID)}>
                        {
                          item.favorite ? (
                            <div className={classes.star}>
                              <SVG src="star-filled"/>
                            </div>
                          ) : null
                        }
                        <Grid container direction="row" className={classes.wrapper}>
                          <AuthenticatedImage className={classes.image} style={{width: '100%', height: '200px'}} thumbLink={item.thumbLink} preferQuality="thumb"/>
                          <SVG src="play"/>
                        </Grid>
                      </Grid>
                    );
                  } else if(isAFile(item.type)) {
                    return (
                      <Grid item md={12} lg={12} xl={12} xs={12} sm={12} key={`k_${key}`} className={classes.itemWrapper} onClick={() => handleOpenModal(item.mediaID)}>
                        {
                          item.favorite ? (
                            <div className={classes.star}>
                              <SVG src="star-filled"/>
                            </div>
                          ) : null
                        }
                        <div className={classes.file} style={{height: '200px'}}>
                          {
                            item.size ? (
                              <span>{formatFileSize(item.size)}</span>
                            ) : null
                          }
                          <SVG src={item.icon} children={<SVG src="file"/>}/>
                          <p>{item.name}</p>
                        </div>
                      </Grid>
                    );
                  } else if(isAPhoto(item.type)) {
                    return (
                      <Grid item md={12} lg={12} xl={12} xs={12} sm={12} key={`k_${key}`} className={classes.itemWrapper} onClick={() => handleOpenModal(item.mediaID)}>
                        {
                          item.favorite ? (
                            <div className={classes.star}>
                              <SVG src="star-filled"/>
                            </div>
                          ) : null
                        }
                        <AuthenticatedImage style={{height: '200px'}} className={classes.image} thumbLink={item.thumbLink} fullsizeLink={item.fullsizeLink} preferQuality="thumb"/>
                      </Grid>
                    );
                  } else {
                    return null;
                  }
                })
              }
            </Grid>
          );
        } else {
          return (
            <Grid container spacing={1}>
              {
                data.map((item: any, key: any) => {
                  if(isAFile(item.type)) {
                    return (
                      <Grid item md={6} lg={6} xl={6} xs={6} sm={6} key={`k_${key}`} className={classes.itemWrapper} onClick={() => handleOpenModal(item.mediaID)}>
                        {
                          item.favorite ? (
                            <div className={classes.star}>
                              <SVG src="star-filled"/>
                            </div>
                          ) : null
                        }
                        <div className={classes.file} style={{height: imageHeightLarge + 'px'}}>
                          {
                            item.size ? (
                              <span>{formatFileSize(item.size)}</span>
                            ) : null
                          }
                          <SVG src={item.icon} children={<SVG src="file"/>}/>
                          <p>{item.name}</p>
                        </div>
                      </Grid>
                    );
                  } else if(isAPhoto(item.type)) {
                    return (
                      <Grid item md={6} lg={6} xl={6} xs={6} sm={6} key={`k_${key}`} className={classes.itemWrapper} onClick={() => handleOpenModal(item.mediaID)}>
                        {
                          item.favorite ? (
                            <div className={classes.star}>
                              <SVG src="star-filled"/>
                            </div>
                          ) : null
                        }
                        <AuthenticatedImage style={{height: imageHeightLarge + 'px'}} className={classes.image} thumbLink={item.thumbLink} fullsizeLink={item.fullsizeLink} preferQuality="thumb"/>
                      </Grid>
                    );
                  } else {
                    return null;
                  }
                })
              }
            </Grid>
          );
        }
      } else if(data.length === 3) {
        return (
          <Grid container spacing={1}>
            <Grid item md={6} lg={6} xl={6} xs={6} sm={6} className={classes.itemWrapper}>
              {
                data[0].favorite ? (
                  <div className={classes.star}>
                    <SVG src="star-filled"/>
                  </div>
                ) : null
              }
              {
                isAVideo(data[0].type) ? (
                  <Grid item md={12} lg={12} xl={12} xs={12} sm={12} onClick={() => handleOpenModal(data[0].mediaID)}>
                    <Grid container direction="row" className={classes.wrapper}>
                        <Grid container direction="row" className={classes.wrapper}>
                          <AuthenticatedImage className={classes.image} style={{width: '100%', height: (imageHeightLarge + 5) + 'px'}} thumbLink={data[0].thumbLink} preferQuality="thumb"/>
                          <SVG src="play"/>
                        </Grid>
                    </Grid>
                  </Grid>
                ) : isAFile(data[0].type) ? (
                  <div className={classes.file} style={{height: (imageHeightLarge + 5) + 'px'}} onClick={() => handleOpenModal(data[0].mediaID)}>
                    {
                      data[0].size ? (
                        <span>{formatFileSize(data[0].size)}</span>
                      ) : null
                    }
                    <SVG src={data[0].icon} children={<SVG src="file"/>}/>
                    <p>{data[0].name}</p>
                  </div>
                ) : (
                  <AuthenticatedImage style={{height: (imageHeightLarge + 5) + 'px'}} className={classes.image} thumbLink={data[0].thumbLink} fullsizeLink={data[0].fullsizeLink} preferQuality="thumb" onClick={() => handleOpenModal(data[0].mediaID)}/>
                )
              }
            </Grid>{" "}
            <Grid item md={6} lg={6} xl={6} xs={6} sm={6}>
              {
                data.map((item: any, key: any) => {
                  if(key !== 0) {
                    if(isAVideo(item.type)) {
                      return (
                        <Grid container direction="row" className={classes.wrapper} key={`k_${key}`} onClick={() => handleOpenModal(item.mediaID)}>
                          {
                            item.favorite ? (
                              <div className={classes.star}>
                                <SVG src="star-filled"/>
                              </div>
                            ) : null
                          }
                          <AuthenticatedImage className={classes.image} style={{width: '100%', height: imageHeightSmall + 'px'}} thumbLink={item.thumbLink} preferQuality="thumb"/>
                          <SVG src="play"/>
                        </Grid>
                      );
                    } else if(isAFile(item.type)) {
                      return (
                        <Grid item md={12} lg={12} xl={12} xs={12} sm={12} className={classes.itemWrapper} style={{marginTop: key !== 1 ? '14px' : ''}} key={`k_${key}`}>
                          {
                            item.favorite ? (
                              <div className={classes.star}>
                                <SVG src="star-filled"/>
                              </div>
                            ) : null
                          }
                          <div className={classes.file} style={{height: imageHeightSmall + 'px'}} key={`k_${key}`} onClick={() => handleOpenModal(item.mediaID)}>
                            {
                              item.size ? (
                                <span>{formatFileSize(item.size)}</span>
                              ) : null
                            }
                            <SVG src={item.icon} children={<SVG src="file"/>}/>
                            <p>{item.name}</p>
                          </div>
                        </Grid>
                      );
                    } else if(isAPhoto(item.type)) {
                      return (
                        <Grid item md={12} lg={12} xl={12} xs={12} sm={12} className={classes.itemWrapper} key={`k_${key}`}>
                          {
                            item.favorite ? (
                              <div className={classes.star}>
                                <SVG src="star-filled"/>
                              </div>
                            ) : null
                          }
                          <AuthenticatedImage style={{height: imageHeightSmall + 'px', marginBottom: '5px'}} className={classes.image} thumbLink={item.thumbLink} fullsizeLink={item.fullsizeLink} preferQuality="thumb" key={`k_${key}`} onClick={() => handleOpenModal(item.mediaID)}/>
                        </Grid>
                      );
                    } else {
                      return null;
                    }
                  } else {
                    return null;
                  }
                })
              }
            </Grid>
          </Grid>
        );
      } else if(data.length === 4) {
        return (
          <Grid container spacing={1}>
            <Grid item md={8} lg={8} xl={8} xs={8} sm={8} className={classes.itemWrapper}>
              {
                data[0].favorite ? (
                  <div className={classes.star}>
                    <SVG src="star-filled"/>
                  </div>
                ) : null
              }
              {
                isAVideo(data[0].type) ? (
                  <Grid item md={12} lg={12} xl={12} xs={12} sm={12} onClick={() => handleOpenModal(data[0].mediaID)}>
                    <Grid container direction="row" className={classes.wrapper}>
                      <AuthenticatedImage className={classes.image} style={{width: '100%', height: imageHeightLarge + 'px'}} thumbLink={data[0].thumbLink} preferQuality="thumb"/>
                      <SVG src="play"/>
                    </Grid>
                  </Grid>
                ) : isAFile(data[0].type) ? (
                  <div className={classes.file} style={{height: imageHeightLarge + 'px'}} onClick={() => handleOpenModal(data[0].mediaID)}>
                    {
                      data[0].size ? (
                        <span>{formatFileSize(data[0].size)}</span>
                      ) : null
                    }
                    <SVG src={data[0].icon} children={<SVG src="file"/>}/>
                    <p>{data[0].name}</p>
                  </div>
                ) : (
                  <AuthenticatedImage style={{height: imageHeightLarge + 'px'}} className={classes.image} thumbLink={data[0].thumbLink} fullsizeLink={data[0].fullsizeLink} preferQuality="thumb" onClick={() => handleOpenModal(data[0].mediaID)}/>
                )
              }
            </Grid>{" "}
            <Grid item md={4} lg={4} xl={4} xs={4} sm={4}>
              {
                data.map((item: any, key: any) => {
                  if(key !== 0) {
                    if(isAVideo(item.type)) {
                      return (
                        <Grid item md={12} lg={12} xl={12} xs={12} sm={12} key={`k_${key}`} className={classes.itemWrapper} onClick={() => handleOpenModal(item.mediaID)}>
                          {
                            item.favorite ? (
                              <div className={classes.star}>
                                <SVG src="star-filled"/>
                              </div>
                            ) : null
                          }
                          <Grid container direction="row" className={classes.wrapper}>
                            <AuthenticatedImage className={classes.image} style={{width: '100%', height: '130px'}} thumbLink={item.thumbLink} fullsizeLink={item.fullsizeLink} preferQuality="thumb"/>
                            <SVG src="play"/>
                          </Grid>
                        </Grid>
                      );
                    } else if(isAFile(item.type)) {
                      return (
                        <Grid item md={12} lg={12} xl={12} xs={12} sm={12} className={classes.itemWrapper} style={{marginTop: key !== 1 ? '5px' : ''}} key={`k_${key}`}>
                          {
                            item.favorite ? (
                              <div className={classes.star}>
                                <SVG src="star-filled"/>
                              </div>
                            ) : null
                          }
                          <div className={classes.file} style={{height: '130px'}} onClick={() => handleOpenModal(item.mediaID)}>
                            {
                              item.size ? (
                                <span>{formatFileSize(item.size)}</span>
                              ) : null
                            }
                            <SVG src={item.icon} children={<SVG src="file"/>}/>
                            <p>{item.name}</p>
                          </div>
                        </Grid>
                      );
                    } else if(isAPhoto(item.type)) {
                      return (
                        <Grid item md={12} lg={12} xl={12} xs={12} sm={12} className={classes.itemWrapper} key={`k_${key}`}>
                          {
                            item.favorite ? (
                              <div className={classes.star}>
                                <SVG src="star-filled"/>
                              </div>
                            ) : null
                          }
                          <AuthenticatedImage style={{height: '130px', marginBottom: '5px'}} className={classes.image} thumbLink={item.thumbLink} fullsizeLink={item.fullsizeLink} preferQuality="thumb" onClick={() => handleOpenModal(item.mediaID)}/>
                        </Grid>
                      );
                    } else {
                      return null;
                    }
                  } else {
                    return null;
                  }
                })
              }
            </Grid>
          </Grid>
        );
      } else {
        return (
          <Grid container spacing={1}>
            <Grid item md={6} lg={6} xl={6} xs={6} sm={6} className={classes.itemWrapper}>
              {
                data[0].favorite ? (
                  <div className={classes.star}>
                    <SVG src="star-filled"/>
                  </div>
                ) : null
              }
              {
                isAVideo(data[0].type) ? (
                  <Grid item md={12} lg={12} xl={12} xs={12} sm={12} onClick={() => handleOpenModal(data[0].mediaID)}>
                    <Grid container direction="row" className={classes.wrapper}>
                      <AuthenticatedImage className={classes.image} style={{width: '100%', height: imageHeightLarge + 'px'}} thumbLink={data[0].thumbLink} preferQuality="thumb"/>
                      <SVG src="play"/>
                    </Grid>
                  </Grid>
                ) : isAFile(data[0].type) ? (
                  <div className={classes.file} style={{height: imageHeightLarge + 'px'}} onClick={() => handleOpenModal(data[0].mediaID)}>
                    {
                      data[0].size ? (
                        <span>{formatFileSize(data[0].size)}</span>
                      ) : null
                    }
                    <SVG src={data[0].icon} children={<SVG src="file"/>}/>
                    <p>{data[0].name}</p>
                  </div>
                ) : (
                  <AuthenticatedImage style={{height: imageHeightLarge + 'px'}} className={classes.image} thumbLink={data[0].thumbLink} fullsizeLink={data[0].fullsizeLink} preferQuality="thumb" onClick={() => handleOpenModal(data[0].mediaID)}/>
                )
              }
            </Grid>{" "}
            <Grid item md={6} lg={6} xl={6} xs={6} sm={6}>
              <Grid container spacing={1}>
                {
                  data.map((item: any, key: any) => {
                    if(key !== 0) {
                      if(key > 3 && data.length > 5) {
                        if(key === 4) {
                          if(isAVideo(item.type)) {
                            return (
                              <Grid item md={6} lg={6} xl={6} xs={6} sm={6} className={classes.more} key={`k_${key}`} onClick={() => handleOpenModal(item.mediaID)}>
                                <AuthenticatedImage style={{height: imageHeightSmall + 'px'}} className={classes.image} thumbLink={item.thumbLink} preferQuality="thumb"/>
                                <span className={classes.title} data-clarity-unmask="true">
                                  <SVG src={data.filter((media: any) => media.type !== "photo").length === 0 ? "image" : "file"}/>                                                                
                                  +{data.length === 6 ? 1 : data.length - (key + 2)}
                                </span>
                              </Grid>
                            );
                          }
                          if(isAFile(item.type)) {
                            return (
                              <Grid item md={6} lg={6} xl={6} xs={6} sm={6} className={classes.more} key={`k_${key}`} onClick={() => handleOpenModal(item.mediaID)}>
                                <div className={classes.file}>
                                  {
                                    item.size ? (
                                      <span>{formatFileSize(item.size)}</span>
                                    ) : null
                                  }
                                  <SVG src={item.icon} children={<SVG src="file"/>}/>
                                  <p>{item.name}</p>
                                </div>
                                <span className={classes.title} data-clarity-unmask="true">
                                  <SVG src={data.filter((media: any) => media.type !== "photo").length === 0 ? "image" : "file"}/>                                                                
                                  +{data.length === 6 ? 1 : data.length - (key + 2)}
                                </span>
                              </Grid>
                            );
                          }
                          if(isAPhoto(item.type)) {
                            return (
                              <Grid item md={6} lg={6} xl={6} xs={6} sm={6} className={classes.more} key={`k_${key}`} onClick={() => handleOpenModal(item.mediaID)}>
                                <AuthenticatedImage style={{height: imageHeightSmall + 'px'}} className={classes.image} thumbLink={item.thumbLink} preferQuality="thumb"/>
                                <span className={classes.title} data-clarity-unmask="true">
                                  <SVG src={data.filter((media: any) => media.type !== "photo").length === 0 ? "image" : "file"}/>                                                                
                                  +{data.length === 6 ? 1 : data.length - (key + 2)}
                                </span>
                              </Grid>
                            );
                          }
                        }
                      } else {
                        if(hasAVideo(data) && data.length <= 5) {
                          if(key > 2) {
                            if(isAVideo(item)) {
                              return (
                                <Grid item md={12} lg={12} xl={12} xs={12} sm={12}key={`k_${key}`} onClick={() => handleOpenModal(item.mediaID)} className={classes.itemWrapper}>
                                  {
                                    item.favorite ? (
                                      <div className={classes.star}>
                                        <SVG src="star-filled"/>
                                      </div>
                                    ) : null
                                  }
                                  <Grid container direction="row" className={classes.wrapper}>
                                    <AuthenticatedImage className={classes.image} style={{width: '100%', height: '105px'}} thumbLink={item.thumbLink} preferQuality={"fullsize"}/>
                                    <SVG src="play"/>
                                  </Grid>
                                </Grid>
                              );
                            } else if(isAFile(item.type)) {
                              return (
                                <Grid item md={12} lg={12} xl={12} xs={12} sm={12} key={`k_${key}`} onClick={() => handleOpenModal(item.mediaID)} className={classes.itemWrapper}>
                                  {
                                    item.favorite ? (
                                      <div className={classes.star}>
                                        <SVG src="star-filled"/>
                                      </div>
                                    ) : null
                                  }
                                  <div className={classes.file} style={{height: '105px'}}>
                                    {
                                      item.size ? (
                                        <span>{formatFileSize(item.size)}</span>
                                      ) : null
                                    }
                                    <SVG src={item.icon} children={<SVG src="file"/>}/>
                                    <p>{item.name}</p>
                                  </div>
                                </Grid>
                              );
                            } else if(isAPhoto(item.type)) {
                              return (
                                <Grid item md={6} lg={6} xl={6} xs={6} sm={6} key={`k_${key}`} onClick={() => handleOpenModal(item.mediaID)} className={classes.itemWrapper}>
                                  {
                                    item.favorite ? (
                                      <div className={classes.star}>
                                        <SVG src="star-filled"/>
                                      </div>
                                    ) : null
                                  }
                                  <AuthenticatedImage style={{height: '105px'}} className={classes.image} thumbLink={item.thumbLink} fullsizeLink={item.fullsizeLink} preferQuality="thumb"/>
                                </Grid>
                              );
                            } else {
                              return null;
                            }
                          }
                          if(isAVideo(item.type)) {
                            return (
                              <Grid item md={6} lg={6} xl={6} xs={6} sm={6} key={`k_${key}`} onClick={() => handleOpenModal(item.mediaID)} className={classes.itemWrapper}>
                                  {
                                    item.favorite ? (
                                      <div className={classes.star}>
                                        <SVG src="star-filled"/>
                                      </div>
                                    ) : null
                                  }
                                <Grid container direction="row" className={classes.wrapper}>
                                  <AuthenticatedImage className={classes.image} style={{width: '100%', height: '155px'}} thumbLink={item.thumbLink} preferQuality={"fullsize"}/>
                                  <SVG src="play"/>
                                </Grid>
                              </Grid>
                            );
                          } else if(isAFile(item.type)) {
                            return (
                              <Grid item md={6} lg={6} xl={6} xs={6} sm={6} key={`k_${key}`} onClick={() => handleOpenModal(item.mediaID)} className={classes.itemWrapper}>
                                {
                                  item.favorite ? (
                                    <div className={classes.star}>
                                      <SVG src="star-filled"/>
                                    </div>
                                  ) : null
                                }
                                <div className={classes.file} style={{height: '155px'}}>
                                  {
                                    item.size ? (
                                      <span>{formatFileSize(item.size)}</span>
                                    ) : null
                                  }
                                  <SVG src={item.icon} children={<SVG src="file"/>}/>
                                  <p>{item.name}</p>
                                </div>
                              </Grid>
                            );
                          } else if(isAPhoto(item.type)) {
                            return (
                              <Grid item md={6} lg={6} xl={6} xs={6} sm={6} key={`k_${key}`} onClick={() => handleOpenModal(item.mediaID)} className={classes.itemWrapper}>
                                {
                                  item.favorite ? (
                                    <div className={classes.star}>
                                      <SVG src="star-filled"/>
                                    </div>
                                  ) : null
                                }
                                <AuthenticatedImage style={{height: '155px'}} className={classes.image} thumbLink={item.thumbLink} fullsizeLink={item.fullsizeLink} preferQuality="thumb"/>
                              </Grid>
                            );
                          } else {
                            return null;
                          }
                        } else {
                          if(isAVideo(item.type)) {
                            return (
                              <Grid item md={6} lg={6} xl={6} xs={6} sm={6} key={`k_${key}`} onClick={() => handleOpenModal(item.mediaID)} className={classes.itemWrapper}>
                                {
                                  item.favorite ? (
                                    <div className={classes.star}>
                                      <SVG src="star-filled"/>
                                    </div>
                                  ) : null
                                }
                                <Grid container direction="row" className={classes.wrapper}>
                                  <AuthenticatedImage className={classes.image} style={{width: '100%', height: '195px'}} thumbLink={item.thumbLink}preferQuality={"fullsize"}/>
                                  <SVG src="play"/>
                                </Grid>
                              </Grid>
                            );
                          } else if(isAFile(item.type)) {
                            return (
                              <Grid item md={6} lg={6} xl={6} xs={6} sm={6} key={`k_${key}`} onClick={() => handleOpenModal(item.mediaID)} className={classes.itemWrapper}>
                                {
                                  item.favorite ? (
                                    <div className={classes.star}>
                                      <SVG src="star-filled"/>
                                    </div>
                                  ) : null
                                }
                                <div className={classes.file} style={{height: imageHeightSmall + 'px'}}>
                                  {
                                    item.size ? (
                                      <span>{formatFileSize(item.size)}</span>
                                    ) : null
                                  }
                                  <SVG src={item.icon} children={<SVG src="file"/>}/>
                                  <p>{item.name}</p>
                                </div>
                              </Grid>
                            );
                          } else if(isAPhoto(item.type)) {
                            return (
                              <Grid item md={6} lg={6} xl={6} xs={6} sm={6} key={`k_${key}`} onClick={() => handleOpenModal(item.mediaID)} className={classes.itemWrapper}>
                                {
                                  item.favorite ? (
                                    <div className={classes.star}>
                                      <SVG src="star-filled"/>
                                    </div>
                                  ) : null
                                }
                                <AuthenticatedImage style={{height: imageHeightSmall + 'px'}} className={classes.image} thumbLink={item.thumbLink} fullsizeLink={item.fullsizeLink} preferQuality="thumb"/>
                              </Grid>
                            );
                          } else {
                            return null;
                          }
                        }
                      }
                      return null;
                    } else {
                      return null;
                    }
                  })
                }
              </Grid>
            </Grid>
          </Grid>
        );
      }
    }
  };
  return (
    <div className={classes.galleryWrapper} style={{ width: width, maxWidth: maxWidth }}>
      {display === "grid" ? displayGrid(data) : displayResponsiveGrid(data)}
    </div>
  );
}

export default MediaGrid;