import EmailChange from './EmailChange';
import PasswordChange from './PasswordChange';
import React from 'react';
import SSOGenerator from './SSOGenerator';
import tabs from 'src/constants/tabs';
import TabsMenu from 'src/components/Menus/TabsMenu';
import { createUseStyles } from 'react-jss';
import { getUserRole } from '../../../../../utils/useUser';
import { useAppSelector } from '../../../../../hooks/redux-hooks';
import { useState } from '../../../../../utils/useState';
import { useTranslation } from 'react-i18next';
import LoggedInDevices from './LoggedInDevices';

const useStyles = createUseStyles((theme: any) => ({
  securityWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
    },
  },
  tabsWrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
    justifyContent: 'center',
    '& > div': {
      width: '100%',
    },
  },
  boxWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    gap: '16px',
    maxWidth: '100%',
    marginBottom: '20px',
    height: 'fit-content',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      borderRadius: '0px',
      marginBottom: '0px',
    },
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 60px)',
    maxWidth: '100%',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    padding: '4px 30px 20px 30px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    height: 'fit-content',
    '& > button': {
      marginTop: '16px',
    },
    [theme.breakpoints.down('md')]: {
      borderRadius: '0px',
    },
  },
  nothingFoundWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
    gap: '16px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  nothingFound: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 68px)',
    maxWidth: '100%',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    height: 'fit-content',
    '& > button': {
      marginTop: '16px',
    },
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 60px)',
      borderRadius: '0px',
    },
  },
}));

const SecuritySettings: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const userData = useAppSelector((state: any) => state.user);
  const tabsItems = tabs.settings.security.filter((item: any) => item.roles.includes(getUserRole(userData.userObject.roleType)));
  const [viewMode, setViewMode] = useState(tabsItems[0].value);

  return (
    <div className={classes.securityWrapper}>
      <div className={classes.tabsWrapper}>
        <TabsMenu items={tabsItems} selected={viewMode} onSelect={setViewMode}/>
      </div>
      <div className={classes.boxWrapper}>
        <div className={classes.box}>
        {
          viewMode === "email_change" ? (
            <EmailChange/>
          ) : viewMode === "password_change" ? (
            <PasswordChange/>
          ) : viewMode === "logged_in_devices" ? (
            <LoggedInDevices/>
          ) : viewMode === "sso_one_time_login" ? (
            <SSOGenerator/>
          ) : (
            <div className={classes.nothingFoundWrapper}>
              <div className={classes.nothingFound}>
                {t('nothing_was_found')}
              </div>  
            </div>
          )
        }
        </div>
      </div>
    </div>
  );
};

export default SecuritySettings;