import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Model {
  [key: string]: any;
};

interface ArrayModel {
  communicationDrawer: any;
  firebaseNotificationsDrawer: any;
};

const initialState: ArrayModel = {
  communicationDrawer: {
    isOpen: false,
    position: 'right',
  },
  firebaseNotificationsDrawer: {
    isOpen: false,
    position: 'right',
  },
};

const slice = createSlice({
  name: 'drawers',
  initialState: initialState,
  reducers: {
    setCommunicationDrawer(state, action: PayloadAction<Model>) {
      state.communicationDrawer = Object.assign(state.communicationDrawer, action.payload);
    },
    setFirebaseNotificationsDrawer(state, action: PayloadAction<Model>) {
      state.firebaseNotificationsDrawer = Object.assign(state.firebaseNotificationsDrawer, action.payload);
    },
    resetAllDrawers(state) {
      state.communicationDrawer = initialState.communicationDrawer;
      state.firebaseNotificationsDrawer = initialState.firebaseNotificationsDrawer;
    },
  }
});

export const drawersSlice = slice;